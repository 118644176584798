import styles from "./styles.module.css";
import React from "react";
import { Col, Row } from "antd";

interface PageTitleProps {}

const PageTitle = ({ children }: React.PropsWithChildren<PageTitleProps>) => {
  return (
    <Row justify="center">
      <Col xs={24} md={12} xl={8}>
        <h2 className={styles.title}>{children}</h2>
      </Col>
    </Row>
  );
};

export default PageTitle;
