// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useCallback, useEffect, useState } from "react";
import Constants from "../common/constants";
import { useNavigate } from "react-router-dom"; // Your web app's Firebase configuration

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_PROJECT_ID}.firebaseapp.com`,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
auth.useDeviceLanguage();

export const setExpectFirebaseUser = (v: boolean): void => {
  localStorage.setItem(Constants.STORAGE_EXPECT_FIREBASE_USER, v ? "1" : "0");
};

export const expectFirebaseUser = (): boolean => {
  return localStorage.getItem(Constants.STORAGE_EXPECT_FIREBASE_USER) === "1";
};

firebase.initializeApp(firebaseConfig);

const useFirebase = () => {
  const [userId, setUserId] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [firebaseProviders, setFirebaseProviders] = useState<
    string[] | undefined
  >();

  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isSignInWithEmailLink(window.location.href)) {
      setExpectFirebaseUser(true);
      const email = new URLSearchParams(window.location.search).get("email");
      if (email === null) {
        throw new Error("Sign in failed, no email found in params.");
      }
      setExpectFirebaseUser(true);
      auth.signInWithEmailLink(email, window.location.href).then((result) => {
        setExpectFirebaseUser(true);
      });
      navigate("/");
    }
  }, [navigate]);

  const logout = useCallback(async () => {
    await firebase.auth().signOut();
    setExpectFirebaseUser(false);
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      console.log("onAuthStateChanged", user);
      setExpectFirebaseUser(!!user);
      setUserId(user?.uid || null);

      let userEmail = null;
      if (!!user) {
        userEmail = user?.email;
        if (!userEmail) {
          user?.providerData.forEach((profile) => {
            if (profile?.email) {
              userEmail = profile.email;
            }
          });
        }
        setFirebaseProviders(
          user.providerData
            .map((p) => p?.providerId)
            .filter((p) => !!p) as string[],
        );

        if (!userEmail) {
          void logout();
        }
      }

      setUserEmail(userEmail);
    });
    return () => {
      unsubscribe();
    };
  }, [logout]);

  return {
    firebaseUserId: userId,
    firebaseEmail: userEmail,
    firebaseLogout: logout,
    expectFirebaseUser,
    firebaseProviders,
  };
};

export { useFirebase };
