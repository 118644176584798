import { Button, Card, Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import profileStyles from "../Profile/styles.module.css";
import styles from "./styles.module.css";

import React, { useEffect, useState } from "react";
import ScaleInput from "./ScaleInput";
import useApiClient from "../Networking/useApiClient";
import useReviews from "./useReviews";
import useUATracker from "../Networking/Metrics/useUATracker";
import {
  DishRatingSubmitted,
  DishReviewClosed,
  DishReviewSkipped,
} from "../Networking/Metrics/UserActions/Products";

const RecipesReviewModal = () => {
  const { t } = useTranslation();
  const { trackUserAction } = useUATracker();
  const { submitCustomerReview } = useApiClient();
  const { productsToReview, setReviewCooldown } = useReviews();

  const [openModal, setOpenModal] = useState<boolean | undefined>(false);
  const [current, setCurrent] = useState(0);
  const [submittedRating, setSubmittedRating] = useState<number | undefined>(
    undefined,
  );

  const [form] = Form.useForm();

  const reviewQuestion = t(
    "Quanto sei d'accordo con l'affermazione 'riordinerei questo piatto'?",
  );

  const reviews =
    productsToReview &&
    productsToReview.map((product) => ({
      sku: product.sku,
      title: product.title,
      variantId: product.id,
      content: (
        <Card
          key={product.id}
          bordered={false}
          bodyStyle={{
            padding: 0,
            height: "80%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <img src={product.images[0].src} alt={product.title} />
        </Card>
      ),
    }));

  useEffect(() => {
    if (reviews) setOpenModal(true);
  }, [reviews]);

  const nextReview = () => {
    if (reviews && current < reviews.length - 1) {
      setCurrent(current + 1);
    } else {
      setOpenModal(false);
      setReviewCooldown();
    }
    setSubmittedRating(undefined);
    form.resetFields();
  };

  const onSubmit = (values: { rating?: number; feedback?: string }) => {
    if (!reviews) return;
    if (values.rating) {
      setSubmittedRating(values.rating);
      form.resetFields();
      return;
    }
    if (submittedRating) {
      trackUserAction(
        new DishRatingSubmitted({
          productSku: reviews[current].sku,
          dishRating: submittedRating,
          reviewQuestion: reviewQuestion,
        }),
      );
      void submitCustomerReview({
        variantId: reviews[current].variantId,
        rating: submittedRating,
        feedback: values.feedback,
      });
    }
    nextReview();
  };

  return (
    <>
      <Modal
        title={
          <div style={{ textAlign: "center", fontSize: 16 }}>
            {t("Ti è piaciuto? 💚")}
          </div>
        }
        destroyOnClose
        open={openModal}
        forceRender={true}
        onCancel={() => {
          trackUserAction(new DishReviewClosed());
          setOpenModal(false);
          setReviewCooldown();
        }}
        footer={
          <div className={profileStyles.smallModalFooter}>
            {!submittedRating && (
              <Button
                className={profileStyles.button}
                style={{ marginBottom: 8, background: "transparent" }}
                onClick={() => {
                  if (reviews)
                    trackUserAction(
                      new DishReviewSkipped({
                        productSku: reviews[current].sku,
                        reviewQuestion: reviewQuestion,
                      }),
                    );
                  nextReview();
                }}
              >
                {t("Non l'ho ancora mangiato")}
              </Button>
            )}
            {submittedRating && (
              <Button
                className={profileStyles.button}
                style={{ marginBottom: 8, marginInlineStart: 0 }}
                onClick={form.submit}
                type={"primary"}
              >
                {t("Avanti")}
              </Button>
            )}
          </div>
        }
      >
        <div className={styles.modalImage}>
          {reviews && reviews[current].content}
        </div>
        <div className={styles.dishTitle}>
          {reviews && reviews[current].title}
        </div>

        <Form
          form={form}
          layout={"vertical"}
          onFinish={onSubmit}
          className={styles.form}
        >
          {!submittedRating && (
            <Form.Item name="rating">
              <div className={styles.question}>{t(reviewQuestion)}</div>
              <ScaleInput form={form} />
            </Form.Item>
          )}
          {submittedRating && (
            <Form.Item name="feedback" label={t("Hai qualcosa da aggiungere?")}>
              <Input.TextArea />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default RecipesReviewModal;
