import { Col, Row, Select, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface ExperimentSwitchProps {
  title: string;
  options: { label: string; value: number | null }[];
  error?: string;
  selectedOption: number;
  onChange: (value: number | null) => void;
}

const ExperimentSwitcher = ({
  title,
  options,
  selectedOption,
  onChange,
  error,
}: ExperimentSwitchProps) => {
  return (
    <Row style={{ padding: 10 }}>
      <Col span={10}>
        <Tooltip title={error}>
          <span style={{ color: error ? "red" : "inherit" }}>
            {title} {error && <QuestionCircleOutlined />}{" "}
          </span>
        </Tooltip>
      </Col>
      <Col span={14}>
        <Select
          value={selectedOption}
          style={{ width: "100%" }}
          onSelect={onChange}
          options={options}
        />
      </Col>
    </Row>
  );
};

export default ExperimentSwitcher;
