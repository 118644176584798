import { useContext } from "react";
import { CartContext } from "../Cart/CartContext";

const useCart = () => {
  const cart = useContext(CartContext);
  if (!cart) {
    throw new Error("useCart must be used inside a valid CartContext");
  }
  return cart;
};

export default useCart;
