import { OrderType } from "../../Networking/PastOrdersContext";
import homeStyles from "../../Home/styles.module.css";

interface HomepageOrderProps {
  order: OrderType;
}

export const HomepageOrder = ({ order }: HomepageOrderProps) => {
  console.log(order);

  var today = new Date();
  var shippingDate = new Date(order.expectedShippingDate);

  return (
    <div
      className={homeStyles.banner + " " + homeStyles.bannerBox}
      style={{ marginTop: 16 }}
    >
      <img
        className={homeStyles.orderIcon}
        src={"/icons/dish.svg"}
        alt={"yummy dish"}
      />
      <h1 className={homeStyles.title}>
        {order.fulfillmentStatus === "unfulfilled"
          ? "Ordine completato."
          : order.fulfillmentStatus === "fulfilled" && shippingDate > today
            ? "La tua Box è stata spedita!"
            : order.fulfillmentStatus === "fulfilled" && shippingDate < today
              ? "La tua Box è stata consegnata!"
              : ""}
      </h1>
      <p className={homeStyles.textLeft}>
        {shippingDate > today
          ? "Data di consegna prevista: "
          : "Consegnata il: "}
        <br />
        <b>{shippingDate.toLocaleDateString([])}</b>
      </p>
      <p className={homeStyles.textLeft}>
        Numero ordine: <br />
        <b>{order.name}</b>
      </p>
      {order.fulfillmentStatus === "unfulfilled" && (
        <p
          className={homeStyles.textLeft}
          style={{ fontSize: 14, marginBottom: 0 }}
        >
          Ti abbiamo spedito una mail con i dettagli del tuo ordine.
        </p>
      )}
    </div>
  );
};
