import { LineItem } from "../Cart/LineItem";
import { OrderFulfillmentStatus } from "../common/me";
import { createContext, useContext } from "react";

interface OrderLineItem {
  id: string;
  quantity: number;
  sku: string;
  price: string;
  variantId: string;
}

interface RawOrderType {
  id: string;
  name: string;
  createdAt: string;
  lineItems: OrderLineItem[];
  fulfillmentStatus: OrderFulfillmentStatus;
  cancelledAt: string;
  expectedShippingDate: string;
}

export enum QaplaOrderStatus {
  ATTESA_RITIRO = "ATTESA RITIRO",
  CONSEGNATO = "CONSEGNATO",
  ECCEZIONE = "ECCEZIONE",
  IN_CONSEGNA = "IN_CONSEGNA",
  IN_LAVORAZIONE = "IN LAVORAZIONE",
  IN_TRANSITO = "IN TRANSITO",
  PARTITO = "PARTITO",
  PUNTO_DI_RITIRO = "PUNTO DI RITIRO",
  RIENTRATO = "RIENTRATO",
}

export enum OrderStatus {
  CANCELLED = "Cancelled",
  DELIVERED = "Delivered",
  SHIPPED = "Shipped",
  PROCESSING = "Processing",
}

interface OrderType {
  id: string;
  name: string;
  createdAt: string;
  lineItems: LineItem[];
  fulfillmentStatus: OrderFulfillmentStatus;
  cancelledAt?: string;
  expectedShippingDate: string;
  expectedDeliveryDate?: string;
  status: OrderStatus;
  qaplaStatus?: QaplaOrderStatus;
}

interface PastOrdersContextProps {
  orders?: OrderType[];
  loading: boolean;
}

const PastOrdersContext = createContext<PastOrdersContextProps | null>(null);

export const usePastOrders = () => {
  const context = useContext(PastOrdersContext);

  return { orders: context?.orders, isLoading: context?.loading };
};

export default PastOrdersContext;
export type { OrderType, RawOrderType };
