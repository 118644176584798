import { BoxConfig, useSettings } from "../Networking/useSettings";
import { useMe } from "../AppSetup/MeContext";
import { useContext, useMemo } from "react";
import { CartContext } from "../Cart/CartContext";
import { useTranslation } from "react-i18next";

export const useSettingsBoxConfig = (): BoxConfig[] => {
  const me = useMe();
  const { t } = useTranslation();

  const settings = useSettings();

  return useMemo(() => {
    if (!settings || !me) {
      return [];
    }

    let baseBoxConfig = settings.boxConfig.map((box) => {
      if (box.isTrial) {
        return box;
      }
      return {
        ...box,
        name: t("boxName", { count: box.items }).toString(),
      };
    });
    if (settings.trialBoxPriceOverride) {
      baseBoxConfig = baseBoxConfig.map((box) => {
        if (box.isTrial) {
          return {
            ...box,
            comparedPrice: box.price,
            price: settings.trialBoxPriceOverride?.toString(),
          } as BoxConfig;
        }
        return box;
      });
    }

    if (
      settings.firstPurchaseDiscountCode &&
      settings.firstPurchaseDiscountAmount &&
      !me.latestOrder
    ) {
      baseBoxConfig = baseBoxConfig.map((box) => {
        return {
          ...box,
          originalPrice: box.originalPrice || box.price,
          comparedPrice: box.price,
          price: (parseFloat(box.price) - settings.firstPurchaseDiscountAmount!)
            .toFixed(2)
            .toString(),
        };
      });
    }

    const draftOrderTotalItems =
      me.order?.lineItems.reduce((tot, li) => tot + li.quantity, 0) || 0;

    return baseBoxConfig.filter((box) => {
      if (!me) {
        return false;
      }
      if (box.items === draftOrderTotalItems) {
        return true;
      }
      if (box.isTrial) {
        return settings.enableTrialBox && !me.isMember && !me.latestOrder;
      }
      return true;
    });
  }, [settings, me, t]);
};

export const useBoxConfig = (): BoxConfig[] => {
  const cart = useContext(CartContext);
  const settingsBoxConfig = useSettingsBoxConfig();
  if (cart) {
    return cart.boxConfig;
  }
  return settingsBoxConfig;
};

export type { BoxConfig };
