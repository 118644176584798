import { Col, Row, Space } from "antd";
import Logo from "../../Home/HomeComponents/Logo";
import useCart from "../../Hooks/useCart";
import ProductComponent from "../../Products/Product";
import { Product } from "../../common/product";
import { useMe } from "../../AppSetup/MeContext";
import { HomepageOrder } from "./HomepageOrder";

const OrderRecap = () => {
  const { lineItems } = useCart();
  const me = useMe();

  const getQuantityForProduct = (product: Product): number => {
    return lineItems.find((li) => li.product.id === product.id)?.quantity || 0;
  };

  const productsList = lineItems.map((product) => (
    <ProductComponent
      editable={false}
      membershipInCart={false}
      key={product.product.id}
      product={product.product}
      inCart={getQuantityForProduct(product.product)}
    />
  ));

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <Row style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
        <Logo small />
      </Row>
      <Row justify="center">
        <Col
          span={8}
          xs={24}
          md={12}
          xl={8}
          style={{ padding: 24, paddingTop: 0 }}
        >
          {me.latestOrder && <HomepageOrder order={me.latestOrder} />}

          <div style={{ margin: -16, marginTop: 0 }}>{productsList}</div>
        </Col>
      </Row>
    </Space>
  );
};

export default OrderRecap;
