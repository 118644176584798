import styles from "./styles.module.css";
import { Col, Divider, Row, Switch } from "antd";
import React from "react";

interface MembershipListItemProps {
  editable: boolean;
  switchValue: boolean;
  onToggle: (value: boolean) => void;
}

const MembershipListItem = ({
  editable,
  switchValue,
  onToggle,
}: MembershipListItemProps) => {
  return (
    <Row gutter={16} style={{ paddingTop: 8 }}>
      <div
        className={styles.imageBadge}
        style={
          switchValue
            ? { backgroundImage: "url('/icons/premium.svg')" }
            : { backgroundImage: "url('/icons/premium-grey.svg')" }
        }
      />
      <Col
        flex="auto"
        className={styles.itemTitle}
        style={{ maxWidth: "calc(100% - 105px)" }}
      >
        Membership
      </Col>
      {editable && (
        <Switch
          checked={switchValue}
          onChange={onToggle}
          className={styles.membershipSwitch}
        />
      )}
      <Divider className={styles.divider} />
    </Row>
  );
};

export default MembershipListItem;
