import { useEndpoint } from "./common";
import { useMe } from "../AppSetup/MeContext";
import { useSettings } from "./useSettings";
import dayjs from "dayjs";

enum ShippingSlot {
  mattina = "mattina",
  pomeriggio = "pomeriggio",
  tuttoIlGiorno = "tuttoIlGiorno",
}

interface ShippingConfig {
  [x: string]: ShippingSlot[];
}

export const useAllowedShippingConfig = (params: {
  minOffset?: number;
  maxDate?: string;
  maxOffset?: number;
}) => {
  const { shippingWeekdaysBlocked, country } = useSettings();
  const { isSuperUser } = useMe();

  const countryMap: { [key: string]: "IT" | "DE" } = {
    italy: "IT",
    germany: "DE",
  };

  const { data: shippingConfig } = useEndpoint<ShippingConfig>(
    "/shipping_schedule",
    { ...params, country: country ? countryMap[country.toLowerCase()] : "IT" },
  );

  if (isSuperUser) {
    return null;
  }

  if (shippingWeekdaysBlocked && shippingConfig) {
    return Object.entries(shippingConfig).reduce((acc, [key, value]) => {
      if (shippingWeekdaysBlocked.includes(dayjs(key).day().toString())) {
        return acc;
      }
      return { ...acc, [key]: value };
    }, {});
  }
  return shippingConfig;
};

export { ShippingSlot };
