import { Button, Carousel, Modal, Row, Spin, Tooltip } from "antd";
import styles from "./styles.module.css";
import React, { useContext, useState } from "react";
import { Product } from "../common/product";
import { CartContext } from "../Cart/CartContext";
import { NewOrderCTA } from "../Home/HomeComponents/NewOrderBanner";
import { useSettings } from "../Networking/useSettings";
import { MembershipCTASelected } from "../Networking/Metrics/UserActions/Shop";
import useUATracker from "../Networking/Metrics/useUATracker";
import useApiClient from "../Networking/useApiClient";
import { ContactExpertCTASelected } from "../Networking/Metrics/UserActions/common";
import MeContext from "../AppSetup/MeContext";
import { useTranslation } from "react-i18next";
import NutritionalValuesTable from "./NutritionalValuesTable";

interface ProductModalProps {
  open: boolean;
  onCancel: () => void;
  editable: boolean;
  product: Product;
  onAdd?: () => void;
  onRemove?: () => void;
  canAdd: boolean;
  cantAddReason?: string;
  cantRemoveReason?: string;
  canRemove: boolean;
  inCart?: number;
  maxCartSize?: number;
}

const ProductModal = ({
  open,
  product,
  onCancel,
  editable,
  onAdd,
  onRemove,
  canAdd,
  canRemove,
  cantAddReason,
  cantRemoveReason,
  inCart,
}: ProductModalProps) => {
  const [alertOpened, setAlertOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { trackUserAction, sendRecentEvents } = useUATracker();
  const { getMembershipCheckoutUrl } = useApiClient();
  const meContext = useContext(MeContext);
  const { t } = useTranslation();

  const settings = useSettings();

  const cart = useContext(CartContext);

  const goToMembership = async () => {
    if (!settings.membership) {
      return;
    }
    setIsLoading(true);
    trackUserAction(new MembershipCTASelected());
    const checkoutUrl = await getMembershipCheckoutUrl(settings.membership.sku);
    if (checkoutUrl) {
      window.location.assign(checkoutUrl);
    }
    setIsLoading(false);
  };

  const renderFooter = () => {
    if (!editable) {
      return null;
    }
    if (!cart) {
      // We're in the Menu. If the user is a member, show the Create new order CTA
      if (!meContext?.me.isMember) {
        return (
          <Button
            className={
              styles.button + " " + styles.primaryGreen + " " + styles.modalBtn
            }
            onClick={() => {
              setAlertOpened(!alertOpened);
            }}
          >
            Attiva la Membership per Ordinare
          </Button>
        );
      } else {
        return <NewOrderCTA />;
      }
    }
    if (inCart) {
      return [
        <Row align="middle" justify="center" className={styles.modalEditBar}>
          <div style={{ display: "flex" }}>
            <Tooltip title={cantRemoveReason}>
              <Button
                onClick={onRemove}
                block
                disabled={!canRemove}
                className={styles.editBtn + " " + styles.modalEditBtn}
              >
                <img
                  className={styles.editIcon}
                  src="/icons/minus.svg"
                  alt="remove"
                />
              </Button>
            </Tooltip>
          </div>
          <div className={styles.qtyLabel}>{inCart || 0}</div>
          <div style={{ display: "flex" }}>
            <Tooltip title={cantAddReason}>
              <Button
                block
                onClick={onAdd}
                disabled={!canAdd}
                className={styles.editBtn + " " + styles.modalEditBtn}
              >
                <img
                  className={styles.editIcon}
                  src="/icons/plus.svg"
                  alt="add"
                />
              </Button>
            </Tooltip>
          </div>
        </Row>,
      ];
    } else {
      return (
        <Button
          onClick={onAdd}
          key="add to order"
          disabled={!canAdd}
          className={
            styles.button + " " + styles.primaryGreen + " " + styles.modalBtn
          }
        >
          {canAdd ? "Aggiungi all'Ordine" : "Scorte Esaurite"}
        </Button>
      );
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className={styles.fullscreenModal}
      style={{ top: 0 }}
      destroyOnClose={true}
      closeIcon={
        <img
          className={styles.closeIcon}
          src="/icons/close-modal2.svg"
          alt="close"
        />
      }
      footer={renderFooter()}
    >
      <Carousel className={styles.modalImage} dots={{ className: styles.dots }}>
        {product.images.map((image, index) => (
          <img
            key={`${product.id}-image-${index}`}
            alt={image.alt}
            src={image.src}
          />
        ))}
      </Carousel>
      <div className={styles.detailsTitleRow}>
        <h4 className={styles.detailsTitle}> {product.title} </h4>
      </div>
      {product.cooki?.portionWeight && <b>{product.cooki.portionWeight}g</b>}
      {product.cooki?.ingredients && (
        <p
          className={styles.descriptionBody}
          dangerouslySetInnerHTML={{ __html: product.cooki.ingredients }}
        />
      )}
      {product.cooki?.allergens && (
        <b>
          {t("Può contenere: ")}
          {product.cooki.allergens}
        </b>
      )}
      {product.macros && <NutritionalValuesTable product={product} />}
      <p
        className={styles.descriptionBody}
        dangerouslySetInnerHTML={{ __html: product.description }}
      />
      <Modal
        open={alertOpened}
        onCancel={() => setAlertOpened(!alertOpened)}
        className={styles.alert}
        centered
        closeIcon={
          <img
            className={styles.closeIcon}
            src="/icons/close-modal.svg"
            alt="close"
          />
        }
        footer={
          <>
            {settings.enableExpertCta && (
              <Button
                onClick={() => {
                  trackUserAction(new ContactExpertCTASelected());
                  void sendRecentEvents();
                  window.open(settings.supportUrl);
                }}
                key="contact expert"
                className={styles.button}
                style={{ marginBottom: 8 }}
              >
                Contatta la Tua Expert
              </Button>
            )}
            <Button
              onClick={goToMembership}
              key="contact expert"
              className={styles.button + " " + styles.yellow}
              style={{ marginInlineStart: 0 }}
            >
              {isLoading ? <Spin /> : "Attiva Membership"}
            </Button>
          </>
        }
      >
        <img src="/icons/membership.svg" alt="membership" />
        <h4 className={styles.alertTitle}> Vuoi creare un nuovo ordine? </h4>
        <p className={styles.descriptionBody}>
          Puoi creare un nuovo ordine contattando la tua expert, o attivando la
          tua membership.
        </p>
      </Modal>
    </Modal>
  );
};

export default ProductModal;
