import { usePastOrders } from "../Networking/PastOrdersContext";
import { Col, Row, Space, Spin } from "antd";
import SecondaryPageHeader from "../PageTitle/SecondaryPageHeader";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import Order from "../Order/Order";

const PastOrders = () => {
  const { orders } = usePastOrders();
  const { t } = useTranslation();

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <SecondaryPageHeader>{t("I tuoi ordini")}</SecondaryPageHeader>
      <Row justify="center">
        <Col span={8} xs={24} md={12} xl={8}>
          {orders && orders.length > 0 ? (
            orders.map((order) => <Order key={order.id} order={order} />)
          ) : orders && orders.length === 0 ? (
            <p className={styles.bodySmall}>
              {t("Non hai ancora effettuato ordini!")}
            </p>
          ) : (
            <Spin
              style={{
                color: "#000000",
                width: "100vw",
                height: "20vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          )}
        </Col>
      </Row>
    </Space>
  );
};

export default PastOrders;
