import { Col, Row, Space, Button, Spin } from "antd";
import React, { useState } from "react";
import ProductComponent from "../Products/Product";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { tagToFilter } from "../Hooks/useProductFilters";
import filterProducts from "../Products/Filters/filterProducts";
import FiltersBar from "../Products/Filters/FiltersBar";
import { useServerProducts } from "../Networking/useProducts";

interface HumamyMenuProps {
  collectionId?: string;
  hideShopButton?: boolean;
}

const HumamyMenu = ({ collectionId, hideShopButton }: HumamyMenuProps) => {
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const { products, isLoading } = useServerProducts(collectionId);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const filteredProducts = filterProducts({
    selectedFilters,
    products,
    filter,
  });

  const allTags =
    products?.reduce(
      (acc, product) => [...acc, ...product.tags],
      [] as string[],
    ) || [];
  const uniqueTags = Array.from(new Set(allTags));
  const filters = uniqueTags
    .filter((tag) => tag.startsWith("filter:"))
    .map(tagToFilter);

  const productsList = filteredProducts.map((product) => (
    <ProductComponent
      editable={false}
      membershipInCart={false}
      key={product.id}
      product={product}
    />
  ));

  return (
    <Space direction={"vertical"} size={0} style={{ width: "100%" }}>
      <Row justify="center">
        <img
          alt={"logo Humamy"}
          src={"/logo.png"}
          style={{ height: 80, margin: 24 }}
        />
      </Row>
      <Row justify="center">
        <span className={styles.bigTitle}>Menu</span>
      </Row>
      <Row justify="center">
        <Col span={8} xs={24} md={12} xl={8}>
          {isLoading ? (
            <Spin />
          ) : (
            <FiltersBar
              setFilter={setFilter}
              filters={filters}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          )}
        </Col>
      </Row>
      <Row justify="center">
        <Col span={8} xs={24} md={12} xl={8}>
          {productsList}
        </Col>
      </Row>

      {!hideShopButton && (
        <Row align="middle" justify="center" className={styles.modalEditBar}>
          <Button
            onClick={() => navigate("/")}
            className={styles.button + " " + styles.primary}
          >
            {t("Vai allo Shop")}
          </Button>
        </Row>
      )}
    </Space>
  );
};

export default HumamyMenu;
