import styles from "./styles.module.css";
import { Product } from "../common/product";
import { Col, Divider, Row } from "antd";
import React from "react";
import CartButtons from "../Shop/CartButtons";

interface ListItemProps {
  item: {
    product: Product;
    quantity: number;
  };
  editable: boolean;
  last?: boolean;
}

const ListItem = ({ item, last, editable }: ListItemProps) => {
  const product = item.product;
  return (
    <Row gutter={16} style={{ alignItems: "center" }}>
      <div
        className={styles.image}
        style={{ backgroundImage: "url(" + product.images[0].src + ")" }}
      />
      <Col
        flex="auto"
        className={styles.itemTitle}
        style={
          editable
            ? { maxWidth: "calc(100% - 184px)" }
            : { maxWidth: "calc(100% - 120px)" }
        }
      >
        {product.title}
      </Col>

      {editable ? (
        <Col
          flex="auto"
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
          }}
        >
          <CartButtons product={product} isInCart={true} />
        </Col>
      ) : (
        <div className={styles.qtyLabel}> x {item.quantity} </div>
      )}
      {!last && <Divider className={styles.divider} />}
    </Row>
  );
};

export default ListItem;
