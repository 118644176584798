import type { MenuProps } from "antd";
import { Col, Dropdown, Row, Space } from "antd";
import React, { useState } from "react";
import styles from "./styles.module.css";
import { useProducts } from "../../Networking/useProducts";
import { useEnsuredSubscriptions } from "../../Hooks/useSubscriptions";
import useCart from "../../Hooks/useCart";
import ProductCarouselCard from "./ProductCarouselCard";
import { useDiscount } from "../../Hooks/useDiscount";
import { useTranslation } from "react-i18next";

const SelectSubscription = () => {
  const subsMap = useEnsuredSubscriptions();
  const { t } = useTranslation();

  const { selectedSub, setSelectedSub } = useCart();

  const [selectedFrequency, _setSelectedFrequency] = useState<number>(
    selectedSub ? selectedSub.period.count : parseInt(Object.keys(subsMap)[0]),
  );

  const setSelectedFrequency = (frequency: number) => {
    _setSelectedFrequency(frequency);
    if (selectedSub && subsMap[frequency][selectedSub.boxSize]) {
      setSelectedSub(subsMap[frequency][selectedSub.boxSize]);
    } else {
      setSelectedSub(undefined);
    }
  };

  const { products } = useProducts();
  const discount = useDiscount();

  const items: MenuProps["items"] = subsMap
    ? Object.keys(subsMap).map((frequency) => {
        return {
          key: frequency,
          label: `Ogni ${frequency} settimane`,
        };
      })
    : [];

  return (
    <Row justify="center">
      <Col
        span={8}
        xs={24}
        md={12}
        xl={8}
        style={{ padding: 24, paddingTop: 0 }}
      >
        <div className={styles.titleCentered}>Scegli la tua box</div>
        <p className={styles.text}>
          {t(
            "Potrai comporla con i piatti che desideri. Cancella l’iscrizione in qualsiasi momento, senza vincoli!",
          )}
        </p>

        <Dropdown
          menu={{
            items,
            onClick: (item) => setSelectedFrequency(parseInt(item.key)),
          }}
          trigger={["click"]}
          className={styles.frequencyDropdown}
        >
          <Space>
            <span>
              <b>{t("Frequenza")}:</b> {t("ogni")} {selectedFrequency}{" "}
              {t("settimane")}
            </span>
            <img src="/icons/down_small.svg" alt="open frequency selector" />
          </Space>
        </Dropdown>

        <div style={{ marginTop: 24 }}>
          {Object.keys(subsMap[selectedFrequency]).map((key) => {
            const boxSize = parseInt(key);
            return (
              <div
                key={`sub-box-${key}`}
                className={
                  styles.boxSelector +
                  " " +
                  (selectedSub?.boxSize === boxSize
                    ? styles.boxSelectorActive
                    : "")
                }
                onClick={() => {
                  setSelectedSub(subsMap[selectedFrequency][key]);
                }}
              >
                <div style={{ fontWeight: 800 }}>
                  <span className={styles.largeText}>{boxSize}</span>
                  &nbsp; piatti
                </div>
                <div style={{ fontSize: 16 }}>
                  {discount?.amount && (
                    <>
                      <span
                        style={{
                          color: "#8F959E",
                          textDecoration: "line-through",
                        }}
                      >
                        {(
                          subsMap[selectedFrequency][key].price /
                          100 /
                          boxSize
                        ).toFixed(2)}
                        €
                      </span>
                      &nbsp;&nbsp;
                    </>
                  )}
                  <b>
                    {(
                      (subsMap[selectedFrequency][key].price / 100 -
                        (discount?.amount || 0)) /
                      boxSize
                    ).toFixed(2)}
                    €
                  </b>
                  <span style={{ opacity: 0.5 }}>&nbsp; / piatto</span>
                </div>
              </div>
            );
          })}
        </div>

        <div className={styles.fullWidthBanner}>
          <img
            style={{ marginBottom: -8 }}
            className={styles.modalImage}
            src={"/images/generic-dishes.avif"}
            alt={"delicious dishes"}
          />
          <h1 className={styles.title}>{t("Come funziona Humamy?")}</h1>
          <div className={styles.listItem}>
            <img
              src={"/icons/check_green.svg"}
              alt={"check"}
              style={{ marginTop: 2 }}
            />
            <div>
              <b>{t("Completamente personalizzabile")}</b>
              <br />
              {t(
                "Scegli la frequenza e la quantità di piatti in base alle tue abitudini.",
              )}
            </div>
          </div>
          <div className={styles.listItem}>
            <img
              src={"/icons/check_green.svg"}
              alt={"check"}
              style={{ marginTop: 2 }}
            />
            <div>
              <b>{t("Interrompi o cancella l’abbonamento quando vuoi")}</b>
              <br />
              {t(
                "Puoi disdire in qualsiasi momento, senza vincoli o costi aggiuntivi.",
              )}
            </div>
          </div>
          <div className={styles.listItem}>
            <img
              src={"/icons/check_green.svg"}
              alt={"check"}
              style={{ marginTop: 2 }}
            />
            <div>
              <b>{t("Nuovi piatti ogni mese")}</b>
              <br />
              {t(
                "Il nostro menù usa ingredienti stagionali, gusta novità diverse ogni mese!",
              )}
            </div>
          </div>
        </div>

        <h1 className={styles.title} style={{ marginTop: 48 }}>
          {t("Sbircia il menu del mese")}
        </h1>
        <div className={styles.carousel}>
          {products.map((product) => {
            return (
              <ProductCarouselCard
                key={`product-carousel-${product.id}`}
                product={product}
              />
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default SelectSubscription;
