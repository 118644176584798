import styles from "./styles.module.css";
import { Row, Col, Divider } from "antd";

interface MembershipBoxOptionProps {
  name: string;
  items: number;
  price: string;
  nonMembershipPrice: string;
  layout: string;
}

const MembershipBoxOption = ({
  name,
  items,
  price,
  nonMembershipPrice,
  layout,
}: MembershipBoxOptionProps) => {
  return layout === "doublePrice" ? (
    <Row
      className={styles.listItem + " " + styles.listItemBox}
      style={{ marginBottom: -6 }}
    >
      <Col span={3}>
        <img src={`/icons/box-${items}.svg`} alt={`box da ${items}`} />
      </Col>
      <Col span={6}>
        <b>{name}</b>
      </Col>
      <Col span={5}>{nonMembershipPrice}€</Col>
      <Col span={1}>
        <Divider type={"vertical"} style={{ margin: 0 }} />
      </Col>
      <Col span={6}>
        <b>{price}€</b>
        <span className={styles.premiumIcon} />
      </Col>
    </Row>
  ) : layout === "singlePrice" ? (
    <div className={styles.listItem + " " + styles.listItemBox}>
      <img src={`/icons/box-${items}.svg`} alt={`box da ${items}`} />
      <div>
        <b style={{ fontSize: 18 }}>{name}</b> &nbsp; {price ? price + "€" : ""}
      </div>
    </div>
  ) : null;
};

export default MembershipBoxOption;
