import { useSettings } from "../Networking/useSettings";
import { useMemo } from "react";

export const useSubscriptions = () => {
  const { subscriptions } = useSettings();

  return useMemo(() => {
    if (!subscriptions) {
      return undefined;
    }
    return Object.fromEntries(
      subscriptions.map((sub) => [
        sub.period.count,
        Object.fromEntries(
          subscriptions
            .filter((s) => s.period.count === sub.period.count)
            .map((s) => [s.boxSize, s]),
        ),
      ]),
    );
  }, [subscriptions]);
};

export const useEnsuredSubscriptions = () => {
  const subscriptions = useSubscriptions();

  if (!subscriptions) {
    throw new Error(
      "Ensure that this component is only used in a subscription context.",
    );
  }

  return subscriptions;
};
