import styles from "./styles.module.css";
import { Button, Carousel, Col, Modal, Row } from "antd";
import React, { useRef, useState } from "react";

interface OnboardingModalProps {
  open: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const OnboardingModal: React.FC<OnboardingModalProps> = ({
  open,
  onOk,
  // onCancel
}) => {
  const [onboardingPosition, setOnboardingPosition] = useState<number>(0);
  const ref = useRef<any>(null);

  const onboardingSteps = [
    {
      title: "Personalizza il tuo abbonamento",
      description:
        "Scegli frequenza, dimensione della Box e piatti. Disdici quando vuoi.",
      image: "/images/onboarding-1.jpg",
    },
    {
      title: "In regalo per te uno sconto di 50€ sulla prima Box!",
      description: "Applicato direttamente al checkout.",
      image: "/images/onboarding-2.jpg",
    },
    {
      title: "I piatti sono surgelati, rigenerali in soli 5 minuti",
      description: "Ma durano fino a 6 mesi nel tuo freezer!",
      image: "/images/onboarding-3.jpg",
    },
    {
      title: "Non sei in casa?",
      description:
        "Nessun problema! Imballiamo con ghiaccio secco: il cibo si conserva fino alla sera del giorno di consegna.",
      image: "/images/onboarding-4.jpg",
    },
  ];

  return (
    <Modal
      title="Come funziona?"
      destroyOnClose
      open={open}
      onOk={onOk}
      // onCancel={onCancel}
      className={styles.detailsModal}
      forceRender={true}
      closeIcon={null}
      footer={
        <Row align="middle" justify="center" className={styles.modalEditBar}>
          <Col span={24} style={{ display: "flex" }}>
            <Button
              onClick={() => {
                ref.current?.prev();
              }}
              className={styles.buttonArrow}
              style={
                onboardingPosition === 0
                  ? { width: 0, padding: 0, margin: 0 }
                  : { width: 60 }
              }
            >
              <img
                src="/icons/next_small_grey.svg"
                alt={"next"}
                style={{ transform: "rotate(180deg)" }}
              />
            </Button>
            <div style={{ flex: 1 }}>
              <Button
                onClick={onOk}
                className={styles.button + " " + styles.primary}
                style={
                  onboardingPosition === 3
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                OK, Cominciamo!
              </Button>
              <Button
                onClick={() => {
                  ref.current?.next();
                }}
                className={styles.button + " " + styles.primary}
                style={
                  onboardingPosition < 3
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                Avanti
                <img
                  src="/icons/next_small_black.svg"
                  alt={"next"}
                  style={{ marginTop: 2 }}
                />
              </Button>
            </div>
          </Col>
        </Row>
      }
    >
      <Carousel
        className={styles.modalImage}
        dots={{ className: styles.dots }}
        infinite={false}
        ref={ref}
        afterChange={(e) => {
          setOnboardingPosition(e);
        }}
      >
        {onboardingSteps.map((step, index) => (
          <div key={`onboarding-step-${index}`}>
            <div className={styles.imgContainer}>
              <img
                alt={step.title}
                key={step.image}
                src={step.image}
                className={styles.onboardingImage}
              />
            </div>

            <div className={styles.bottom}>
              <h3 className={styles.onboardingTitle}>{step.title}</h3>
              <p className={styles.onboardingDescription}>{step.description}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </Modal>
  );
};

export default OnboardingModal;
