import { Button, Modal, Row, Col, Switch } from "antd";
import styles from "../styles.module.css";
import { BoxConfig, useSettings } from "../../Networking/useSettings";
import { Product } from "../../common/product";
import ProductComponent from "../../Products/Product";
import MembershipDetailsModal from "../../Shop/MembershipDetailsModal";
import React, { useState } from "react";
import PriceBox from "../../Products/PriceBox";
import useCart from "../../Hooks/useCart";
import { useMe } from "../../AppSetup/MeContext";

interface BoxDetailsModalProps {
  open: boolean;
  total?: number;
  onConfirm?: () => void;
  onCancel: () => void;
  products: Array<Product>;
  boxTitle: string;
  box: BoxConfig;
  addMembershipToggle?: boolean;
}

const BoxDetailsModal = ({
  open,
  onCancel,
  products,
  boxTitle,
  box,
  onConfirm,
  addMembershipToggle,
}: BoxDetailsModalProps) => {
  const { membership, membershipPercentDiscount, freemiumMode } = useSettings();
  const { isMember } = useMe();
  const { membershipInCart, setMembership } = useCart();

  const [membershipModalopen, setMembershipModalOpen] = useState(false);
  const onMembershipConfirm = () => {
    setMembership(membership?.sku, false);
    setMembershipModalOpen(false);
  };
  const onMembershipCancel = () => {
    setMembershipModalOpen(false);
  };

  const MembershipOptionalTooltip = membership && (
    <Col style={{ position: "absolute", bottom: 75, padding: 16 }}>
      <div className={styles.membershipOptionalTooltip}>
        <Col flex="auto">
          <div>
            Attiva la membership Humamy per lo{" "}
            <b>sconto del {membershipPercentDiscount} %</b>
          </div>
          <div
            style={{ paddingTop: 8, textDecoration: "underline" }}
            onClick={() => setMembershipModalOpen(true)}
          >
            Dettagli
          </div>
        </Col>
        <Col flex="none">
          <Switch
            checked={!!membershipInCart}
            onChange={(value) =>
              setMembership(value ? membership.sku : undefined, true)
            }
            style={{ verticalAlign: "unset" }}
          />
        </Col>
        <MembershipDetailsModal
          open={membershipModalopen}
          onCancel={onMembershipCancel}
          onConfirm={onMembershipConfirm}
          currentBox={box}
        />
      </div>
      <img
        alt={"tooltip"}
        src={`/images/tooltip-tip.svg`}
        className={styles.tooltipTip}
      />
    </Col>
  );

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className={
        styles.fullscreenModal +
        " " +
        styles.white +
        " " +
        styles.freemiumModal +
        " " +
        (freemiumMode && !isMember ? styles.paddingBottom : "")
      }
      style={{ top: 0 }}
      title={<h4 className={styles.detailsTitle}> {boxTitle} </h4>}
      closeIcon={
        <img className={styles.closeIcon} src="/icons/close.svg" alt="close" />
      }
      footer={
        <Row
          align="middle"
          justify="center"
          className={styles.ctaBar}
          style={{ top: "calc(100dvh - 90px)", height: "unset" }}
        >
          {addMembershipToggle && MembershipOptionalTooltip}

          <Button
            onClick={onConfirm}
            type="primary"
            className={styles.button + " " + styles.buttonLeftAligned}
          >
            <span>
              Continua
              <img
                alt={"next"}
                src={`/icons/next_small_grey.svg`}
                style={{ marginBottom: -3, marginLeft: 4 }}
              />
            </span>

            <div style={{ display: "flex", alignItems: "baseline", gap: 4 }}>
              {freemiumMode && (
                <PriceBox
                  price={
                    box.nonMembershipPrice || box.comparedPrice || box.price
                  }
                  strikethrough={!!membershipInCart || isMember}
                  highlight={true}
                  premium={false}
                />
              )}
              <PriceBox
                price={box.price}
                highlight={true}
                strikethrough={!membershipInCart && !isMember}
                premium={!!freemiumMode}
              />
            </div>
          </Button>
        </Row>
      }
    >
      <p className={styles.textLeft}>
        Il modo più conveniente per conoscere Humamy! {box.items} piatti,
        accuratamente selezionati tra i nostri best-seller.
      </p>

      <div style={{ marginLeft: -16, marginRight: -16 }}>
        {products.map((product) => {
          return (
            <ProductComponent
              editable={false}
              membershipInCart={false}
              key={product.id}
              product={product}
            />
          );
        })}
      </div>
    </Modal>
  );
};

export default BoxDetailsModal;
