import { filterToTag } from "../../Hooks/useProductFilters";
import { Product } from "../../common/product";

interface FilterProductsProps {
  selectedFilters: string[];
  products?: Product[];
  filter?: string;
}

const filterProducts = ({
  selectedFilters,
  products,
  filter,
}: FilterProductsProps): Product[] => {
  if (!products) {
    return [];
  } else {
    return products.filter((p) => {
      if (!filter || filter === "") {
        if (selectedFilters.length > 0) {
          return selectedFilters.every((f) => p.tags?.includes(filterToTag(f)));
        }
        return true;
      }
      const f = filter.toLowerCase();
      return (
        p.tags?.some((t) => t.includes(f)) ||
        p.title.toLowerCase().includes(f) ||
        p.description.toLowerCase().includes(f)
      );
    });
  }
};

export default filterProducts;
