import { Button, Divider, Form, Input, Modal, Space, Spin, Switch } from "antd";
import { useState } from "react";
import useApiClient from "../Networking/useApiClient";
import ExperimentSwitcher from "./ExperimentSwitch";
import Constants from "../common/constants";
import { useNavigate } from "react-router-dom";
import {
  useExperiments,
  useExperimentSegments,
} from "../Networking/useExperimentSegments";
import { useMe } from "../AppSetup/MeContext";
import dayjs from "dayjs";
import useUATracker from "../Networking/Metrics/useUATracker";
import { SubscriptionProfileEdited } from "../Networking/Metrics/UserActions/Shop";

interface UpdateDeliveryDateModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}
const UpdateDeliveryDateModal = ({
  open,
  setOpen,
}: UpdateDeliveryDateModalProps) => {
  const { updateDeliveryDate } = useApiClient();

  const { subscriptionProfile } = useMe();

  const [form] = Form.useForm();

  const onFinish = (values: { date: string }) => {
    void updateDeliveryDate(
      subscriptionProfile.latestOutboundOrder.name,
      dayjs(values.date),
    );
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onOk={form.submit}
      onCancel={() => {
        setOpen(false);
      }}
    >
      <h1>Update Delivery Date</h1>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          initialValue={
            subscriptionProfile?.latestOutboundOrder?.expectedShippingDate
          }
          label={"Delivery Date"}
          name={"date"}
        >
          <Input type={"date"} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const SecretMenu = () => {
  const [open, setOpen] = useState(false);

  const [updateDeliveryDateOpen, setUpdateDeliveryDateOpen] = useState(false);

  const { forceSegment, authAs } = useApiClient();

  const { experiments, isLoading: experimentsLoading } = useExperiments();
  const { segments, isLoading: segmentsLoading } = useExperimentSegments();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const { trackUserAction } = useUATracker();

  const { isSuperUser, setIsSuperUser } = useMe();

  const navigate = useNavigate();

  if (loading || segmentsLoading || experimentsLoading || !segments) {
    return <Spin />;
  }

  const setExperimentSegment = async (
    experimentName: string,
    value: number | null,
  ) => {
    setLoading(true);
    await forceSegment(experimentName, value);
    navigate("/");
    setLoading(false);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const getSegmentValue: (experimentName: string) => number = (
    experimentName,
  ) => {
    return segments.find((segment) => segment.experimentName === experimentName)
      ?.segmentIndex as number;
  };

  const getSegmentError = (experimentName: string): string | undefined => {
    const segment = segments.find(
      (segment) => segment.experimentName === experimentName,
    );
    if (!segment) {
      return undefined;
    }
    return segment.error;
  };

  return (
    <Space direction={"vertical"}>
      <Modal open={open} onOk={toggleOpen} onCancel={toggleOpen}>
        <Space direction={"vertical"} style={{ width: "100%" }}>
          <h1>Secret Menu</h1>

          <h3>Super powers: {isSuperUser ? "🦸💪" : "🤷‍♂️"}</h3>
          <Switch onChange={() => setIsSuperUser(!isSuperUser)} />

          <Divider />

          <Form>
            <Form.Item label={"Login as"}>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                placeholder={"Email"}
              />
            </Form.Item>
            <Button
              type={"primary"}
              htmlType={"submit"}
              onClick={() => authAs(email)}
            >
              Login as
            </Button>
          </Form>

          <Divider />

          {experiments!.map((experiment) => (
            <ExperimentSwitcher
              key={experiment.name}
              title={experiment.name}
              error={getSegmentError(experiment.name)}
              options={[
                { label: "❌", value: null },
                ...experiment.segments.map(({ name }, index) => ({
                  label: name,
                  value: index,
                })),
              ]}
              selectedOption={getSegmentValue(experiment.name)}
              onChange={(value) => setExperimentSegment(experiment.name, value)}
            />
          ))}

          <Divider />

          <Button
            onClick={() => {
              trackUserAction(
                new SubscriptionProfileEdited({
                  subscriptionIsActive: true,
                  subscriptionBoxSize: 15,
                  subscriptionBoxFrequency: "2",
                  subscriptionNextBoxDeliveryDate: dayjs(),
                }),
              );
            }}
          >
            Send MembershipUpdated Metric 📊
          </Button>

          <Divider />

          <Button
            onClick={() => {
              localStorage.removeItem(Constants.STORAGE_LINE_ITEMS_KEY);
              window.location.reload();
            }}
          >
            Clear Cart 🛒
          </Button>

          <Divider />

          <UpdateDeliveryDateModal
            open={updateDeliveryDateOpen}
            setOpen={setUpdateDeliveryDateOpen}
          />
          <Button onClick={() => setUpdateDeliveryDateOpen(true)}>
            Update delivery date of subscription order 📆
          </Button>
        </Space>
      </Modal>

      <Button type={"primary"} onClick={toggleOpen}>
        🤫 Secret Menu
      </Button>
    </Space>
  );
};

export default SecretMenu;
