import { Button, Modal, Row, Divider } from "antd";
import styles from "./styles.module.css";
import { BoxConfig, useSettings } from "../Networking/useSettings";

interface FreemiumModalProps {
  open: boolean;
  total: number;
  continueWithMembership: () => void;
  onCancel: () => void;
  continueWithoutMembership: () => void;
  currentBox: BoxConfig;
  discount?: number;
}

const FreemiumModal = ({
  open,
  onCancel,
  continueWithoutMembership,
  continueWithMembership,
  total,
  currentBox,
}: FreemiumModalProps) => {
  const { membership, membershipPercentDiscount } = useSettings();

  if (!membership) {
    return null;
  }

  const totalWithMembership =
    parseFloat(currentBox.price) +
    (!!membership.freeTrialPeriod ? 0 : parseFloat(membership.price));
  const totalWithoutMembership = total;

  const saving = totalWithoutMembership - totalWithMembership;

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className={
        styles.fullscreenModal + " " + styles.white + " " + styles.freemiumModal
      }
      style={{ top: 0 }}
      title={`${membershipPercentDiscount}% di sconto su tutti gli ordini`}
      closeIcon={
        <img className={styles.closeIcon} src="/icons/close.svg" alt="close" />
      }
      footer={
        <Row
          align="middle"
          justify="center"
          className={styles.ctaBar}
          style={{ top: "calc(100dvh - 90px)", height: "unset" }}
        >
          <Button
            onClick={continueWithoutMembership}
            className={
              styles.button +
              " " +
              styles.modalBtn +
              " " +
              styles.buttonLeftAligned
            }
          >
            Continua senza Membership
            <img
              alt={"next"}
              src={`/icons/next_small_grey.svg`}
              style={{ marginBottom: -3, marginLeft: 4 }}
            />
            <span>{total}€</span>
          </Button>
        </Row>
      }
    >
      <div className={styles.bannerModal}>
        <div className={styles.flexContainer}>
          <img
            style={{ marginBottom: -8 }}
            className={styles.modalImage}
            src={"/images/membership-dishes-freemium.webp"}
            alt={"delicious dishes"}
          />
          <div className={styles.sectionTitle}>
            Attiva la membership,
            <span className={styles.highlightYellow}>
              risparmi subito {saving.toFixed(2)}€!
            </span>
          </div>
          <div
            className={styles.listItem + " " + styles.listItemBox}
            style={{ gap: 8, marginBottom: -4 }}
          >
            <img
              src={`/icons/box-${currentBox.items}.svg`}
              alt={`box da ${currentBox.items}`}
            />
            <div>
              {currentBox.name}: &nbsp;
              <span className={styles.strikedPrice}>
                {currentBox.nonMembershipPrice}
              </span>{" "}
              &nbsp;
              <b>{currentBox.price}€</b>
              <span className={styles.premiumIcon} />
            </div>
          </div>
          <div
            className={styles.listItem + " " + styles.listItemBox}
            style={{ gap: 8, marginBottom: 0 }}
          >
            <img
              src={`/icons/premium.svg`}
              alt={`membership badge`}
              className={styles.membershipBadge}
            />
            {!!membership.freeTrialPeriod ? (
              <div>
                Membership: <b>Gratis</b> per {membership.freeTrialPeriod.count}{" "}
                {membership.freeTrialPeriod.unit}, poi{" "}
                <b>{membership.price}€</b> ogni {membership.period.count}{" "}
                {membership.period.unit}
              </div>
            ) : (
              <div>
                Membership: &nbsp; <b>{membership.price}€</b> / &nbsp;
                {membership.period.count} {membership.period.unit}
              </div>
            )}
          </div>
          <Divider />
          <div style={{ marginBottom: 24 }}>
            <b>
              Totale ordine: &nbsp; &nbsp;
              <span className={styles.strikedPrice}>{total}</span> &nbsp;
              {totalWithMembership.toFixed(2)} €
              <span className={styles.premiumIcon} />
            </b>
          </div>
        </div>

        <Button
          onClick={continueWithMembership}
          className={
            styles.button +
            " " +
            styles.primaryMembership +
            " " +
            styles.buttonLeftAligned
          }
          style={{ width: "100%", height: 60, flexGrow: 0 }}
        >
          Continua con Membership
          <img
            alt={"next"}
            src={`/icons/next_small_white.svg`}
            className={styles.buttonIcon}
          />
        </Button>
      </div>
    </Modal>
  );
};

export default FreemiumModal;
