import InnerHTML from "dangerously-set-html-content";

const CookieList = () => {
  const oneTrustHtml = `
    <!-- OneTrust Cookies Consent Notice end for new.humamy.com -->
    <div id="ot-sdk-cookie-policy"></div>
    <!-- OneTrust Cookies Consent Notice start for new.humamy.com -->
    <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8"
            data-domain-script="61001aaa-d97c-46eb-a173-a5c5e6ccfe9d"></script>
    <script type="text/javascript">
        function OptanonWrapper() {
        }
    </script>
`;
  return <InnerHTML html={oneTrustHtml} />;
};

export default CookieList;
