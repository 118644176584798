import { Button, Col, Modal, Row, Divider } from "antd";
import styles from "./styles.module.css";
import { BoxConfig, useSettings } from "../Networking/useSettings";
import { useBoxConfig } from "../Hooks/useBoxConfig";
import MembershipBoxOption from "./MembershipBoxOption";
import useCart from "../Hooks/useCart";

interface MembershipDetailsModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  currentBox?: BoxConfig;
  discount?: number;
}

const MembershipDetailsModalNew = ({
  open,
  onCancel,
  onConfirm,
}: MembershipDetailsModalProps) => {
  const boxConfig = useBoxConfig();
  const { membership, membershipPercentDiscount } = useSettings();
  const { membershipInCart } = useCart();

  if (!membership) {
    return null;
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className={
        styles.fullscreenModal +
        " " +
        styles.membershipDetails +
        " " +
        styles.newMembershipDetails
      }
      style={{ top: 0 }}
      closeIcon={
        <img
          className={styles.closeIcon}
          src="/icons/close-modal2.svg"
          alt="close"
        />
      }
      footer={
        <Row
          align="middle"
          justify="center"
          className={styles.ctaBar}
          style={{
            top: "calc(100dvh - 120px)",
            height: 120,
            justifyContent: "flex-end",
          }}
        >
          {!membershipInCart ? (
            <Button
              onClick={onConfirm}
              className={
                styles.button +
                " " +
                styles.modalBtn +
                " " +
                styles.primary +
                " " +
                styles.buttonLeftAligned
              }
            >
              Aggiungi Membership all’Ordine
              <img
                alt={"plus"}
                src={`/icons/plus_small.svg`}
                style={{ marginBottom: -3, marginLeft: 8 }}
              />
            </Button>
          ) : (
            <Button
              onClick={onConfirm}
              className={
                styles.button +
                " " +
                styles.modalBtn +
                " " +
                styles.buttonLeftAligned
              }
            >
              Rimuovi Membership dall’Ordine
              <img
                alt={"minus"}
                src={`/icons/minus_small.svg`}
                style={{ marginBottom: -3, marginLeft: 8 }}
              />
            </Button>
          )}
        </Row>
      }
    >
      <div className={styles.lightBlueSection}>
        <img
          className={styles.modalImage}
          src={"/images/membership-dishes.webp"}
          alt={"delicious dishes"}
        />
        <div className={styles.bigTitle}>
          Attiva la membership e compra Humamy a costo ridotto.
        </div>
        <div style={{ display: "flex", gap: 16 }}>
          <div className={styles.infoDetail}>
            <img src={"/icons/free-trial_blue.svg"} alt={"gift icon"} />
            Gratis per tre settimane
          </div>
          <div className={styles.infoDetail}>
            <img src={"/icons/discount_blue.svg"} alt={"discount tag icon"} />
            {membershipPercentDiscount}% di sconto su tutte le Box
          </div>
          <div className={styles.infoDetail}>
            <img src={"/icons/cancel_blue.svg"} alt={"cancel icon"} />
            Disdici in ogni momento
          </div>
        </div>
      </div>

      <div
        id="available-boxes"
        style={{ marginBottom: -32, marginTop: 20, color: "var(--green)" }}
      >
        <div
          className={styles.modalBody}
          style={{ marginBottom: "5dvh", fontSize: 16 }}
        >
          {membership.freeTrialPeriod && (
            <>
              <b>
                {membership.freeTrialPeriod.count}
                &nbsp;
                {membership.freeTrialPeriod.unit} di prova gratis
              </b>
              &nbsp;e poi&nbsp;
            </>
          )}
          <b>
            {membership.price}€ ogni {membership.period.count}{" "}
            {membership.period.unit}
          </b>
          , per avere sempre circa il{" "}
          <b>{membershipPercentDiscount}% di sconto su tutte le Box.</b>
        </div>

        <Row
          className={styles.listItem + " " + styles.listItemBox}
          justify="start"
        >
          <Col span={3}></Col>
          <Col span={6}></Col>
          <Col span={5}>
            <span style={{ fontSize: 14 }}>Normale</span>
          </Col>
          <Col span={1}>
            <Divider type={"vertical"} style={{ margin: 0 }} />
          </Col>
          <Col span={6}>
            <b style={{ fontSize: 14 }}>Membri</b>
          </Col>
        </Row>

        {boxConfig.map((box) => (
          <MembershipBoxOption
            key={box.name}
            name={box.name}
            items={box.items}
            price={box.price!}
            nonMembershipPrice={box.nonMembershipPrice!}
            layout={"doublePrice"}
          />
        ))}
      </div>
    </Modal>
  );
};

export default MembershipDetailsModalNew;
