import { OrderStatus, OrderType } from "../Networking/PastOrdersContext";
import styles from "./styles.module.css";
import { Card, Col, Collapse, Row } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import React from "react";
import { OrderStatusIcon } from "../Order/OrderIcon";
import { OrderTracking } from "../OrderTracking/OrderTracking";

const Order = ({ order }: { order: OrderType }) => {
  const { t } = useTranslation();
  const getDate = (stringDate: string) => {
    return new Date(stringDate).toLocaleDateString();
  };

  return (
    <Card
      title={`${t("Ordine")} ${order.name} ${t("del")} ${getDate(order.createdAt)}`}
      className={styles.orderCard}
    >
      <Row className={styles.orderDetail}>
        <Col span={24}>
          <OrderStatusIcon order={order} />
        </Col>
      </Row>

      {[OrderStatus.SHIPPED, OrderStatus.DELIVERED].includes(order.status) && (
        <div
          style={{
            marginLeft: -24,
            marginRight: -24,
            borderBottom: "1px #ededed solid",
            paddingBottom: 16,
          }}
        >
          <OrderTracking order={order} />
        </div>
      )}

      <Collapse
        ghost
        size="large"
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 180 : 0} />
        )}
        className={styles.collapse + " " + styles.orderCollapse}
        style={{ marginLeft: -24, marginRight: -24, marginTop: 0 }}
        items={[
          {
            label: t("Cosa contiene?"),
            children: order.lineItems.map((li) => (
              <p key={li.product.sku} className={styles.orderRow}>
                {li.product.title} <b>x{li.quantity}</b>
              </p>
            )),
          },
        ]}
      />
    </Card>
  );
};

export default Order;
