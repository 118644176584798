import useCart from "../Hooks/useCart";
import { Col, Row, Space } from "antd";
import { useSettings } from "../Networking/useSettings";
import styles from "./styles.module.css";

interface PriceRecapRowProps {
  label: string;
  strikedPrice?: number;
  price: string;
  premium?: boolean;
}

const PriceRecapRow = ({
  label,
  strikedPrice,
  price,
  premium,
}: PriceRecapRowProps) => {
  return (
    <Row style={{ width: "100%" }}>
      <Col span={12}>{label}</Col>
      <Col span={12} style={{ textAlign: "right" }}>
        {strikedPrice && (
          <span
            style={{ textDecoration: "line-through" }}
            className={styles.priceLabel}
          >
            {strikedPrice}
          </span>
        )}{" "}
        {price} €{" "}
        {premium && (
          <img
            alt={"premium"}
            src={"/icons/premium.svg"}
            style={{ height: 15 }}
          />
        )}
      </Col>
    </Row>
  );
};

const PriceRecap = () => {
  const {
    currentDisplayedBox,
    currentBoxPrice,
    membershipInCart,
    instalments,
  } = useCart();
  const { membership, freemiumMode } = useSettings();

  const comparedPrice = instalments
    ? parseFloat(currentDisplayedBox.price)
    : currentDisplayedBox.comparedPrice
      ? parseFloat(currentDisplayedBox.comparedPrice)
      : undefined;

  const rows = [
    <PriceRecapRow
      key={"cartPrice"}
      label={currentDisplayedBox.name}
      price={currentBoxPrice}
      strikedPrice={comparedPrice}
      premium={freemiumMode && !!membershipInCart}
    />,
  ];
  if (membership && membershipInCart && !membership.freeTrialPeriod) {
    rows.push(
      <PriceRecapRow
        key={"membership"}
        label={"Membership"}
        price={membership.price}
      />,
    );
  }
  return (
    <Space style={{ width: "100%" }} direction={"vertical"}>
      {rows}
    </Space>
  );
};

export default PriceRecap;
