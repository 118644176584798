import { Button, Col, Form, Input, Modal, Row, Spin } from "antd";
import React, { useState } from "react";
import styles from "./styles.module.css";
import useApiClient from "../Networking/useApiClient";
import useUATracker from "../Networking/Metrics/useUATracker";
import { UserProfileUpdated } from "../Networking/Metrics/UserActions/Profile";
import { useMe } from "../AppSetup/MeContext";
import { useTranslation } from "react-i18next";
import { useSettings } from "../Networking/useSettings";
import { AddressConfirmed } from "../Networking/Metrics/UserActions/Shop";

interface AddressModalProps {
  open: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const AddressModal: React.FC<AddressModalProps> = ({
  open,
  onOk,
  onCancel,
}) => {
  const me = useMe();

  const { validateAddress } = useApiClient();
  const [loading, setLoading] = useState<boolean>(false);
  const { country } = useSettings();

  const { trackUserAction } = useUATracker();
  const { t } = useTranslation();

  const onFinish = async (values: any) => {
    setLoading(true);
    await validateAddress(values);
    setLoading(false);
    trackUserAction(new UserProfileUpdated());
    trackUserAction(new AddressConfirmed());
    onOk && onOk();
  };

  const [form] = Form.useForm();

  if (!loading) {
    form.setFieldsValue({
      firstName: me?.firstName,
      lastName: me?.lastName,
      phone: me?.phone,
      address1: me?.shippingInfo.address?.address1,
      address2: me?.shippingInfo.address?.address2,
      city: me?.shippingInfo.address?.city,
      province: me?.shippingInfo.address?.province,
      zip: me?.shippingInfo.address?.zip,
      state: country || "Italia",
    });
  }

  return (
    <Modal
      title={t("Modifica Profilo")}
      destroyOnClose
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      className={styles.detailsModal}
      forceRender={true}
      closeIcon={
        <img
          className={styles.closeIcon}
          src="/icons/close-modal2.svg"
          alt="close"
        />
      }
      footer={
        loading ? (
          <Row align="middle" justify="center" className={styles.modalEditBar}>
            <Spin
              style={{
                color: "#000000",
                width: "100vw",
                height: "20vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </Row>
        ) : (
          <Row align="middle" justify="center" className={styles.modalEditBar}>
            <Col span={24}>
              <Button
                onClick={form.submit}
                className={styles.button + " " + styles.primary}
              >
                {t("Salva")}
              </Button>
            </Col>
          </Row>
        )
      }
    >
      <Form
        disabled={loading}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Col className={styles.infoContainer}>
          <Form.Item name="firstName" label={t("Nome")} required>
            <Input placeholder={t("Nome")} />
          </Form.Item>
          <Form.Item name="lastName" label={t("Cognome")} required>
            <Input placeholder={t("Cognome")} />
          </Form.Item>
        </Col>
        <Col className={styles.infoContainer}>
          <Form.Item name="phone" label={t("Telefono")} required>
            <Input placeholder={t("Tel. 333 3333333")} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="address1" label={t("Indirizzo")} required>
            <Input placeholder={t("Es. Via Rossi, 1")} />
          </Form.Item>
        </Col>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="city" label={t("Città")} required>
              <Input placeholder={t("Città")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="province" label={t("Provincia")}>
              <Input placeholder={t("Provincia")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="zip" label={t("CAP")}>
              <Input placeholder={t("CAP")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="state" label={t("Stato")}>
              <Input placeholder={t("Stato")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddressModal;
