import { ActionInfo, UserAction } from "../event";

interface ProductDetailsActionInfos extends ActionInfo {
  productName: string;
}

export class ProductDetailsOpened implements UserAction {
  type: string = "product_details_opened";
  actionInfos: ProductDetailsActionInfos;

  constructor(actionInfo: ProductDetailsActionInfos) {
    this.actionInfos = actionInfo;
  }
}

export class ProductDetailsClosed implements UserAction {
  type: string = "product_details_closed";
  actionInfos: ProductDetailsActionInfos;

  constructor(actionInfo: ProductDetailsActionInfos) {
    this.actionInfos = actionInfo;
  }
}

export class OrderProductAdded implements UserAction {
  type: string = "order_product_added";
  actionInfos: ProductDetailsActionInfos;
  metaCustomEventName: string = "AddToCart";

  constructor(actionInfo: ProductDetailsActionInfos) {
    this.actionInfos = actionInfo;
  }
}

export class OrderProductRemoved implements UserAction {
  type: string = "order_product_removed";
  actionInfos: ProductDetailsActionInfos;

  constructor(actionInfo: ProductDetailsActionInfos) {
    this.actionInfos = actionInfo;
  }
}

interface DishRatingSubmittedInfo extends ActionInfo {
  productSku: string;
  dishRating: number;
  reviewQuestion: string;
}

export class DishRatingSubmitted implements UserAction {
  type: string = "dish_rating_submitted";
  actionInfos: DishRatingSubmittedInfo;

  constructor(actionInfo: DishRatingSubmittedInfo) {
    this.actionInfos = actionInfo;
  }
}

interface DishReviewSkippedInfo extends ActionInfo {
  productSku: string;
  reviewQuestion: string;
}

export class DishReviewSkipped implements UserAction {
  type: string = "dish_review_skipped";
  actionInfos: DishReviewSkippedInfo;

  constructor(actionInfo: DishReviewSkippedInfo) {
    this.actionInfos = actionInfo;
  }
}

export class DishReviewClosed implements UserAction {
  type: string = "dish_review_closed";
  actionInfos = {};
}

export type { ProductDetailsActionInfos, DishRatingSubmittedInfo };
