import { createContext, useContext } from "react";

interface LoginContextProps {
  email: string;
  logout: () => void;
  attemptRelogin: () => void;
}

const LoginContext = createContext<LoginContextProps | null>(null);

const useLogin = () => {
  const context = useContext(LoginContext);
  if (context === null) {
    throw new Error("useLogin must be used within a LoginContextProvider");
  }
  return { ...context };
};

export default LoginContext;
export { useLogin };
export type { LoginContextProps };
