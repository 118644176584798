import styles from "./styles.module.css";
import Logo from "../Home/HomeComponents/Logo";
import { useLocation, useNavigate } from "react-router-dom";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

interface ShopHeaderStepsState {
  index: number;
  active: boolean;
}

interface ShopHeaderStepsProps {
  steps: ShopHeaderStepsState[];
  headerButton: JSX.Element;
  showHeaderButton?: boolean;
}

const ShopHeaderSteps = ({
  steps,
  headerButton,
  showHeaderButton,
}: ShopHeaderStepsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { lg } = useBreakpoint(true);

  const goBack = (desiredState: ShopHeaderStepsState) => {
    if (!desiredState.active) {
      if (desiredState.index === 1) navigate("/shop/choose-subscription");
      if (
        (location.pathname === "/shop/shipping/select-date" ||
          location.pathname === "/shop/shipping") &&
        desiredState.index === 2
      )
        navigate("/shop/edit");
      if (
        location.pathname === "/shop/shipping/select-date" &&
        desiredState.index === 3
      )
        navigate("/shop/shipping");
    }
  };

  return (
    <div style={{ margin: 16, marginBottom: -16, height: 90 }}>
      {showHeaderButton ? headerButton : <Logo small />}

      <div
        className={styles.headerSteps}
        style={
          showHeaderButton
            ? { top: 16, marginRight: 42 }
            : !showHeaderButton && lg
              ? { top: 16 }
              : { top: -45 }
        }
      >
        {steps.map((step) => {
          return (
            <img
              key={`step-${step.index}`}
              alt={"step " + step.index}
              src={
                "/icons/HeaderSteps/" +
                step.index +
                (step.active ? "-selected" : "") +
                ".svg"
              }
              onClick={() => goBack(step)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ShopHeaderSteps;
