import MembershipModal from "./MembershipModal";
import MembershipModalSimplified from "./MembershipModalSimplified";
import { useNavigate } from "react-router-dom";
import useCart from "../Hooks/useCart";
import { useDiscount } from "../Hooks/useDiscount";
import {
  MembershipPaywallCTASelected,
  MembershipPaywallDiscardedCTASelected,
} from "../Networking/Metrics/UserActions/Shop";
import useUATracker from "../Networking/Metrics/useUATracker";
import { useSettings } from "../Networking/useSettings";
import FreemiumModal from "./FreemiumModal";
import FreemiumModalNew from "./FreemiumModalNew";

const Paywall = () => {
  const navigate = useNavigate();
  const { setMembership, currentTotal, currentDisplayedBox } = useCart();
  const { membership } = useSettings();
  const { trackUserAction } = useUATracker();

  const { freemiumMode, improvedShippingFlow, improvedShop } = useSettings();

  const goBack = () => {
    navigate(-1);
  };
  const continueWithMembership = () => {
    void trackUserAction(new MembershipPaywallCTASelected());
    setMembership(membership?.sku, false);
    navigate("/shop/shipping");
  };

  const continueWithoutMembership = () => {
    void trackUserAction(new MembershipPaywallDiscardedCTASelected());
    setMembership(undefined, false);
    navigate("/shop/shipping");
  };

  const discount = useDiscount();

  const modal = freemiumMode ? (
    improvedShop ? (
      <FreemiumModalNew
        currentBox={currentDisplayedBox}
        discount={discount?.amount}
        total={currentTotal}
        open={true}
        onCancel={goBack}
        continueWithMembership={continueWithMembership}
        continueWithoutMembership={continueWithoutMembership}
      />
    ) : (
      <FreemiumModal
        currentBox={currentDisplayedBox}
        discount={discount?.amount}
        total={currentTotal}
        open={true}
        onCancel={goBack}
        continueWithMembership={continueWithMembership}
        continueWithoutMembership={continueWithoutMembership}
      />
    )
  ) : improvedShippingFlow ? (
    <MembershipModalSimplified
      open={true}
      onCancel={goBack}
      onConfirm={continueWithMembership}
    />
  ) : (
    <MembershipModal
      currentBox={currentDisplayedBox}
      currentTotal={currentTotal}
      discount={discount?.amount}
      open={true}
      onCancel={goBack}
      onConfirm={continueWithMembership}
    />
  );

  return modal;
};

export default Paywall;
