import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

export const useOpenInNewTab = () => {
  const navigate = useNavigate();
  return useCallback(
    (url: string) => {
      window.open(url, "_blank")?.focus();
      setTimeout(() => {
        navigate("/");
      }, 500);
    },
    [navigate],
  );
};
