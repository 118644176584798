import styles from "../styles.module.css";
import { Button } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { clearCart } from "./common";
import useUATracker from "../../Networking/Metrics/useUATracker";
import { NewOrderCTASelected } from "../../Networking/Metrics/UserActions/Shop";
import { SettingsContext } from "../../Networking/useSettings";
import { ContactExpertCTASelected } from "../../Networking/Metrics/UserActions/common";

const NewOrderCTA = () => {
  const navigate = useNavigate();

  const { trackUserAction } = useUATracker();

  const newOrder = () => {
    trackUserAction(new NewOrderCTASelected());
    clearCart();
    navigate("/shop/edit");
  };
  return (
    <Button onClick={newOrder} className={styles.button + " " + styles.yellow}>
      Crea Nuovo Ordine
    </Button>
  );
};

const NewOrderBanner = () => {
  const settings = useContext(SettingsContext);
  if (!settings) {
    throw new Error(
      "Component cannot be used without a valid settings context",
    );
  }

  const { trackUserAction, sendRecentEvents } = useUATracker();

  return (
    <div className={styles.banner} style={{ padding: 24 }}>
      <img
        className={styles.imageOrder}
        src={"/images/nextOrder.png"}
        alt={"next-order"}
        style={{ margin: "-24px -24px 16px -24px" }}
      />
      <h1 className={styles.title} style={{ marginLeft: -24 }}>
        Pronto per gustare il prossimo piatto?
      </h1>
      <NewOrderCTA />
      {settings.enableExpertCta && (
        <Button
          className={styles.button + " " + styles.link}
          style={{ marginTop: 8 }}
          onClick={() => {
            trackUserAction(new ContactExpertCTASelected());
            void sendRecentEvents();
            window.open(settings.supportUrl);
          }}
        >
          Contatta la tua Expert
        </Button>
      )}
    </div>
  );
};

export default NewOrderBanner;
export { NewOrderCTA };
