import styles from "../styles.module.css";
import React from "react";
import { Link } from "react-router-dom";

interface LogoProps {
  small?: boolean;
  desktop?: boolean;
}

const Logo = ({ small, desktop }: LogoProps) => {
  return (
    <Link to={"/"} title="Homepage">
      <img
        className={
          desktop
            ? styles.logo + " " + styles.logoDesktop
            : small
              ? styles.logo + " " + styles.logoMobile
              : styles.logo
        }
        src={"/logo.png"}
        alt={"logo"}
      />
    </Link>
  );
};

export default Logo;
