import React from "react";

import { Col, Rate, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";

const DotIcon = ({ number }: { number: number }) => (
  <div style={{ display: "flex", justifyContent: "center", flexGrow: 1 }}>
    <svg height="40" width="40" viewBox="0 0 40 40">
      <circle
        cx="20"
        cy="20"
        r="18"
        stroke="black"
        strokeWidth="2"
        fill="transparent"
      />
      <text
        x="50%"
        y="50%"
        alignmentBaseline="central"
        textAnchor="middle"
        fontSize="16"
        fontFamily="Arial"
      >
        {number}
      </text>
    </svg>
  </div>
);

const customIcons: Record<number, React.ReactNode> = {
  1: <DotIcon number={1} />,
  2: <DotIcon number={2} />,
  3: <DotIcon number={3} />,
  4: <DotIcon number={4} />,
};

const ScaleInput = ({ form }: { form: FormInstance }) => {
  const { t } = useTranslation();
  return (
    <Col className={styles.rateCol}>
      <Row justify="center" style={{ width: "100%" }}>
        <Rate
          character={({ index = 0 }) => customIcons[index + 1]}
          onChange={(value) => {
            form.setFieldsValue({ rating: value });
            form.submit();
          }}
          count={4}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        />
      </Row>
      <Row justify="space-between" style={{ marginTop: 8 }}>
        <Col className={styles.smallLabel}>{t("Per niente d'accordo")}</Col>
        <Col className={styles.smallLabel} style={{ textAlign: "right" }}>
          {t("Totalmente d'accordo")}
        </Col>
      </Row>
    </Col>
  );
};

export default ScaleInput;
