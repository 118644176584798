import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import Menu from "./Layout/Menu";
import React, { useEffect } from "react";
import useDeviceId from "./common/useDevice";
import RegistrationModal from "./RegistrationModal/RegistrationModal";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useServerProducts } from "./Networking/useProducts";
import { useEndpoint } from "./Networking/common";
import dayjs from "dayjs";
import { LineItem } from "./Cart/LineItem";
import PastOrdersContext, {
  OrderStatus,
  OrderType,
  RawOrderType,
} from "./Networking/PastOrdersContext";

const { Content } = Layout;

export const Main = () => {
  const { deviceId } = useDeviceId();
  const { lg } = useBreakpoint(true);

  useEffect(() => {
    document
      .getElementById("ot-sdk-btn-floating")
      ?.style.setProperty("display", "none", "important");
  }, []);
  if (!deviceId) {
    throw new Error("Invalid device id context");
  }

  const { productsMap } = useServerProducts();
  const { data: orders, isLoading } = useEndpoint<RawOrderType[]>("/orders");
  let mappedOrders: OrderType[] | undefined = undefined;
  const today = dayjs();
  const orderStatus = (rawOrder: RawOrderType): OrderStatus => {
    if (rawOrder.cancelledAt) {
      return OrderStatus.CANCELLED;
    } else if (
      rawOrder.fulfillmentStatus === "fulfilled" &&
      (dayjs(rawOrder.expectedShippingDate) < today ||
        !rawOrder.expectedShippingDate)
    ) {
      return OrderStatus.DELIVERED;
    } else if (rawOrder.fulfillmentStatus === "fulfilled") {
      return OrderStatus.SHIPPED;
    } else {
      return OrderStatus.PROCESSING;
    }
  };
  if (productsMap && orders) {
    mappedOrders = orders?.map((order) => {
      return {
        ...order,
        status: orderStatus(order),
        lineItems: order.lineItems
          .map((li) => {
            const product = productsMap[li.variantId];
            if (!product) {
              return null;
            }
            return {
              product: product,
              quantity: li.quantity,
            };
          })
          .filter((li) => li !== null) as LineItem[],
      };
    });
  }

  return (
    <>
      <PastOrdersContext.Provider
        value={{
          orders: mappedOrders,
          loading: isLoading,
        }}
      >
        <Layout
          hasSider={lg ? false : true}
          style={{ width: "100vw", overflowX: "clip" }}
        >
          <Menu />
          <Layout>
            <Content>
              <Outlet />
            </Content>
            <RegistrationModal />
          </Layout>
        </Layout>
      </PastOrdersContext.Provider>
    </>
  );
};
