import { useMe } from "../AppSetup/MeContext";
import { useEffect, useState } from "react";

const useRequestContactConsent = () => {
  const { shouldCompleteRegistration, shouldRequestContactConsent } = useMe();
  const [_shouldRequestContactConsent, setShouldRequestContactConsent] =
    useState(false);

  useEffect(() => {
    if (!shouldRequestContactConsent) {
      setShouldRequestContactConsent(false);
    }
    if (!shouldCompleteRegistration && shouldRequestContactConsent) {
      setTimeout(() => {
        setShouldRequestContactConsent(true);
      }, 5000);
    }
  }, [shouldCompleteRegistration, shouldRequestContactConsent]);

  return _shouldRequestContactConsent;
};

export default useRequestContactConsent;
