import React, { ReactElement, useEffect, useRef } from "react";
import { useBoxConfig } from "../Hooks/useBoxConfig";
import { Col, Divider, Progress, Row, Space } from "antd";
import styles from "./styles.module.css";
import { useLocation } from "react-router-dom";
import ListItem from "./ListItem";
import useUATracker from "../Networking/Metrics/useUATracker";
import {
  ChangeSubscriptionProfileSelected,
  OrderCartClosed,
  OrderCartOpened,
} from "../Networking/Metrics/UserActions/Shop";
import { useSettings } from "../Networking/useSettings";
import MembershipListItem from "./MembershipListItem";
import PriceRecap from "./PriceRecap";
import { useMe } from "../AppSetup/MeContext";
import useCart from "../Hooks/useCart";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

interface BoxProgressProps {
  current: number;
  steps: number[];
  prices: string[];
  titles: string[];
}

const BoxProgress = ({ current, steps, prices, titles }: BoxProgressProps) => {
  const { hideRecapPrices } = useSettings();
  const stepElements: ReactElement[] = [];
  for (let i = 0; i < steps.length; i++) {
    const prevBoxSize = i > 0 ? steps[i - 1] : 0;
    const currentBoxFill = current - prevBoxSize;
    const currentBoxDiff = steps[i] - prevBoxSize;
    const percentage =
      currentBoxFill <= 0
        ? 0
        : Math.min((currentBoxFill / currentBoxDiff) * 100, 100);

    const active = current <= steps[i] && current > prevBoxSize;
    stepElements.push(
      <Space
        key={`space-${i}`}
        style={{ flexGrow: 1 }}
        direction={"vertical"}
        size={0}
      >
        <div
          style={{ height: 10 }}
          className={
            styles.priceLabel + " " + (active ? styles.priceLabelActive : "")
          }
        >
          {titles[i]}
        </div>
        <Progress
          percent={percentage}
          strokeLinecap={"square"}
          showInfo={false}
          // strokeWidth={4}
          size={["small", 4]}
          style={{ marginBottom: 0 }}
        />
        {!hideRecapPrices ? (
          <div
            style={{ height: 20 }}
            className={
              styles.priceLabel + " " + (active ? styles.priceLabelActive : "")
            }
          >
            {prices[i]}
          </div>
        ) : null}
      </Space>,
    );
    if (i < steps.length - 1) {
      stepElements.push(
        <Divider
          style={{
            height: 25,
            marginTop: 10,
          }}
          key={`divider-${i}`}
          orientation={"center"}
          type="vertical"
        />,
      );
    }
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
      className={styles.boxProgress}
    >
      {" "}
      {stepElements}{" "}
    </div>
  );
};

interface CartRecapBottomBarProps {
  open: boolean;
  editable: boolean;
  setOpened: (opened: boolean) => void;
  hideCart?: boolean;
  hideProgress?: boolean;
}

export const CartRecapBottomBar = ({
  open,
  editable,
  setOpened,
  hideCart,
  hideProgress,
  children,
}: React.PropsWithChildren<CartRecapBottomBarProps>) => {
  const cart = useCart();

  const { membership, defaultSubscriptionPeriodInWeeks } = useSettings();

  const listContainer = useRef();

  const boxConfig = useBoxConfig();
  const location = useLocation();
  const navigate = useNavigate();
  const { lg } = useBreakpoint(true);

  const { trackUserAction } = useUATracker();

  const { t } = useTranslation();

  const toggleCartExpanded = () => {
    trackUserAction(open ? new OrderCartClosed() : new OrderCartOpened());
    setOpened(!open);
  };

  useEffect(() => {
    // On mobile, when location changes, set the bar to not be expanded
    !lg && setOpened(false);
  }, [location, setOpened, lg]);

  const edit = location.pathname === "/shop/edit";
  const { freemiumMode, subsEnabled, unlimitedEnabled } = useSettings();

  const { isMember } = useMe();

  const { totalItems } = cart;

  let box = boxConfig.find((el) => totalItems <= el.items);
  if (!box) {
    box = boxConfig[0];
  }

  const useNonMembershipPrice =
    freemiumMode && !isMember && !cart?.membershipInCart;

  let comparedPrice = box.comparedPrice;
  if (freemiumMode && !isMember && cart?.membershipInCart) {
    comparedPrice = box.nonMembershipPrice;
  }

  const canEditSubscription =
    subsEnabled &&
    (cart.editingSubscription || !defaultSubscriptionPeriodInWeeks);
  const editSubscription = () => {
    if (!canEditSubscription) {
      return;
    }
    trackUserAction(new ChangeSubscriptionProfileSelected());
    navigate("/shop/choose-subscription");
  };

  const productsQtyRecap = (
    <div style={{ display: "flex", alignItems: "center" }}>
      {(hideProgress || lg) && (
        <>
          <div>{box.name}</div>
          <Divider type="vertical" className={styles.smallDivider} />
        </>
      )}
      <div style={{ fontWeight: "500" }}>
        {totalItems} / {box.items} {(hideProgress || lg) && t("piatti")}
      </div>
      <Divider type="vertical" className={styles.smallDivider} />

      <div style={{ display: "flex", alignItems: "center" }}>
        {comparedPrice && !subsEnabled && (
          <>
            <span
              className={styles.priceLabel}
              style={{
                fontSize: 15,
                textDecoration: "line-through",
                opacity: 0.5,
              }}
            >
              {comparedPrice} &euro;
            </span>
            &nbsp;&nbsp;
          </>
        )}
        {!hideProgress && (
          <>
            <span>
              {cart.instalments
                ? `${cart.instalments.immediatePriceInCents / 100} € ora, ${
                    cart.instalments.repeatedPriceInCents / 100
                  } € tra ${cart.instalments.period.count} ${
                    cart.instalments.period.unit
                  }`
                : `${cart.currentBoxPrice} €`}
              {!subsEnabled &&
                freemiumMode &&
                !isMember &&
                cart?.membershipInCart && (
                  <img
                    alt={"premium"}
                    src={"/icons/premium.svg"}
                    className={styles.premiumBadge}
                  />
                )}
            </span>
          </>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div
        className={
          styles.cartRecapBottomBar + " " + (open && lg && styles.sidebarOpen)
        }
        id="cartContainer"
      >
        {!hideCart ? (
          <Row onClick={toggleCartExpanded}>
            <Col span={0} lg={6} xl={8} />
            <Col
              span={24}
              lg={12}
              xl={8}
              className={
                styles.cartRecapBottomBarRow + " " + styles.recapHeader
              }
            >
              {productsQtyRecap}

              <div style={{ alignSelf: "flex-end" }}>
                <div
                  className={styles.iconContainer}
                  style={!open ? { padding: 5 } : { padding: 12 }}
                >
                  <img
                    src="/icons/cart_icon.svg"
                    alt="open cart"
                    style={
                      !open
                        ? { transform: "scale(1)" }
                        : { transform: "scale(0)" }
                    }
                  />
                  <img
                    src="/icons/down_small.svg"
                    alt="close cart"
                    style={
                      open
                        ? { transform: "scale(1)" }
                        : { transform: "scale(0)" }
                    }
                  />
                </div>
                <div
                  className={styles.cartBadge}
                  style={
                    !open
                      ? { transform: "scale(1)" }
                      : { transform: "scale(0)" }
                  }
                >
                  {totalItems}
                </div>
              </div>
            </Col>
          </Row>
        ) : null}

        <Row
          className={styles.cartRecapBottomBarRow + " " + styles.collapsable}
          ref={listContainer.current}
          style={{
            maxHeight:
              open && !hideCart && !hideProgress
                ? "55dvh"
                : open && !hideCart && hideProgress
                  ? "65dvh"
                  : 0,
            paddingTop: open && !hideCart ? 16 : 0,
            paddingBottom: 0,
            paddingLeft: 16,
            paddingRight: 16,
            marginBottom: 0,
          }}
        >
          <Col span={24} lg={12} xl={8}>
            {canEditSubscription ? (
              <div className={styles.boxSizeBtn} onClick={editSubscription}>
                <img
                  alt={"edit"}
                  src="/icons/next_small_grey.svg"
                  style={{ transform: "rotate(180deg)", marginTop: 2 }}
                />
                {t("Cambia dimensione Box")}
              </div>
            ) : null}

            {cart.lineItems.length > 0 ? (
              <>
                {freemiumMode &&
                  !subsEnabled &&
                  membership &&
                  !isMember &&
                  (editable || !!cart.membershipInCart) && (
                    <MembershipListItem
                      switchValue={!!cart.membershipInCart}
                      onToggle={(value) =>
                        cart?.setMembership(
                          value ? membership.sku : undefined,
                          true,
                        )
                      }
                      editable={editable}
                    />
                  )}
                {cart.lineItems.map((item, index) => (
                  <ListItem
                    item={item}
                    editable={editable}
                    key={item.product.sku}
                    last={index === cart?.lineItems.length - 1}
                  />
                ))}
              </>
            ) : (
              <p className={styles.cartEmptyState}>
                Non hai piatti nel carrello.
              </p>
            )}
          </Col>
        </Row>

        {edit && !hideProgress && !lg ? (
          <Row
            className={styles.cartRecapBottomBarRow}
            style={
              totalItems === 0
                ? { maxHeight: 0, paddingTop: 0 }
                : { maxHeight: 68, paddingTop: 16 }
            }
          >
            <Col span={24} lg={12} xl={8}>
              <BoxProgress
                current={totalItems}
                titles={boxConfig.map((b) => b.name)}
                steps={boxConfig.map((b) => b.items)}
                prices={boxConfig.map(
                  (b) =>
                    `${(
                      parseFloat(
                        useNonMembershipPrice
                          ? b.nonMembershipPrice!
                          : b.originalPrice || b.price,
                      ) / b.items
                    ).toFixed(2)}€ / ${t("piatto")}`,
                )}
              />
            </Col>
          </Row>
        ) : null}

        {!subsEnabled && !unlimitedEnabled && !lg && (
          <Row
            className={
              styles.cartRecapBottomBarRecapRow +
              (open ? " " + styles.active : "")
            }
          >
            <Col
              span={24}
              lg={12}
              xl={8}
              style={{ display: "flex", flexDirection: "row", gap: 8 }}
            >
              <PriceRecap />
            </Col>
          </Row>
        )}

        {lg && editable && (
          <Row>
            <Col span={0} lg={6} xl={8} />
            <Col
              span={24}
              lg={12}
              xl={8}
              className={
                styles.cartRecapBottomBarRow + " " + styles.recapHeader
              }
            >
              {productsQtyRecap}
            </Col>
          </Row>
        )}

        <Row
          className={styles.cartRecapBottomBarRow}
          style={{ paddingBottom: 16 }}
        >
          <Col
            span={24}
            lg={12}
            xl={8}
            style={{ display: "flex", flexDirection: "row", gap: 8 }}
          >
            {children}
          </Col>
        </Row>
      </div>
      <div
        className={
          open && !lg ? styles.overlayExpanded : styles.overlayCollapsed
        }
        onClick={toggleCartExpanded}
      />
    </>
  );
};
