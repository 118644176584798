import styles from "./styles.module.css";

interface PriceBoxProps {
  price: string;
  highlight: boolean;
  strikethrough: boolean;
  premium: boolean;
}

const PriceBox = ({
  price,
  strikethrough,
  highlight,
  premium,
}: PriceBoxProps) => {
  return (
    <div
      className={
        styles.price +
        (strikethrough ? " " + styles.strikedPrice : "") +
        (highlight ? " " + styles.highlightedPrice : "")
      }
    >
      <span>{price} €</span>
      {premium && <span className={styles.premiumIcon} />}
    </div>
  );
};

export default PriceBox;
