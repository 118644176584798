import { useSettings } from "../Networking/useSettings";
import { useBoxConfig } from "./useBoxConfig";

const usePurchaseEnabled = () => {
  const settings = useSettings();

  const boxConfig = useBoxConfig();

  if (!settings) {
    throw new Error("Invalid Settings");
  }

  return boxConfig.length > 0;
};
export default usePurchaseEnabled;
