import { Button, Col, Row, Space } from "antd";
import TastingBoxBanner from "../../Home/HomeComponents/TastingBoxBanner";
import styles from "../../Home/styles.module.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../Home/HomeComponents/Logo";
import useUATracker from "../../Networking/Metrics/useUATracker";
import { TastingBoxSkipped } from "../../Networking/Metrics/UserActions/Shop";

export const TastingBox = () => {
  const navigate = useNavigate();
  const { trackUserAction } = useUATracker();

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <Row style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
        <Logo small />
        <div className={styles.headerTitle}>
          <h1>Benvenuto</h1>
        </div>
      </Row>
      <Row justify="center">
        <Col span={8} xs={24} md={12} xl={8} style={{ padding: 16 }}>
          <Row>
            <TastingBoxBanner />
          </Row>
          <Row justify="center" style={{ marginTop: 24, marginBottom: 16 }}>
            Vuoi scegliere tu i piatti?
          </Row>
          <Row>
            <Button
              className={styles.button}
              onClick={() => {
                trackUserAction(new TastingBoxSkipped());
                navigate("/shop/edit");
              }}
            >
              Crea la tua Box
              <img
                alt={"next"}
                src={`/icons/next_small_grey.svg`}
                style={{ marginBottom: -3, marginLeft: 4 }}
              />
            </Button>
          </Row>
        </Col>
      </Row>
    </Space>
  );
};
