import React, { useEffect, useMemo, useState } from "react";
import { Layout, Menu } from "antd";
import {
  CustomerServiceOutlined,
  FileProtectOutlined,
  HomeOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import useUATracker from "../Networking/Metrics/useUATracker";
import {
  WebsiteMenuOptionSelected,
  WebsiteMenuSelected,
} from "../Networking/Metrics/UserActions/common";
import SecretMenu from "../SecretMenu/SecretMenu";
import { useSettings } from "../Networking/useSettings";
import { useMe } from "../AppSetup/MeContext";
import usePurchaseEnabled from "../Hooks/usePurchaseEnabled";
import { useTranslation } from "react-i18next";
import Logo from "../Home/HomeComponents/Logo";
import WebsiteBanners from "./WebsiteBanners";

const { Sider, Header } = Layout;

interface MenuItem {
  key: string;
  label: string;
  icon: JSX.Element;
  path?: string;
  display?: boolean;
}

const _Menu = () => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const me = useMe();

  const purchaseEnabled = usePurchaseEnabled();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { trackUserAction } = useUATracker();

  const {
    enableTastingBox,
    faq,
    subsEnabled,
    defaultSubscriptionPeriodInWeeks,
    unlimitedEnabled,
  } = useSettings();

  const { lg } = useBreakpoint(true);

  const items: MenuItem[] = useMemo(
    () => [
      {
        key: "/",
        label: enableTastingBox ? t("Box Degustazione") : t("Home"),
        icon: <HomeOutlined className={styles.menuItemIcon} />,
      },
      {
        key: "/products",
        label: t("Il nostro menu"),
        icon: <ShopOutlined className={styles.menuItemIcon} />,
        display: purchaseEnabled && !defaultSubscriptionPeriodInWeeks,
      },
      {
        key: "/shop/edit",
        label: unlimitedEnabled ? t("Ordina") : t("Crea la tua Box"),
        icon: <ShoppingCartOutlined className={styles.menuItemIcon} />,
        display: !subsEnabled && purchaseEnabled,
      },
      {
        key: "/profile",
        label: t("Profilo"),
        icon: <UserOutlined className={styles.menuItemIcon} />,
      },
      {
        key: "/support",
        label: t("Assistenza"),
        icon: <CustomerServiceOutlined className={styles.menuItemIcon} />,
      },
      {
        key: "/faq",
        label: t("F.A.Q."),
        icon: <QuestionCircleOutlined className={styles.menuItemIcon} />,
        display: faq.length > 0 && !subsEnabled,
      },
      {
        key: "/privacy-policy",
        label: t("Privacy Policy"),
        icon: <FileProtectOutlined className={styles.menuItemIcon} />,
      },
      {
        key: "/termini-uso",
        label: t("Termini d'Uso"),
        icon: <FileTextOutlined className={styles.menuItemIcon} />,
      },
    ],
    [
      enableTastingBox,
      faq,
      purchaseEnabled,
      subsEnabled,
      t,
      defaultSubscriptionPeriodInWeeks,
      unlimitedEnabled,
    ],
  );

  const onCollapse = (collapsed: boolean, type: string) => {
    setCollapsed(collapsed);
    if (!collapsed && type === "clickTrigger") {
      trackUserAction(new WebsiteMenuSelected());
    }
  };

  useEffect(() => {
    const selectedKey = items.find(
      (_item) => location.pathname === _item.key,
    )?.key;
    if (selectedKey) {
      setSelectedKeys([selectedKey]);
    } else {
      setSelectedKeys(["/"]);
    }
    document.body.style.overflow = !collapsed && !lg ? "hidden" : "unset";
  }, [location.pathname, collapsed, lg, items]);

  const filteredItems = items.filter(
    (item) => item.display === undefined || !me || item.display,
  );

  return (
    <>
      {lg ? (
        <>
          <WebsiteBanners />
          <Header className={styles.menuDesktop}>
            <Logo desktop />
            <Menu
              mode="horizontal"
              selectedKeys={selectedKeys}
              onClick={(item) => {
                trackUserAction(
                  new WebsiteMenuOptionSelected({
                    websiteMenuOptionName: item.key,
                  }),
                );
                navigate(item.key);
              }}
              items={filteredItems}
            />
            {me?.isInternal && (
              <div style={{ position: "absolute", right: -10, top: 50 }}>
                <SecretMenu />
              </div>
            )}
          </Header>
        </>
      ) : (
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          collapsed={collapsed}
          onBreakpoint={setCollapsed}
          onCollapse={onCollapse}
          className={styles.menuWrapper}
          reverseArrow={true}
          theme={"light"}
          width="230"
          trigger={<MenuOutlined />}
          zeroWidthTriggerStyle={
            collapsed
              ? {
                  marginLeft: -14,
                  borderRadius: 0,
                  transition: "all 0.2s,background 0s",
                }
              : {
                  borderRadius: 0,
                  transition: "all 0.2s,background 0s",
                }
          }
        >
          <Menu
            mode={"inline"}
            selectedKeys={selectedKeys}
            className={styles.menu}
            onClick={(item) => {
              trackUserAction(
                new WebsiteMenuOptionSelected({
                  websiteMenuOptionName: item.key,
                }),
              );
              if (!lg) {
                setCollapsed(true);
                // to avoid menu lagging due to the page loading 👇
                setTimeout(() => navigate(item.key), 200);
              } else {
                navigate(item.key);
              }
            }}
            items={filteredItems}
          />
          {me?.isInternal && <SecretMenu />}
          <div
            className={
              !collapsed ? styles.overlayExpanded : styles.overlayCollapsed
            }
            onClick={() => {
              setCollapsed(true);
            }}
          />
        </Sider>
      )}
    </>
  );
};

export default _Menu;
