import { ActionInfo, UserAction } from "../event";

export class WebsiteMenuSelected implements UserAction {
  type: string = "website_menu_selected";
  actionInfos = {};
}

export class ContactExpertCTASelected implements UserAction {
  type: string = "contact_expert_ctas_selected";
  actionInfos = {};
}

interface WebsiteMenuOptions extends ActionInfo {
  websiteMenuOptionName: string;
}

export class WebsiteMenuOptionSelected implements UserAction {
  type: string = "website_menu_option_selected";
  actionInfos: WebsiteMenuOptions;

  constructor(actionInfo: WebsiteMenuOptions) {
    this.actionInfos = actionInfo;
  }
}

export class BackButtonClicked implements UserAction {
  type: string = "back_button_clicked";
  actionInfos = {};
}

interface ErrorDisplayedActionInfos extends ActionInfo {
  errorDescription: string;
}

export class ErrorDisplayed implements UserAction {
  type: string = "error_displayed";
  actionInfos: ErrorDisplayedActionInfos;

  constructor(actionInfo: ErrorDisplayedActionInfos) {
    this.actionInfos = actionInfo;
  }
}

export type { WebsiteMenuOptions };
