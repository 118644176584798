import { Button, Modal, message } from "antd";
import React, { useEffect } from "react";
import useUATracker from "../Networking/Metrics/useUATracker";
import {
  // ContactConsentModalAnswered,
  ContactConsentModalDisplayed,
} from "../Networking/Metrics/UserActions/Login";
import styles from "./styles.module.css";
import useRequestContactConsent from "./useRequestContactConsent";
import useApiClient from "../Networking/useApiClient";
import { Trans, useTranslation } from "react-i18next";

const OfferModal = () => {
  const { trackUserAction } = useUATracker();
  const { completeContactConsent } = useApiClient();
  const shouldRequestContactConsent = useRequestContactConsent();
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();

  useEffect(() => {
    if (shouldRequestContactConsent) {
      trackUserAction(new ContactConsentModalDisplayed());
    }
  }, [shouldRequestContactConsent, trackUserAction]);

  const submitConfirm = (consentGiven: boolean) => {
    if (consentGiven) {
      messageApi.open({
        type: "success",
        content:
          "Grazie! Verrai ricontattato al più presto per ricevere lo sconto.",
        className: styles.message,
        duration: 1.5,
      });
    }
    void completeContactConsent({ consent: consentGiven });
  };

  return (
    <Modal
      title=""
      destroyOnClose
      open={shouldRequestContactConsent}
      bodyStyle={{ height: "100%" }}
      forceRender={true}
      className={styles.alert}
      centered
      footer={
        <>
          {contextHolder}
          <Button
            onClick={() => submitConfirm(true)}
            key="get offer"
            className={styles.button + " " + styles.yellow}
            style={{ marginInlineStart: 0, marginBottom: 16 }}
          >
            {t("Ottieni l'offerta!")}
          </Button>
          <Button
            onClick={() => submitConfirm(false)}
            key="no thanks"
            className={styles.button}
            style={{ marginInlineStart: 0 }}
          >
            {t("No, grazie")}
          </Button>
          <p style={{ textAlign: "center" }}>
            {t(
              "Cliccando su “ottieni l’offerta” acconsenti a essere ricontattato per promozioni e sconti",
            )}
          </p>
        </>
      }
    >
      <img
        src="/images/offer-20.jpg"
        alt="20€ discount on our dishes"
        className={styles.modalImage}
      />
      <h4 className={styles.offerTitle}>{t("Offerta per i nuovi iscritti")}</h4>
      <p className={styles.descriptionBody}>
        <Trans i18nKey={"offerDescription"}>
          Vuoi essere ricontattato per ricevere consigli e uno
          <b> sconto di 20€</b> sulla tua prima Box?
        </Trans>
      </p>
    </Modal>
  );
};

export default OfferModal;
