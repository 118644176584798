import { Button, Modal, Row } from "antd";
import styles from "./styles.module.css";
import { BoxConfig, useSettings } from "../Networking/useSettings";

interface FreemiumModalProps {
  open: boolean;
  total: number;
  continueWithMembership: () => void;
  onCancel: () => void;
  continueWithoutMembership: () => void;
  currentBox: BoxConfig;
  discount?: number;
}

const FreemiumModalNew = ({
  open,
  onCancel,
  continueWithoutMembership,
  continueWithMembership,
  total,
  currentBox,
}: FreemiumModalProps) => {
  const { membership } = useSettings();

  if (!membership) {
    return null;
  }
  const totalWithMembership =
    parseFloat(currentBox.price) +
    (membership.freeTrialPeriod ? 0 : parseFloat(membership.price));
  const totalWithoutMembership = total;
  const saving = totalWithoutMembership - totalWithMembership;

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className={
        styles.fullscreenModal +
        " " +
        styles.white +
        " " +
        styles.freemiumModal +
        " " +
        styles.newFreemium
      }
      style={{ top: 0 }}
      title={`Risparmia su tutti gli ordini`}
      closeIcon={
        <img className={styles.closeIcon} src="/icons/close.svg" alt="close" />
      }
      footer={
        <Row
          align="middle"
          justify="center"
          className={styles.ctaBar}
          style={{ top: "calc(100dvh - 90px)", height: "unset" }}
        >
          <Button
            onClick={continueWithoutMembership}
            className={
              styles.button +
              " " +
              styles.modalBtn +
              " " +
              styles.buttonLeftAligned
            }
            style={{ color: "#444845" }}
          >
            <span>
              Continua senza Membership
              <img
                alt={"next"}
                src={`/icons/next_small_grey.svg`}
                style={{ marginBottom: -3, marginLeft: 4 }}
              />
            </span>
            <span>{total}€</span>
          </Button>
        </Row>
      }
    >
      <div className={styles.bannerModal}>
        <div className={styles.flexContainer}>
          <div className={styles.sectionTitle}>
            Attiva la membership,
            <br />
            risparmi subito
            <br />
            <span className={styles.highlightYellow}>
              {parseFloat(saving.toFixed(2))}€
            </span>
          </div>
        </div>

        <div style={{ marginBottom: 12, textAlign: "center", fontSize: 15 }}>
          {!!membership.freeTrialPeriod ? (
            <>
              <b>
                Gratis per {membership.freeTrialPeriod.count}{" "}
                {membership.freeTrialPeriod.unit}
              </b>
              , poi{" "}
              <b>
                {membership.price}€ / {membership.period.count}{" "}
                {membership.period.unit}
              </b>
            </>
          ) : (
            <>
              &nbsp; <b>{membership.price}€</b> / &nbsp;
              {membership.period.count} {membership.period.unit}
            </>
          )}
        </div>

        <Button
          onClick={continueWithMembership}
          className={styles.button + " " + styles.primaryMembership}
          style={{ width: "100%", height: 60, flexGrow: 0 }}
        >
          Continua con Membership
          <img
            alt={"next"}
            src={`/icons/next_small_white.svg`}
            className={styles.buttonIcon}
          />
        </Button>

        <div style={{ marginTop: 12, textAlign: "center" }}>
          <b>
            Totale: &nbsp;
            <span className={styles.strikedPrice}>{total}</span> &nbsp;
            {totalWithMembership} €
            <span className={styles.premiumIcon} />
          </b>
        </div>
      </div>
    </Modal>
  );
};

export default FreemiumModalNew;
