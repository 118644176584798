import { Button, Col, Modal, Row } from "antd";
import styles from "./styles.module.css";
import { BoxConfig, useSettings } from "../Networking/useSettings";

interface MembershipModalSimplifiedProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  currentBox?: BoxConfig;
  currentTotal?: number;
  discount?: number;
}

const MembershipModalSimplified = ({
  open,
  onCancel,
  onConfirm,
}: MembershipModalSimplifiedProps) => {
  const { membership } = useSettings();

  if (!membership) {
    return null;
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className={
        styles.fullscreenModal +
        " " +
        " " +
        styles.white +
        " " +
        styles.membershipModalSimplified
      }
      style={{ top: 0 }}
      closeIcon={
        <img
          className={styles.closeIcon}
          src="/icons/close-modal2.svg"
          alt="close"
        />
      }
      footer={
        <Row align="middle" justify="center" className={styles.ctaBar}>
          <Col span={24}>
            <Button
              onClick={onConfirm}
              className={
                styles.button + " " + styles.modalBtn + " " + styles.primary
              }
              style={{ fontSize: 18 }}
            >
              Continua
            </Button>
          </Col>
        </Row>
      }
    >
      <div className={styles.yellowSection}>
        <img
          className={styles.modalImage}
          src={"/images/membership-dishes.webp"}
          alt={"delicious dish"}
        />
        <div className={styles.bigTitle}>
          Quasi fatto! <br />
          Prova gratis la membership Humamy.
        </div>
        <div style={{ display: "flex", gap: 24 }}>
          <div className={styles.infoDetail}>
            <img src={"/icons/calendar-3.svg"} alt={"calendar icon"} />
            Gratis per tre settimane
          </div>
          <div className={styles.infoDetail}>
            <img src={"/icons/cancel.svg"} alt={"cancel icon"} />
            Disdici in ogni momento
          </div>
        </div>
      </div>

      <div
        className={styles.whiteSection}
        style={{ marginTop: 24 }}
        id="membership-explanation"
      >
        <div className={styles.sectionTitle} style={{ fontSize: 20 }}>
          Cos’è la membership Humamy?
        </div>
        <div className={styles.sectionBody}>
          <div className={styles.listItem}>
            <img src={"/icons/check_yellow.svg"} alt={"check"} />
            <div>
              La nostra quota di iscrizione:&nbsp;
              {membership.freeTrialPeriod && (
                <>
                  <b>
                    {membership.freeTrialPeriod.count}
                    &nbsp;
                    {membership.freeTrialPeriod.unit}
                    &nbsp; di prova gratis
                  </b>
                  , e poi&nbsp;
                </>
              )}
              <b>
                {membership.price}€ ogni&nbsp;
                {membership.period.count} {membership.period.unit}.
              </b>
            </div>
          </div>
          <div className={styles.listItem}>
            <img src={"/icons/check_yellow.svg"} alt={"check"} />
            <div>
              Sono inclusi nella membership
              <b>&nbsp;assistenza clienti e costi di spedizione.</b>
            </div>
          </div>
          <div className={styles.listItem}>
            <img src={"/icons/check_yellow.svg"} alt={"check"} />
            <div>
              Puoi acquistare quanti piatti vuoi senza vincoli! Non ricevi
              piatti in automatico, ma &nbsp;
              <b>solamente quelli che ordini tu.</b>
            </div>
          </div>
          <div className={styles.listItem}>
            <img src={"/icons/check_yellow.svg"} alt={"check"} />
            <div>
              Puoi <b>disdire gratuitamente</b> in qualsiasi momento dal tuo
              profilo o contattandoci.
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MembershipModalSimplified;
