import { Col, Input, Layout, Row, Space, Tooltip } from "antd";
import ProductComponent from "../../Products/Product";
import ProductVerticalComponent from "../../Products/ProductVertical";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useProducts } from "../../Networking/useProducts";
import { Product } from "../../common/product";
import { useMe } from "../../AppSetup/MeContext";
import { useSettings } from "../../Networking/useSettings";
import MembershipProductBanner from "../../Products/MembershipProductBanner";
import MembershipProductBannerSimple from "../../Products/MembershipProductBannerSimple";
import { SearchOutlined } from "@ant-design/icons";
import ShippingProductBanner from "../../Products/ShippingProductBanner";
import styles from "../styles.module.css";
import { useBoxConfig } from "../../Hooks/useBoxConfig";
import useCart from "../../Hooks/useCart";
import { useTranslation } from "react-i18next";
import { Content, Header } from "antd/es/layout/layout";
import { useProductFilters } from "../../Hooks/useProductFilters";
import useUATracker from "../../Networking/Metrics/useUATracker";
import {
  ShopFilterDeselected,
  ShopFilterSelected,
  ShopSearchTyped,
} from "../../Networking/Metrics/UserActions/Shop";
import filterProducts from "../../Products/Filters/filterProducts";
import FiltersBar from "../../Products/Filters/FiltersBar";

interface RecapProps {
  edit?: boolean;
}

export const Recap = ({ edit }: RecapProps) => {
  const cart = useCart();
  const navigate = useNavigate();
  const { freemiumMode, membership, subsEnabled, improvedShop } = useSettings();

  const { trackUserAction } = useUATracker();

  const { products } = useProducts();
  const location = useLocation();
  const { t } = useTranslation();
  const boxConfig = useBoxConfig();
  const maxCartSize = cart.selectedSub
    ? cart.selectedSub.boxSize
    : Math.max(...boxConfig.map((el) => el.items));

  const [filter, setFilter] = useState<string | undefined>();
  const [shippingClosed, setShippingClosed] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const filteredProducts = filterProducts({
    selectedFilters,
    products,
    filter,
  });

  const filters = useProductFilters(filteredProducts);

  const [fakeLoading, setFakeLoading] = useState<boolean | undefined>();

  const launchFakeLoading = () => {
    setFakeLoading(true);
    setTimeout(() => {
      setFakeLoading(false);
    }, 500);
  };

  const me = useMe();
  const { Search } = Input;

  const { lineItems, discardCart } = cart;

  const { currentDisplayedBox, membershipInCart } = cart;
  const pricePerItem = (
    parseFloat(
      currentDisplayedBox.currentPrice ||
        currentDisplayedBox.originalPrice ||
        currentDisplayedBox.price,
    ) / currentDisplayedBox.items
  )
    .toFixed(2)
    .toString();
  let nonMembershipPricePerItem: string | undefined = undefined;
  if (currentDisplayedBox.nonMembershipPrice) {
    nonMembershipPricePerItem = (
      parseFloat(currentDisplayedBox.nonMembershipPrice) /
      currentDisplayedBox.items
    )
      .toFixed(2)
      .toString();
  }

  useEffect(() => {
    const filterTimeout = setTimeout(() => {
      if (filter) {
        trackUserAction(new ShopSearchTyped({ searchQuery: filter }));
      }
    }, 1000);

    return () => {
      clearTimeout(filterTimeout);
    };
  }, [filter, trackUserAction]);

  const getQuantityForProduct = (product: Product): number => {
    return lineItems.find((li) => li.product.id === product.id)?.quantity || 0;
  };

  useEffect(() => {
    if (
      products &&
      !cart.valid &&
      !me?.order &&
      location.pathname !== "/shop/edit"
    ) {
      discardCart();
      navigate("/");
    }
  }, [discardCart, products, navigate, me, cart, location]);

  const renderProducts = () => {
    if (!edit) {
      return lineItems.map((li) => (
        <ProductComponent
          editable={false}
          membershipInCart={!!membershipInCart}
          pricePerItem={pricePerItem}
          nonMembershipPricePerItem={nonMembershipPricePerItem}
          maxCartSize={maxCartSize}
          key={li.product.id}
          product={li.product}
          inCart={li.quantity}
        />
      ));
    }
    const productsList = filteredProducts.map((product) =>
      improvedShop ? (
        <ProductVerticalComponent
          editable={true}
          membershipInCart={!!membershipInCart}
          pricePerItem={pricePerItem}
          nonMembershipPricePerItem={nonMembershipPricePerItem}
          key={product.id}
          product={product}
          maxCartSize={maxCartSize}
          onAdd={() => {
            cart?.addProduct(product);
          }}
          onRemove={() => {
            cart?.removeProduct(product);
          }}
          inCart={getQuantityForProduct(product)}
        />
      ) : (
        <ProductComponent
          editable={true}
          membershipInCart={!!membershipInCart}
          pricePerItem={pricePerItem}
          nonMembershipPricePerItem={nonMembershipPricePerItem}
          key={product.id}
          product={product}
          maxCartSize={maxCartSize}
          onAdd={() => {
            cart?.addProduct(product);
          }}
          onRemove={() => {
            cart?.removeProduct(product);
          }}
          inCart={getQuantityForProduct(product)}
        />
      ),
    );

    const membershipBanner = !membership ? null : improvedShop ? (
      <MembershipProductBannerSimple
        active={!!membershipInCart}
        onToggle={(value) => {
          cart?.setMembership(value ? membership!.sku : undefined, true);
        }}
      />
    ) : (
      <MembershipProductBanner
        active={!!membershipInCart}
        onToggle={(value) => {
          cart?.setMembership(value ? membership!.sku : undefined, true);
        }}
      />
    );

    if (freemiumMode && !subsEnabled && selectedFilters.length === 0) {
      productsList.splice(
        0,
        0,
        <div
          key={"membership"}
          style={filter ? { maxHeight: 0 } : { maxHeight: 400 }}
          className={styles.bannerRow + " " + (improvedShop && styles.simple)}
        >
          {membershipBanner}
        </div>,
      );
    }
    return productsList;
  };

  return (
    <Layout style={{ padding: 0 }}>
      {(subsEnabled || improvedShop) && (
        <Header
          style={{
            width: "100%",
            padding: 0,
            zIndex: 1,
            height: 55,
            marginTop: -24,
            backgroundColor: "transparent",
          }}
        >
          <div className={styles.titleCentered} id="title">
            {t("Scegli i piatti")}
          </div>
        </Header>
      )}
      <Header className={improvedShop ? styles.filtersBarWrapper : ""}>
        <Row justify="center">
          <Col
            span={8}
            xs={24}
            md={20}
            xl={20}
            className={styles.productsSearch}
          >
            {improvedShop ? (
              <FiltersBar
                setFilter={setFilter}
                filters={filters}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                onAddedFilter={(f: string) => {
                  trackUserAction(new ShopFilterSelected({ filterName: f }));
                }}
                onRemovedFilter={(f: string) => {
                  trackUserAction(new ShopFilterDeselected({ filterName: f }));
                }}
              />
            ) : (
              <Search
                bordered={false}
                allowClear
                loading={fakeLoading}
                style={{
                  padding: "0 16px",
                  backgroundColor: "white !important",
                }}
                prefix={<SearchOutlined style={{ marginRight: 8 }} />}
                placeholder={t("SearchPlaceholder")}
                onChange={(e) => {
                  launchFakeLoading();
                  setFilter(e.target.value);
                }}
              />
            )}
          </Col>
        </Row>
      </Header>
      <Content
        style={{ marginTop: improvedShop ? -8 : 0 }}
        className={styles.productsSpace}
      >
        <Space direction={"vertical"} size={0} style={{ width: "100%" }}>
          <Row
            justify="center"
            className={styles.bannerRow}
            style={filter ? { maxHeight: 0 } : { maxHeight: 400 }}
          >
            {!shippingClosed && !subsEnabled && !improvedShop && (
              <Col span={8} xs={24} md={12} xl={8}>
                <ShippingProductBanner
                  dismissShippingBanner={() => setShippingClosed(true)}
                />
              </Col>
            )}
          </Row>
          <Row justify="center">
            <Col span={8} xs={24} md={20} xl={20}>
              {improvedShop ? (
                <Row
                  className={styles.productsRow}
                  justify="start"
                  gutter={[
                    { xs: 0, sm: 0, md: 0, lg: 24, xl: 24, xxl: 40 },
                    { xs: 0, sm: 0, md: 0, lg: 24 },
                  ]}
                >
                  {renderProducts()}
                </Row>
              ) : (
                renderProducts()
              )}
            </Col>
          </Row>
          <Space direction={"vertical"}>
            <Tooltip
              title={
                !cart.valid
                  ? "Il numero di prodotti nel carrello non è corretto!"
                  : null
              }
            />
          </Space>
        </Space>
      </Content>
    </Layout>
  );
};
