import styles from "./styles.module.css";
import { Card, Col, Row, Image } from "antd";
import React, { useState } from "react";
import ShippingDetailsModal from "../Shop/ShippingDetailsModal";
import useUATracker from "../Networking/Metrics/useUATracker";
import { ShippingDetailsOpened } from "../Networking/Metrics/UserActions/Shop";
import { useSettings } from "../Networking/useSettings";
import { useTranslation } from "react-i18next";

interface ShippingProductBannerProps {
  dismissShippingBanner?: () => void;
}
const ShippingProductBanner = ({
  dismissShippingBanner,
}: ShippingProductBannerProps) => {
  const [open, setModalOpen] = useState(false);
  const { trackUserAction } = useUATracker();

  const { subsEnabled } = useSettings();

  const { t } = useTranslation();

  const onConfirm = () => {
    setModalOpen(false);
  };

  return (
    <Card
      key={"shipping"}
      className={styles.productCard}
      style={{ minHeight: 110, height: 110, marginBottom: 8, marginTop: 24 }}
      bodyStyle={{ padding: 0 }}
    >
      <ShippingDetailsModal
        open={open}
        onCancel={onConfirm}
        onConfirm={onConfirm}
      />
      <Row gutter={14}>
        <Col
          span={11}
          style={{ display: "flex", alignItems: "center", height: 110 }}
          onClick={() => {
            setModalOpen(true);
            trackUserAction(new ShippingDetailsOpened());
          }}
        >
          <Image
            alt={"membership"}
            src={"/images/shipping.jpg"}
            preview={false}
          />
        </Col>

        <Col
          span={13}
          className={styles.details}
          style={{ minHeight: 110, height: 110, paddingBottom: 16 }}
        >
          <Row justify="start">
            <Col
              className={styles.title}
              style={{ paddingBottom: 0, flex: 1 }}
              onClick={() => {
                setModalOpen(true);
                trackUserAction(new ShippingDetailsOpened());
              }}
            >
              {subsEnabled
                ? "Vuoi saperne di più su come spediamo Humamy?"
                : t("Come funziona la spedizione?")}
            </Col>
            {!subsEnabled && (
              <Col>
                <img
                  src="/icons/close-small.svg"
                  alt="dismiss banner"
                  style={{ marginRight: 4 }}
                  onClick={dismissShippingBanner}
                />
              </Col>
            )}
          </Row>
          <Row
            style={{ width: "100%" }}
            justify={"space-between"}
            onClick={() => {
              setModalOpen(true);
              trackUserAction(new ShippingDetailsOpened());
            }}
          >
            <Col>
              <div className={styles.detailsBtn}>{t("Scopri")}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default ShippingProductBanner;
