import { DynamicPopUp, useSettings } from "../Networking/useSettings";
import { useMe } from "../AppSetup/MeContext";
import dayjs from "dayjs";
import { Modal } from "antd";
import { useState } from "react";
import { Widget } from "@typeform/embed-react";

interface DynamicPopupProps {
  popup: DynamicPopUp;
}

export const DynamicPopupElement = ({
  popup: { typeformId, showUpWhen, title, buttonText },
}: DynamicPopupProps) => {
  const me = useMe();
  const { email } = me;
  const settings = useSettings();
  // eslint-disable-next-line no-new-func
  const shouldShowPopupModal = Function(showUpWhen)();

  const popupInCooldown =
    localStorage.getItem(`popupInCooldown:${typeformId}`) &&
    dayjs(localStorage.getItem(`popupInCooldown:${typeformId}`)).isAfter(
      dayjs(),
    );

  let popupModalResult = false;
  try {
    popupModalResult = shouldShowPopupModal({ me, settings, dayjs });
  } catch (e) {
    console.error(e);
  }

  const [open, setOpen] = useState(!popupInCooldown && popupModalResult);

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={true}
      title={title}
      okText={buttonText}
      centered
      width={1000}
      bodyStyle={{ height: "800px" }}
      onOk={() => {
        setOpen(false);
        localStorage.setItem(
          `popupInCooldown:${typeformId}`,
          dayjs().add(3, "day").toISOString(),
        );
      }}
    >
      <Widget
        style={{ display: "flex", justifyContent: "center" }}
        hidden={{
          email,
        }}
        width={800}
        height={800}
        id={typeformId}
      />
    </Modal>
  );
};
