import { useEndpoint } from "./common";

interface UserSegment {
  experimentName: string;
  segmentName: string;
  segmentIndex: number;
  error?: string;
}

interface ExperimentSegment {
  name: string;
}

interface Experiment {
  name: string;
  segments: ExperimentSegment[];
}

export const useExperiments = () => {
  const { data: experiments, isLoading } =
    useEndpoint<Experiment[]>("/get-experiments");
  return { experiments, isLoading };
};

export const useExperimentSegments = () => {
  const { data: segments, isLoading } = useEndpoint<UserSegment[]>(
    "/get-experiments-segments",
  );
  return { segments, isLoading };
};
