import { Button, Collapse, Form, Input, Spin } from "antd";
import styles from "./styles.module.css";
import { DownOutlined } from "@ant-design/icons";
import { useState } from "react";
import { InvoiceData } from "../common/me";
import useApiClient from "../Networking/useApiClient";
import { useMe } from "../AppSetup/MeContext";
import { useSettings } from "../Networking/useSettings";
import { useTranslation } from "react-i18next";

const InvoiceFormPanel = () => {
  const [loading, setLoading] = useState(false);
  const me = useMe();
  const { setInvoiceData } = useApiClient();
  const [form] = Form.useForm();

  const { t } = useTranslation();

  if (me?.invoiceData && !loading) {
    form.setFieldsValue({ ...me.invoiceData });
  }

  const onFinish = async (invoiceData: InvoiceData) => {
    setLoading(true);
    await setInvoiceData(invoiceData);
    setLoading(false);
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      className={styles.invoiceForm}
    >
      <Form.Item
        name={"firstName"}
        rules={[{ required: true }]}
        label={t("Nome")}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"lastName"}
        rules={[{ required: true }]}
        label={t("Cognome")}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"businessName"}
        rules={[{ required: true }]}
        label={t("Ragione Sociale")}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"province"}
        rules={[{ required: true }]}
        label={t("Provincia")}
      >
        <Input />
      </Form.Item>
      <Form.Item name={"zipCode"} rules={[{ required: true }]} label={t("CAP")}>
        <Input />
      </Form.Item>
      <Form.Item
        name={"addressLine"}
        rules={[{ required: true }]}
        label={t("Indirizzo")}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"taxCode"}
        rules={[{ required: true }]}
        label={t("Codice Fiscale")}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"vatNumber"}
        rules={[
          {
            required: true,
            message: "Inserisci la partita IVA",
          },
          {
            pattern: /[0-9]{11}/,
            message: "Partita IVA non valida. Inserisci 11 cifre.",
          },
        ]}
        label={t("Partita IVA")}
      >
        <Input />
      </Form.Item>
      <Form.Item name={"pec"} rules={[{ required: true }]} label={t("PEC")}>
        <Input />
      </Form.Item>
      <Form.Item name={"email"} rules={[{ required: true }]} label={t("Email")}>
        <Input />
      </Form.Item>
      <Button
        onClick={(e) => {
          form.submit();
          e.preventDefault();
        }}
        disabled={loading}
        htmlType={"submit"}
        className={styles.button}
      >
        {loading ? <Spin /> : t("Conferma")}
      </Button>
    </Form>
  );
};

const InvoiceForm = () => {
  const { improvedShippingFlow } = useSettings();
  const { t } = useTranslation();
  return (
    <Collapse
      ghost
      size="large"
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <DownOutlined rotate={isActive ? 180 : 0} />
      )}
      className={styles.collapse}
      items={[
        {
          key: "invoice-form",
          label: improvedShippingFlow
            ? t("Hai la partita iva? Clicca qui per i dati di fatturazione")
            : t(
                "Hai la partita iva e vuoi la fattura? Clicca qui per inserire i dati necessari.",
              ),
          children: <InvoiceFormPanel />,
        },
      ]}
    />
  );
};

export default InvoiceForm;
