import { useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect } from "react";
import useApiClient from "../Networking/useApiClient";
import { Spin } from "antd";

export const MembershipCheckout: React.FC = () => {
  const { membershipSku, sellingPlanId } = useParams();
  const navigate = useNavigate();

  const { getMembershipSelfCheckoutUrl } = useApiClient();

  const checkoutMembership = useCallback(async () => {
    if (!membershipSku) {
      return;
    }
    const checkoutResult = await getMembershipSelfCheckoutUrl(membershipSku);
    if (checkoutResult) {
      window.location.replace(checkoutResult.checkoutUrl);
    }
  }, [getMembershipSelfCheckoutUrl, membershipSku]);

  useEffect(() => {
    if (!membershipSku) {
      navigate("/");
    } else {
      void checkoutMembership();
    }
  }, [membershipSku, sellingPlanId, checkoutMembership, navigate]);

  return <Spin />;
};
