import { ActionInfo, UserAction } from "../event";

class EmailActionInfo implements ActionInfo {
  email: string;
  loginMethods?: string;

  constructor(email: string, loginMethods?: string[]) {
    this.email = email;
    this.loginMethods = loginMethods ? loginMethods.join(";") : undefined;
  }
}

class LoginActionInfo implements ActionInfo {
  email: string;
  zipCode?: string;

  constructor(email: string, zipCode?: string) {
    this.email = email;
    this.zipCode = zipCode;
  }
}

export class LoginSuccessful implements UserAction {
  type: string = "user_login_successful";
  actionInfos: EmailActionInfo;
  metaCustomEventName: string = "CompleteRegistration";

  constructor(actionInfos: EmailActionInfo) {
    this.actionInfos = actionInfos;
  }
}

export class LoginEmailConfirmationSent implements UserAction {
  type: string = "login_email_confirmation_sent";
  actionInfos: EmailActionInfo;

  constructor(actionInfos: EmailActionInfo) {
    this.actionInfos = actionInfos;
  }
}

interface OnboardingQuizActionInfo extends ActionInfo {
  onboardingQuizId: string;
}

export class LoginContinue implements UserAction {
  type: string = "login_continue_tapped";
  actionInfos: LoginActionInfo;

  constructor(actionInfo: LoginActionInfo) {
    this.actionInfos = actionInfo;
  }
}

export class LoginAddressNotServed implements UserAction {
  type: string = "login_address_not_served";
  actionInfos: LoginActionInfo;

  constructor(actionInfo: LoginActionInfo) {
    this.actionInfos = actionInfo;
  }
}

export class UserLogoutSuccessful implements UserAction {
  type: string = "user_logout_successful";
  actionInfos = {};
}

export class RegistrationModalDisplayed implements UserAction {
  type: string = "registration_modal_displayed";
  actionInfos = {};
}

export class RegistrationModalCompleted implements UserAction {
  type: string = "registration_modal_completed";
  actionInfos = {};
  metaCustomEventName: string = "CompleteRegistration";
}

interface ContactConsentModalActionInfo extends ActionInfo {
  phone: string;
  consent: "si" | "no";
}
export class ContactConsentModalDisplayed implements UserAction {
  type: string = "contact_consent_modal_displayed";
  actionInfos = {};
}

export class ContactConsentModalAnswered implements UserAction {
  type: string = "contact_consent_modal_answered";
  actionInfos: ContactConsentModalActionInfo;
  constructor(actionInfo: ContactConsentModalActionInfo) {
    this.actionInfos = actionInfo;
  }
}

export type { OnboardingQuizActionInfo, LoginActionInfo };
