import { useEndpoint } from "./common";
import { Product } from "../common/product";
import React from "react";
import { useSettings } from "./useSettings";

interface ProductContextProps {
  products: Product[];
  productsMap: Record<string, Product>;
}

const ProductContext = React.createContext<ProductContextProps | null>(null);

export const useServerProducts = (collectionId?: string) => {
  const { productsCollectionId, hideOutOfStockProducts } = useSettings();

  const { data: products, isLoading } = useEndpoint<Product[]>("/products", {
    collectionId: collectionId || productsCollectionId,
  });

  let filteredProducts = products;
  if (products) {
    filteredProducts = products.filter((product) => {
      if (hideOutOfStockProducts) {
        return product.active && product.inventoryQuantity > 0;
      } else {
        return product.active;
      }
    });
  }

  const productsMap: Record<string, Product> = React.useMemo(() => {
    if (!products) {
      return {} as Record<string, Product>;
    }
    return products.reduce(
      (acc, product) => {
        acc[product.id] = product;
        return acc;
      },
      {} as Record<string, Product>,
    );
  }, [products]);

  return { products: filteredProducts, isLoading, productsMap };
};

export const useProducts = () => {
  const productContextValue = React.useContext(ProductContext);

  if (!productContextValue) {
    throw new Error("Missing products context");
  }
  return productContextValue;
};

export { ProductContext };
export type { ProductContextProps };
