import { useSettings } from "../Networking/useSettings";
import { useMe } from "../AppSetup/MeContext";
import { useProducts } from "../Networking/useProducts";
import { Product } from "../common/product";
import Constants from "../common/constants";
import dayjs from "dayjs";

const useReviews = () => {
  const { reviewCooldownInDays } = useSettings();
  const { recipesToReview } = useMe();
  const { products } = useProducts();

  const productsToReview = recipesToReview
    .map((recipeId) => products?.find((product) => product.id === recipeId))
    .filter((product) => product !== undefined) as Product[];

  const isReviewCooldownActive = () => {
    if (!reviewCooldownInDays) return false;

    const item = localStorage.getItem(Constants.REVIEW_PERFORMED);
    if (!item) return false;

    const now = dayjs();
    const submissionDate = dayjs(JSON.parse(item).submissionDate);
    const expirationDate = submissionDate.add(reviewCooldownInDays, "day");

    return expirationDate.isAfter(now);
  };

  const setReviewCooldown = () => {
    if (!reviewCooldownInDays) return;

    const now = dayjs();
    const value = {
      submissionDate: now,
    };
    localStorage.setItem(Constants.REVIEW_PERFORMED, JSON.stringify(value));
  };

  const isReviewCooldown = isReviewCooldownActive();

  if (
    !reviewCooldownInDays ||
    isReviewCooldown ||
    productsToReview.length === 0
  )
    return { undefined, setReviewCooldown };

  return { productsToReview, setReviewCooldown };
};

export default useReviews;
