import { Button, Tooltip } from "antd";
import productsStyles from "../Products/styles.module.css";
import React from "react";
import useCart from "../Hooks/useCart";
import { Product } from "../common/product";
import { useBoxConfig } from "../Hooks/useBoxConfig";
import { useTranslation } from "react-i18next";
import { useSettings } from "../Networking/useSettings";

interface CartButtonsProps {
  product: Product;
  isInCart: boolean;
}

const CartButtons = ({ product, isInCart }: CartButtonsProps) => {
  const cart = useCart();
  const boxConfig = useBoxConfig();

  const { t } = useTranslation();

  const inCart: number =
    cart?.lineItems.find((li) => li.product.id === product.id)?.quantity || 0;

  let canAdd = (inCart || 0) < product.inventoryQuantity;
  let cantAddReason: string | undefined;
  if (!canAdd) {
    cantAddReason = t("Scorte Esaurite");
  }

  const cartFull =
    cart.totalItems >= Math.max(...boxConfig.map((el) => el.items));
  if (cartFull) {
    canAdd = false;
    cantAddReason = t("Carrello Pieno!");
  }

  const canRemove = !!inCart && inCart > 0;
  let cantRemoveReason: string | undefined;
  if (!canRemove) {
    cantRemoveReason = t("Il prodotto non è nel carrello");
  }

  const settings = useSettings();

  var output = inCart ? (
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        alignItems: "center",
        width: settings.improvedShop ? "auto" : isInCart ? 75 : "50%",
      }}
    >
      <div style={{ display: "flex" }}>
        <Tooltip title={cantRemoveReason}>
          <Button
            block
            disabled={!canRemove}
            className={
              isInCart ? productsStyles.editBtnSmall : productsStyles.editBtn
            }
            onClick={(e) => {
              cart.removeProduct(product);
              e.stopPropagation();
            }}
          >
            <img
              className={productsStyles.editIcon}
              src="/icons/minus.svg"
              alt="remove"
            />
          </Button>
        </Tooltip>
      </div>
      <div className={productsStyles.qtyLabel}>{inCart || 0}</div>
      <div style={{ display: "flex" }}>
        <Tooltip title={cantAddReason}>
          <Button
            block
            disabled={!canAdd}
            className={
              isInCart ? productsStyles.editBtnSmall : productsStyles.editBtn
            }
            onClick={(e) => {
              cart.addProduct(product);
              e.stopPropagation();
            }}
          >
            <img
              className={productsStyles.editIcon}
              src="/icons/plus.svg"
              alt="add"
            />
          </Button>
        </Tooltip>
      </div>
    </div>
  ) : (
    <Tooltip title={cantAddReason}>
      <Button
        className={productsStyles.addCta}
        disabled={!canAdd}
        onClick={(e) => {
          cart.addProduct(product);
          e.stopPropagation();
        }}
      >
        {t("Aggiungi")}
      </Button>
    </Tooltip>
  );

  return output;
};

export default CartButtons;
