import styles from "./styles.module.css";
import { Col, Row } from "antd";
import Logo from "../Home/HomeComponents/Logo";
import { useNavigate } from "react-router-dom";

interface ShopHeaderState {
  title: string;
  active: boolean;
}

interface ShopHeaderProps {
  pageTitle?: string;
  states?: ShopHeaderState[];
}

const ShopHeader = ({ pageTitle, states }: ShopHeaderProps) => {
  const navigate = useNavigate();

  if (states) {
    const span = 24 / states.length;
    const goBack = (desiredState: ShopHeaderState) => {
      if (desiredState.title === states[0].title && !desiredState.active)
        navigate(-1);
    };

    return (
      <div style={{ margin: 16 }}>
        <Row>
          <Logo small />
          <div className={styles.headerTitle}>
            <h1>{pageTitle}</h1>
          </div>
        </Row>

        <Row style={{ marginTop: 24 }} wrap={false}>
          {states.map((state, index) => (
            <Col span={span} key={state.title} onClick={() => goBack(state)}>
              <p
                className={
                  styles.shopState +
                  " " +
                  (!state.active && " " + styles.shopStateDisabled)
                }
              >
                {state.title}
              </p>
            </Col>
          ))}
        </Row>
      </div>
    );
  } else {
    return (
      <div style={{ margin: 16 }}>
        <Row>
          <Logo small />
          <div className={styles.headerTitle}>
            <h1>{pageTitle}</h1>
          </div>
        </Row>
      </div>
    );
  }
};

export default ShopHeader;
