import { useSettings } from "../Networking/useSettings";
import { useMe } from "../AppSetup/MeContext";

interface Discount {
  code: string;
  amount: number;
}

export const useDiscount = () => {
  const settings = useSettings();
  const me = useMe();

  if (
    settings.firstPurchaseDiscountAmount &&
    settings.firstPurchaseDiscountCode &&
    !me.latestOrder
  ) {
    return {
      code: settings.firstPurchaseDiscountCode,
      amount: settings.firstPurchaseDiscountAmount,
    };
  }
  return null;
};

export type { Discount };
