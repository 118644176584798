import Constants from "../common/constants";
import { useCallback } from "react";

const useHeaders = () => {
  const getHeaders = useCallback(() => {
    return JSON.parse(localStorage.getItem(Constants.HEADERS) || "{}") as {
      [key: string]: string;
    };
  }, []);
  const setHeader = useCallback(
    (key: string, value: string | null) => {
      let headers = getHeaders();
      if (!value) {
        delete headers[key];
      } else {
        headers[key] = value;
      }
      if (JSON.stringify(getHeaders()) !== JSON.stringify(headers)) {
        localStorage.setItem(Constants.HEADERS, JSON.stringify(headers));
      }
    },
    [getHeaders],
  );

  return { getHeaders, setHeader };
};

export default useHeaders;
