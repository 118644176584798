import React, { useEffect, useState } from "react";
import useCart from "../Hooks/useCart";
import { useNavigate } from "react-router-dom";
import shopStyles from "../Shop/styles.module.css";
import homeStyles from "../Home/styles.module.css";
import styles from "./styles.module.css";
import { Row, Col } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { WaitlistCTASelected } from "../Networking/Metrics/UserActions/Shop";
import useUATracker from "../Networking/Metrics/useUATracker";
import { useSettings } from "../Networking/useSettings";

const WaitingList = () => {
  const [sentMetrics, setSentMetrics] = useState(false);
  const { trackUserAction } = useUATracker();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { headerButtonClick, shouldPurchaseWaitingList } = useCart();
  const { unlimited, unlimitedMaxNumberOfDishesPerPeriod } = useSettings();

  const waitingListUpgradePrice = unlimited?.discountedAmountInCents
    ? unlimited?.discountedAmountInCents / 100 - 10
    : 90;

  useEffect(() => {
    if (!shouldPurchaseWaitingList) navigate("/shop/unlimited-paywall");
  }, [shouldPurchaseWaitingList, navigate]);

  useEffect(() => {
    if (!shouldPurchaseWaitingList || sentMetrics) return;
    trackUserAction(new WaitlistCTASelected());
    setSentMetrics(true);
  }, [shouldPurchaseWaitingList, sentMetrics, trackUserAction]);

  useEffect(() => {
    headerButtonClick.subscribe(async () => {
      navigate("/shop/unlimited-paywall");
    });
  }, [navigate, headerButtonClick]);

  return (
    <Row justify="center">
      <Col span={8} xs={24} md={12} xl={8} className={styles.body}>
        <img src={"/icons/unlimited.svg"} alt={"unlimited icon"} />
        <h1
          className={shopStyles.title}
          style={{
            fontSize: 20,
            marginTop: 8,
            marginBottom: 24,
            color: "var(--green)",
          }}
        >
          {t("Prenota il tuo posto in Humamy")}
        </h1>
        <p
          className={shopStyles.sectionBody}
          style={{ color: "var(--dark-grey)", textAlign: "center" }}
        >
          {t(
            "Accederai automaticamente al servizio appena nuovi posti saranno disponibili",
          )}
        </p>

        <div style={{ display: "flex", height: "100%", gap: 24 }}>
          <div className={styles.timeline}>
            <img src={"/icons/check_green_fill.svg"} alt={"check"} />
          </div>

          <div style={{ display: "flex", flexFlow: "column", flex: 1 }}>
            <div
              className={homeStyles.banner + " " + homeStyles.bannerBox}
              style={{
                marginBottom: 24,
                backgroundColor: "var(--lighter-green)",
                textAlign: "left",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={shopStyles.stepTitle}>
                  {t("Paga l’anticipo")}
                </div>
                <div className={shopStyles.stepTitle}>10€</div>
              </div>
              <div>
                <p
                  className={shopStyles.sectionBody}
                  style={{ color: "var(--dark-grey)" }}
                >
                  {t(
                    "Per iscriverti alla lista d’attesa paghi ora un anticipo di 10€, poi sottratto dal costo del primo mese.",
                  )}
                </p>
                <div className={shopStyles.noteWithIcon}>
                  <img
                    src={"/icons/alert.svg"}
                    alt={"attention"}
                    style={{ width: 24 }}
                  />
                  {t(
                    "Puoi disdire e chiedere il rimborso in ogni momento, contattando l’assistenza dal tuo profilo.",
                  )}
                </div>
              </div>
            </div>

            <div style={{ marginBottom: 8 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={styles.dot}></div>
                <div className={shopStyles.stepTitle}>
                  {t("Arriva il tuo turno")}
                </div>
                <div className={shopStyles.stepTitle}>
                  {waitingListUpgradePrice}€
                </div>
              </div>
              <p
                className={shopStyles.sectionBody}
                style={{ color: "var(--dark-grey)", textAlign: "left" }}
              >
                <Trans
                  i18nKey={"waitingListUpgrade"}
                  waitingListUpgradePrice={waitingListUpgradePrice}
                >
                  L’abbonamento si attiverà in automatico, e riceverai una mail
                  di notifica. Ti verranno addebitati automaticamente i restanti{" "}
                  {{ waitingListUpgradePrice }}€.
                </Trans>
              </p>
            </div>

            <div>
              <div>
                <div className={styles.dot}></div>
                <div className={shopStyles.stepTitle}>
                  {t("Ordina Humamy gratis!  🎉")}
                </div>
              </div>
              {unlimitedMaxNumberOfDishesPerPeriod ? (
                <p
                  className={shopStyles.sectionBody}
                  style={{ color: "var(--dark-grey)", textAlign: "left" }}
                >
                  {t(
                    "Ordina fino a 40 piatti al mese in box da 20 piatti l'una.",
                  )}
                </p>
              ) : (
                <p
                  className={shopStyles.sectionBody}
                  style={{ color: "var(--dark-grey)", textAlign: "left" }}
                >
                  {t("Ordina quanti piatti vuoi, senza costi aggiuntivi!")}
                </p>
              )}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default WaitingList;
