import { Button, Modal } from "antd";
import useApiClient from "../Networking/useApiClient";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface MarketingConsentModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const MarketingConsentModal = ({
  open,
  setOpen,
}: MarketingConsentModalProps) => {
  const { t } = useTranslation();
  const { fetchMarketingConsent } = useApiClient();

  const [currentConsent, setCurrentConsent] = useState<boolean | undefined>(
    undefined,
  );
  useEffect(() => {
    void fetchMarketingConsent().then(setCurrentConsent);
  }, [fetchMarketingConsent]);
  const { submitMarketingConsent } = useApiClient();

  const updateConsent = (consent: boolean) => {
    submitMarketingConsent(consent).then(() => {
      void fetchMarketingConsent().then(setCurrentConsent);
    });
  };

  return (
    <Modal
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      title={t("Consenso Marketing")}
    >
      <p>
        {t(
          "Vorremmo inviarti informazioni di marketing riguardanti nostri prodotti e servizi.",
        )}
      </p>
      <p>
        {t(
          "Per favore, comunicaci se ti piacerebbe ricevere informazioni di marketing.",
        )}
      </p>
      <p>
        {t(
          "Puoi cambiare la tua scelta in qualsiasi momento visitando le impostazioni del tuo profilo.",
        )}
      </p>
      {currentConsent === undefined ? null : currentConsent ? (
        <p>{t("Hai dato il consenso a ricevere informazioni di marketing.")}</p>
      ) : (
        <p>
          {t("Non hai dato il consenso a ricevere informazioni di marketing.")}
        </p>
      )}
      <p>
        <Button
          onClick={() => {
            updateConsent(true);
          }}
        >
          {t("Si")}
        </Button>
        <Button
          onClick={() => {
            updateConsent(false);
          }}
        >
          {t("No")}
        </Button>
      </p>
    </Modal>
  );
};
