import { Button, Col, Modal, Row } from "antd";
import styles from "./styles.module.css";
import { BoxConfig, useSettings } from "../Networking/useSettings";
import { useBoxConfig } from "../Hooks/useBoxConfig";
import MembershipBoxOption from "./MembershipBoxOption";
import useCart from "../Hooks/useCart";

interface MembershipModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  currentBox: BoxConfig;
  currentTotal: number;
  discount?: number;
}

const MembershipModal = ({
  open,
  onCancel,
  onConfirm,
  currentBox,
  currentTotal,
  discount,
}: MembershipModalProps) => {
  const { membership } = useSettings();
  const boxConfig = useBoxConfig();

  const { instalments } = useCart();

  if (!membership) {
    return null;
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className={styles.fullscreenModal + " " + styles.yellow}
      style={{ top: 0 }}
      closeIcon={
        <img
          className={styles.closeIcon}
          src="/icons/close-modal2.svg"
          alt="close"
        />
      }
      footer={
        <Row align="middle" justify="center" className={styles.ctaBar}>
          <Col span={24}>
            <Button
              onClick={onConfirm}
              className={
                styles.button +
                " " +
                styles.modalBtn +
                " " +
                styles.primaryGreen
              }
              style={{ fontSize: 18 }}
            >
              Continua
            </Button>
            <div style={{ marginTop: 8, fontSize: 16 }}>
              Totale{" "}
              <b>
                {instalments
                  ? instalments.immediatePriceInCents / 100
                  : currentTotal.toFixed(2)}{" "}
                €
              </b>
            </div>
          </Col>
        </Row>
      }
    >
      <img
        className={styles.modalImage}
        src={"/images/membership-dish-pattern.webp"}
        alt={"delicious dish"}
      />
      <div className={styles.bigTitle}>
        <span className={styles.highlight}>Cosa</span> c'è nel mio ordine?
      </div>
      <div style={{ marginTop: 24 }}>
        <div className={styles.listItem}>
          <img src={"/icons/1.svg"} alt={"first"} />
          {instalments ? (
            <div>
              L'anticipo della tua box da {currentBox.items} piatti:&nbsp;:{" "}
              <b>{instalments.immediatePriceInCents / 100} €</b>
            </div>
          ) : (
            <div>
              La tua box da {currentBox.items} piatti:&nbsp;
              {discount ? (
                <>
                  <b style={{ textDecoration: "line-through", opacity: 0.6 }}>
                    {currentBox.originalPrice} €
                  </b>{" "}
                  <b>{currentBox.price} €</b>
                </>
              ) : (
                <b>{currentBox.price} €</b>
              )}
            </div>
          )}
        </div>
        <div className={styles.listItem}>
          <img src={"/icons/2.svg"} alt={"second"} />
          <div>
            {membership.freeTrialPeriod ? (
              <>
                Prova gratuita di {membership.freeTrialPeriod.count}{" "}
                {membership.freeTrialPeriod.unit} della membership Humamy:
                <b>0 €</b>
              </>
            ) : (
              <>
                Membership Humamy valida per&nbsp;
                <b>
                  {membership.period.count} {membership.period.unit}:
                </b>
                &nbsp;
                <b>{membership.price} €</b>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={styles.whiteSection} id="membership-explanation">
        <div className={styles.sectionTitle}>
          Che cos’è la membership Humamy?
        </div>
        <div className={styles.sectionBody}>
          <div className={styles.listItem}>
            <img src={"/icons/check_yellow.svg"} alt={"check"} />
            <div>
              Una quota di iscrizione da <b>{membership.price} €</b> ogni
              <b>
                &nbsp;
                {membership.period.count} {membership.period.unit},&nbsp;
              </b>
              {membership.freeTrialPeriod && (
                <b>
                  con un periodo di prova di {membership.freeTrialPeriod.count}
                  &nbsp;
                  {membership.freeTrialPeriod.unit}
                  &nbsp;
                </b>
              )}
              necessaria per acquistare i piatti Humamy
            </div>
          </div>
          <div className={styles.listItem}>
            <img src={"/icons/check_yellow.svg"} alt={"check"} />
            <div>
              Con la membership Humamy hai accesso a
              <b>
                &nbsp; tutti i piatti, assistenza clienti, e zero costi di
                spedizione
              </b>
            </div>
          </div>
          <div className={styles.listItem}>
            <img src={"/icons/check_yellow.svg"} alt={"check"} />
            <div>
              Potrai acquistare quanti piatti vuoi senza vincoli! Non ricevi
              piatti in automatico a casa, ma &nbsp;
              <b>solamente quelli che ordini tu</b>
            </div>
          </div>
          <div className={styles.listItem}>
            <img src={"/icons/check_yellow.svg"} alt={"check"} />
            <div>Puoi disdire in qualsiasi momento dal tuo profilo</div>
          </div>
        </div>
      </div>

      <div className={styles.greySection} id="available-boxes">
        <div className={styles.sectionTitle} style={{ width: "70%" }}>
          Le box disponibili
        </div>
        <div className={styles.sectionBody} style={{ width: "70%" }}>
          Nei tuoi ordini potrai sempre scegliere tra:
        </div>

        {boxConfig.map((box) => (
          <MembershipBoxOption
            key={box.name}
            name={box.name}
            items={box.items}
            price={box.originalPrice || box.price}
            nonMembershipPrice={box.nonMembershipPrice!}
            layout={"singlePrice"}
          />
        ))}
      </div>
    </Modal>
  );
};

export default MembershipModal;
