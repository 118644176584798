import useSWR, { mutate } from "swr";
import useAPIClient, { NetworkError } from "./useApiClient";
import { cache } from "swr/_internal";

export const useEndpoint = <Type extends any>(
  endpoint: string,
  params?: { [key: string]: any | undefined },
  onError?: (err: NetworkError) => void,
): {
  data: Type | undefined;
  isLoading: boolean;
} => {
  const { get } = useAPIClient();

  const cacheUrl = `${endpoint}?${new URLSearchParams(params).toString()}`;

  const { data, error } = useSWR(
    endpoint,
    (endpoint: string) => get<Type>(endpoint, params, onError),
    {
      revalidateOnFocus: false,
      revalidateOnMount: cache.get(cacheUrl) === undefined,
      refreshWhenHidden: false,
      refreshInterval: 120000,
    },
  );
  return {
    data: data,
    isLoading: error === undefined && data === undefined,
  };
};

export const invalidate = async (endpoint: string) => {
  cache.delete(endpoint);
  await mutate(endpoint);
};
