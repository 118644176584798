import React, { ReactElement, useEffect, useState } from "react";
import { useBoxConfig } from "../Hooks/useBoxConfig";
import { Col, Divider, Progress, Row, Space, Layout } from "antd";
import styles from "./styles.module.css";
import { useLocation } from "react-router-dom";
import ListItem from "./ListItem";
import useUATracker from "../Networking/Metrics/useUATracker";
import {
  ChangeSubscriptionProfileSelected,
  OrderCartClosed,
  OrderCartOpened,
} from "../Networking/Metrics/UserActions/Shop";
import { useSettings } from "../Networking/useSettings";
import MembershipListItem from "./MembershipListItem";
import { useMe } from "../AppSetup/MeContext";
import useCart from "../Hooks/useCart";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface BoxProgressProps {
  current: number;
  steps: number[];
  prices: string[];
  titles: string[];
}

const { Sider } = Layout;

const BoxProgress = ({ current, steps, prices, titles }: BoxProgressProps) => {
  const { hideRecapPrices } = useSettings();
  const stepElements: ReactElement[] = [];
  for (let i = 0; i < steps.length; i++) {
    const prevBoxSize = i > 0 ? steps[i - 1] : 0;
    const currentBoxFill = current - prevBoxSize;
    const currentBoxDiff = steps[i] - prevBoxSize;
    const percentage =
      currentBoxFill <= 0
        ? 0
        : Math.min((currentBoxFill / currentBoxDiff) * 100, 100);

    const active = current <= steps[i] && current > prevBoxSize;
    stepElements.push(
      <Space
        key={`space-${i}`}
        style={{ flexGrow: 1 }}
        direction={"vertical"}
        size={0}
      >
        <div
          style={{ height: 10 }}
          className={
            styles.priceLabel + " " + (active ? styles.priceLabelActive : "")
          }
        >
          {titles[i]}
        </div>
        <Progress
          percent={percentage}
          strokeLinecap={"square"}
          showInfo={false}
          // strokeWidth={4}
          size={["small", 4]}
          style={{ marginBottom: 0 }}
        />
        {!hideRecapPrices ? (
          <div
            style={{ height: 20 }}
            className={
              styles.priceLabel + " " + (active ? styles.priceLabelActive : "")
            }
          >
            {prices[i]}
          </div>
        ) : null}
      </Space>,
    );
    if (i < steps.length - 1) {
      stepElements.push(
        <Divider
          style={{
            height: 25,
            marginTop: 10,
          }}
          key={`divider-${i}`}
          orientation={"center"}
          type="vertical"
        />,
      );
    }
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
      className={styles.boxProgress}
    >
      {" "}
      {stepElements}{" "}
    </div>
  );
};

interface CartRecapSiderProps {
  open: boolean;
  editable: boolean;
  setOpened: (opened: boolean) => void;
  hideCart?: boolean;
  hideProgress?: boolean;
}

export const CartRecapSider = ({
  open,
  editable,
  setOpened,
  hideProgress,
}: React.PropsWithChildren<CartRecapSiderProps>) => {
  const cart = useCart();

  const { membership, defaultSubscriptionPeriodInWeeks } = useSettings();

  const boxConfig = useBoxConfig();
  const location = useLocation();
  const navigate = useNavigate();

  const { trackUserAction } = useUATracker();

  const { t } = useTranslation();

  const toggleCartExpanded = () => {
    trackUserAction(open ? new OrderCartClosed() : new OrderCartOpened());
    setOpened(!open);
  };

  const edit = location.pathname === "/shop/edit";
  const { freemiumMode, subsEnabled } = useSettings();

  const { isMember } = useMe();
  const { totalItems, setLineItems } = cart;

  const [prevTotalItems, setPrevTotalItems] = useState(totalItems);
  const [isFirstRendering, setIsFirstRendering] = useState(true);

  useEffect(() => {
    if (isFirstRendering && totalItems > 0) {
      setOpened(true);
      setIsFirstRendering(false);
    }
  }, [totalItems, setOpened, isFirstRendering]);

  useEffect(() => {
    // When the number of items increases, open the cart
    // if (prevTotalItems < totalItems) setOpened(true);

    // When the number of items changes from 0 to 1, open the cart
    if (prevTotalItems === 0 && totalItems === 1) setOpened(true);

    setPrevTotalItems(totalItems);
  }, [totalItems, setOpened, prevTotalItems]);

  let box = boxConfig.find((el) => totalItems <= el.items);
  if (!box) {
    box = boxConfig[0];
  }

  const useNonMembershipPrice =
    freemiumMode && !isMember && !cart?.membershipInCart;

  let comparedPrice = box.comparedPrice;
  if (freemiumMode && !isMember && cart?.membershipInCart) {
    comparedPrice = box.nonMembershipPrice;
  }

  const canEditSubscription =
    subsEnabled &&
    (cart.editingSubscription || !defaultSubscriptionPeriodInWeeks);

  const editSubscription = () => {
    if (!canEditSubscription) {
      return;
    }
    trackUserAction(new ChangeSubscriptionProfileSelected());
    navigate("/shop/choose-subscription");
  };

  return (
    <Sider
      collapsedWidth="0"
      collapsed={!open}
      collapsible
      className={styles.cartSiderWrapper + " " + (!open && styles.collapsed)}
      reverseArrow={true}
      theme={"light"}
      width="372"
      zeroWidthTriggerStyle={{
        overflow: "visible",
        position: "absolute",
        top: -90,
        right: 0,
        left: "unset",
      }}
      trigger={
        <Row onClick={toggleCartExpanded} className={styles.cartRecapSiderRow}>
          {canEditSubscription && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                paddingTop: 16,
              }}
            >
              <div className={styles.boxSizeBtn} onClick={editSubscription}>
                <img
                  alt={"edit"}
                  src="/icons/next_small_grey.svg"
                  style={{ transform: "rotate(180deg)", marginTop: 2 }}
                />
                {t("Cambia")}
              </div>
              <Divider type="vertical" className={styles.smallDivider} />
            </div>
          )}

          <div
            className={styles.cartRecapBottomBarRow + " " + styles.recapHeader}
          >
            <div style={{ display: "flex", alignItems: "baseline", gap: 8 }}>
              {hideProgress && (
                <>
                  <div style={{ fontWeight: "700" }}>{box.name}</div>
                  <Divider type="vertical" className={styles.smallDivider} />
                </>
              )}
              <div style={{ fontWeight: "500" }}>
                {totalItems} / {box.items} {t("piatti")}
              </div>
              <Divider type="vertical" className={styles.smallDivider} />

              <div style={{ display: "flex", alignItems: "center" }}>
                {comparedPrice && !subsEnabled && (
                  <>
                    <span
                      className={styles.priceLabel}
                      style={{
                        fontSize: 15,
                        textDecoration: "line-through",
                        opacity: 0.5,
                      }}
                    >
                      {comparedPrice} &euro;
                    </span>
                    &nbsp;&nbsp;
                  </>
                )}
                {!hideProgress && (
                  <>
                    <span>
                      {cart.instalments
                        ? `${
                            cart.instalments.immediatePriceInCents / 100
                          } € ora, ${
                            cart.instalments.repeatedPriceInCents / 100
                          } € tra ${cart.instalments.period.count} ${
                            cart.instalments.period.unit
                          }`
                        : `${cart.currentBoxPrice} €`}
                      {!subsEnabled &&
                        freemiumMode &&
                        !isMember &&
                        cart?.membershipInCart && (
                          <img
                            alt={"premium"}
                            src={"/icons/premium.svg"}
                            className={styles.premiumBadge}
                          />
                        )}
                    </span>
                  </>
                )}
              </div>
            </div>

            <div style={{ alignSelf: "flex-end" }}>
              <div
                className={styles.iconContainer}
                style={!open ? { padding: 5 } : { padding: 12 }}
              >
                <img
                  src="/icons/cart_icon.svg"
                  alt="open cart"
                  style={
                    !open
                      ? { transform: "scale(1)" }
                      : { transform: "scale(0)" }
                  }
                />
                <img
                  src="/icons/next_small_grey.svg"
                  alt="close cart"
                  style={
                    open ? { transform: "scale(1)" } : { transform: "scale(0)" }
                  }
                />
              </div>
              <div
                className={styles.cartBadge}
                style={
                  !open ? { transform: "scale(1)" } : { transform: "scale(0)" }
                }
              >
                {totalItems}
              </div>
            </div>
          </div>
        </Row>
      }
    >
      <div className={styles.cartContentWrapper}>
        {edit && !hideProgress && (
          <Row className={styles.cartSiderProgressRow}>
            <Col span={24}>
              <BoxProgress
                current={totalItems}
                titles={boxConfig.map((b) => b.name)}
                steps={boxConfig.map((b) => b.items)}
                prices={boxConfig.map(
                  (b) =>
                    `${(
                      parseFloat(
                        useNonMembershipPrice
                          ? b.nonMembershipPrice!
                          : b.originalPrice || b.price,
                      ) / b.items
                    ).toFixed(2)}€ / ${t("piatto")}`,
                )}
              />
            </Col>
          </Row>
        )}

        {cart.lineItems.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexFlow: "column",
              alignItems: "stretch",
            }}
          >
            {freemiumMode &&
              !subsEnabled &&
              membership &&
              !isMember &&
              (editable || !!cart.membershipInCart) && (
                <MembershipListItem
                  switchValue={!!cart.membershipInCart}
                  onToggle={(value) =>
                    cart?.setMembership(
                      value ? membership.sku : undefined,
                      true,
                    )
                  }
                  editable={editable}
                />
              )}
            {cart.lineItems.map((item, index) => (
              <ListItem
                item={item}
                editable={editable}
                key={item.product.sku}
                last={index === cart?.lineItems.length - 1}
              />
            ))}
            <div
              className={styles.boxSizeBtn}
              style={{ marginTop: 40 }}
              onClick={() => {
                setLineItems([]);
              }}
            >
              <img
                alt={"edit"}
                src="/icons/trash.svg"
                style={{ marginTop: -3 }}
              />
              {t("Svuota carrello")}
            </div>
          </div>
        ) : (
          <p
            className={styles.cartEmptyState}
            style={{ width: 322, marginTop: 100 }}
          >
            {t("Non hai piatti nel carrello.")}
          </p>
        )}
      </div>
    </Sider>
  );
};
