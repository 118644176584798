import { Product } from "../common/product";
import { useTranslation } from "react-i18next";

const NutritionalValuesTable = ({ product }: { product: Product }) => {
  const { t } = useTranslation();
  const portionWeight = product.cooki?.portionWeight ?? 100;

  const calculatePortionValue = (value: number) =>
    ((value * portionWeight) / 100).toFixed(1);

  return (
    <table style={{ borderSpacing: "10px 10px" }}>
      <thead>
        <tr>
          <th>{t("Valori nutrizionali")}</th>
          <th>{t("Per")} 100g</th>
          <th>
            {t("Per")} {portionWeight}g
          </th>
        </tr>
      </thead>
      <tbody>
        {product.macros && (
          <>
            <tr>
              <td>{t("Calorie")} (kcal)</td>
              <td>{product.macros.calories} kcal</td>
              <td>{calculatePortionValue(product.macros.calories)} kcal</td>
            </tr>
            <tr>
              <td>{t("Carboidrati")}</td>
              <td>{product.macros.carbohydrates}g</td>
              <td>{calculatePortionValue(product.macros.carbohydrates)}g</td>
            </tr>
            <tr>
              <td>{t("Zuccheri")}</td>
              <td>{product.macros.sugars}g</td>
              <td>{calculatePortionValue(product.macros.sugars)}g</td>
            </tr>
            <tr>
              <td>{t("Grassi")}</td>
              <td>{product.macros.fats}g</td>
              <td>{calculatePortionValue(product.macros.fats)}g</td>
            </tr>
            <tr>
              <td>{t("Grassi saturi")}</td>
              <td>{product.macros.saturatedFats}g</td>
              <td>{calculatePortionValue(product.macros.saturatedFats)}g</td>
            </tr>
            <tr>
              <td>{t("Proteine")}</td>
              <td>{product.macros.proteins}g</td>
              <td>{calculatePortionValue(product.macros.proteins)}g</td>
            </tr>
            <tr>
              <td>{t("Fibre")}</td>
              <td>{product.macros.fibers}g</td>
              <td>{calculatePortionValue(product.macros.fibers)}g</td>
            </tr>
            <tr>
              <td>{t("Sale")}</td>
              <td>{product.macros.salt}g</td>
              <td>{calculatePortionValue(product.macros.salt)}g</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default NutritionalValuesTable;
