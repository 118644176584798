import { Product } from "../common/product";

export const tagToFilter = (tag: string) =>
  tag.replace(/_/g, " ").replace("filter:", "");
export const filterToTag = (filter: string) =>
  `filter:${filter.replace(/ /g, "_")}`;

export const useProductFilters = (products: Product[]) => {
  const allTags =
    products?.reduce(
      (acc, product) => [...acc, ...product.tags],
      [] as string[],
    ) || [];
  const uniqueTags = Array.from(new Set(allTags));
  return uniqueTags.filter((tag) => tag.startsWith("filter:")).map(tagToFilter);
};
