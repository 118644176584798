import FingerprintJS from "@fingerprintjs/fingerprintjs";

import { createContext, useContext, useEffect, useState } from "react";
import Constants from "./constants";
import useHeaders from "../Networking/useHeaders";

const getDeviceId = (): string | undefined => {
  if (localStorage.getItem(Constants.FORCED_STORAGE_DEVICE_ID)) {
    return (
      localStorage.getItem(Constants.FORCED_STORAGE_DEVICE_ID) || undefined
    );
  }
  return localStorage.getItem(Constants.STORAGE_DEVICE_ID) || undefined;
};

const useFingerprintJSDeviceId = () => {
  const deviceId = getDeviceId();

  const [_deviceId, setDeviceId] = useState<string | undefined>(deviceId);
  const { setHeader } = useHeaders();

  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load();

      const { visitorId } = await fp.get();

      setHeader(Constants.DEVICE_ID, visitorId);
      localStorage.setItem(Constants.STORAGE_DEVICE_ID, visitorId);
      setDeviceId(getDeviceId());
    };

    void setFp();
  }, [setHeader]);

  return { deviceId: _deviceId };
};

interface DeviceContextProps {
  deviceId: string;
}

const DeviceContext = createContext<DeviceContextProps | null>(null);

const useDeviceId = () => {
  const { deviceId } = useContext(DeviceContext) || {};
  if (!deviceId) {
    throw new Error("Invalid Device ID context!");
  }

  return { deviceId };
};

export default useDeviceId;
export { DeviceContext, useFingerprintJSDeviceId };
