import styles from "../styles.module.css";
import React, { useState } from "react";
import { Button, Col, Row, Spin, Switch } from "antd";
import { useProducts } from "../../Networking/useProducts";
import BoxDetailsModal from "./BoxDetailsModal";
import { useBoxConfig } from "../../Hooks/useBoxConfig";
import { useSettings } from "../../Networking/useSettings";
import MembershipDetailsModal from "../../Shop/MembershipDetailsModal";
import useCart from "../../Hooks/useCart";
import PriceBox from "../../Products/PriceBox";
import { useMe } from "../../AppSetup/MeContext";

const TastingBoxBanner = () => {
  const { products } = useProducts();

  const boxConfig = useBoxConfig();

  const [modalOpened, setModalOpened] = useState(false);

  const { membership, membershipPercentDiscount, freemiumMode } = useSettings();
  const {
    membershipInCart,
    setMembership,
    currentState,
    bestSellers,
    currentTotal,
  } = useCart();

  const box = boxConfig[0];

  const [membershipModalOpen, setMembershipModalOpen] = useState(false);
  const { isMember } = useMe();

  const onMembershipConfirm = () => {
    setMembership(membership?.sku, false);
    setMembershipModalOpen(false);
  };
  const onMembershipCancel = () => {
    setMembershipModalOpen(false);
  };

  if (!products || !bestSellers) {
    return <Spin />;
  }

  // Prevent scrolling if details modal is opened
  if (modalOpened) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  const toggleModal = () => {
    setModalOpened(!modalOpened);
  };

  const previewProducts = bestSellers.slice(1, 4);

  const photosRow = (
    <Row gutter={8} style={{ margin: -16, marginTop: -12, marginBottom: 16 }}>
      {previewProducts?.map((product) => {
        return (
          <Col span={8} key={`product-${product.sku}`}>
            <div
              style={{ backgroundImage: `url(${product.images[0].src})` }}
              className={styles.boxImage}
            ></div>
          </Col>
        );
      })}
    </Row>
  );

  const photosCarousel = (
    <div className={styles.carousel}>
      {bestSellers?.map((product) => {
        return (
          <div onClick={() => setModalOpened(!modalOpened)}>
            <div
              style={{ backgroundImage: `url(${product.images[0].src})` }}
              className={styles.carouselImage}
            ></div>
            <div className={styles.carouselTitle}>{product.title}</div>
          </div>
        );
      })}
    </div>
  );

  const picturesElement = freemiumMode ? photosRow : photosCarousel;

  const MembershipOptionalBanner = (
    <Row className={styles.membershipOptionalBanner}>
      <Col flex="auto">
        <div>
          Attiva la membership Humamy per lo{" "}
          <b>sconto del {membershipPercentDiscount}%</b>
        </div>
        <div
          style={{ paddingTop: 8, textDecoration: "underline" }}
          onClick={() => setMembershipModalOpen(true)}
        >
          Dettagli
        </div>
      </Col>
      <Col flex="none">
        <Switch
          checked={!!membershipInCart}
          onChange={(value) =>
            setMembership(value ? membership?.sku : undefined, true)
          }
          style={{ verticalAlign: "unset" }}
        />
      </Col>
      <MembershipDetailsModal
        open={membershipModalOpen}
        onCancel={onMembershipCancel}
        onConfirm={onMembershipConfirm}
        currentBox={box}
      />
    </Row>
  );

  return (
    <div className={styles.banner + " " + styles.bannerBox}>
      {picturesElement}

      <Row
        gutter={8}
        className={styles.titleRow}
        onClick={() => setModalOpened(!modalOpened)}
      >
        <Col flex="auto">
          <h1 style={{ margin: 0, fontSize: 26 }}>Box Degustazione</h1>
        </Col>
        <Col flex="none">
          <div className={styles.detailsBtn}>Dettagli</div>
        </Col>
      </Row>

      <p
        className={styles.textLeftSmall}
        onClick={() => setModalOpened(!modalOpened)}
      >
        {box.items} piatti, per conoscere il meglio di Humamy.
      </p>

      <Button
        type="primary"
        className={styles.button + " " + styles.buttonLeftAligned}
        onClick={currentState.onConfirm}
      >
        <div>
          Acquista
          <img
            alt={"next"}
            src={`/icons/next_small_grey.svg`}
            style={{ marginBottom: -3, marginLeft: 4 }}
          />
        </div>
        <div style={{ display: "flex", alignItems: "baseline", gap: 4 }}>
          {freemiumMode && (
            <PriceBox
              price={box.nonMembershipPrice || box.comparedPrice || box.price}
              strikethrough={!!membershipInCart || isMember}
              highlight={true}
              premium={false}
            />
          )}
          <PriceBox
            price={
              freemiumMode || isMember ? box.price : currentTotal.toString()
            }
            highlight={true}
            strikethrough={!!(freemiumMode && !membershipInCart && !isMember)}
            premium={!!freemiumMode}
          />
        </div>
      </Button>

      {freemiumMode && !isMember && MembershipOptionalBanner}

      <BoxDetailsModal
        onConfirm={currentState.onConfirm}
        onCancel={toggleModal}
        open={modalOpened}
        products={bestSellers}
        box={box}
        boxTitle="Box Degustazione"
        addMembershipToggle={freemiumMode && !isMember}
      />
    </div>
  );
};

export default TastingBoxBanner;
