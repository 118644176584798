import { ActionInfo, UserAction } from "../event";
import { Dayjs } from "dayjs";

export class UserProfileUpdated implements UserAction {
  type: string = "user_profile_updated";
  actionInfos = {};
}

interface SubscriptionProfileCanceledActionInfo {
  reason: string;
}

export class SubscriptionProfileCancelled implements UserAction {
  type: string = "subscription_profile_cancelled";
  actionInfos: SubscriptionProfileCanceledActionInfo;
  constructor(actionInfo: SubscriptionProfileCanceledActionInfo) {
    this.actionInfos = actionInfo;
  }
}

interface SubscriptionProfilePausedActionInfo extends ActionInfo {
  resumeSubscriptionDate: Dayjs;
}

export class SubscriptionProfilePaused implements UserAction {
  type: string = "subscription_profile_paused";
  actionInfos: SubscriptionProfilePausedActionInfo;

  constructor(actionInfo: SubscriptionProfilePausedActionInfo) {
    this.actionInfos = actionInfo;
  }
}
