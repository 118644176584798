import styles from "./styles.module.css";
import { Col, Row } from "antd";
import ProductCarouselCard from "../Subscription/ProductCarouselCard";
import React from "react";
import { useProducts } from "../../Networking/useProducts";
import { useTranslation } from "react-i18next";

const ShowMenu = () => {
  const { products } = useProducts();
  const { t } = useTranslation();
  const renderMenu = () => {
    return (
      <Row justify="center">
        <Col span={8} xs={24} md={12} lg={24} className={styles.menu} id="menu">
          <h1 className={styles.title} style={{ marginTop: 0, fontSize: 22 }}>
            {t("Scorri e sbircia il menu del mese")}
          </h1>
          <div className={styles.carousel}>
            {products.map((product) => {
              return (
                <ProductCarouselCard
                  key={`product-carousel-${product.id}`}
                  product={product}
                />
              );
            })}
          </div>
        </Col>
      </Row>
    );
  };

  return <>{renderMenu()}</>;
};

export default ShowMenu;
