import ReactPixel, { AdvancedMatching } from "react-facebook-pixel";
import { useCallback, useContext, useEffect, useState } from "react";
import MeContext from "../AppSetup/MeContext";
import Constants from "../common/constants";

const useFbPixel = () => {
  const meContext = useContext(MeContext);
  const [didInit, setDidInit] = useState(false);

  const getEnqueuedEvents = useCallback(() => {
    const key = localStorage.getItem(Constants.STORAGE_RECENT_FB_EVENTS);
    if (!key) {
      return [];
    }
    return key.split(";");
  }, []);

  const enqueueEvent = useCallback(
    (event: string) => {
      localStorage.setItem(
        Constants.STORAGE_RECENT_FB_EVENTS,
        [...getEnqueuedEvents(), event].join(";"),
      );
    },
    [getEnqueuedEvents],
  );

  const sendPendingEvents = useCallback(() => {
    const events = getEnqueuedEvents();
    events.forEach((event) => {
      ReactPixel.track(event);
    });
    localStorage.removeItem(Constants.STORAGE_RECENT_FB_EVENTS);
  }, [getEnqueuedEvents]);

  const sendEvent = useCallback(
    (event: string) => {
      if (didInit) {
        ReactPixel.track(event);
        sendPendingEvents();
      } else {
        enqueueEvent(event);
      }
    },
    [didInit, sendPendingEvents, enqueueEvent],
  );

  useEffect(() => {
    const me = meContext?.me;
    if (!me || didInit) {
      return;
    }
    // @ts-ignore
    const advancedMatching: AdvancedMatching = {
      ct: me.shippingInfo?.address?.city,
      country: me.shippingInfo?.address?.country,
      em: me.email,
      fn: me.firstName,
      ln: me.lastName,
      ph: me.phone,
      st: "IT",
      zp: me.shippingInfo?.address?.zip,
    };
    ReactPixel.init("935299907508089", advancedMatching, {
      autoConfig: true,
      debug: false,
    });
    setDidInit(true);
    sendPendingEvents();
  }, [meContext?.me, didInit, sendPendingEvents]);

  return { sendEvent };
};

export default useFbPixel;
