import styles from "../Shop/Unlimited/styles.module.css";
import React from "react";
import { useMe } from "../AppSetup/MeContext";
import { Trans } from "react-i18next";
import { useSettings } from "../Networking/useSettings";
import { useLocation } from "react-router-dom";
import { useUnlimitedMembership } from "../Hooks/useUnlimitedMembership";

const WebsiteBanners = () => {
  const location = useLocation();
  const { latestOrder, isUnlimitedMember } = useMe();
  const { unlimitedEnabled, unlimited } = useSettings();

  const unlimitedMembership = useUnlimitedMembership();

  const pauseRenewalDate = unlimitedMembership?.scheduledRenewalDate;

  const discountAmount =
    unlimited?.standardAmountInCents && unlimited.discountedAmountInCents
      ? unlimited.standardAmountInCents / 100 -
        unlimited.discountedAmountInCents / 100
      : 200;

  const UnlimitedBanners = () => {
    return (
      !isUnlimitedMember &&
      location.pathname === "/shop/unlimited-paywall" && (
        <>
          {!latestOrder && discountAmount !== 0 && (
            <div className={styles.smallBanner}>
              <Trans i18nKey={"discountBanner"} discountAmount={discountAmount}>
                Sconto di {{ discountAmount }}€ sul primo mese!
              </Trans>
            </div>
          )}
          {pauseRenewalDate && (
            <div className={styles.smallBanner}>
              La pausa finirà il {pauseRenewalDate} e l'abbonamento verrà
              rinnovato.
            </div>
          )}
        </>
      )
    );
  };

  return <>{unlimitedEnabled ? UnlimitedBanners() : null}</>;
};

export default WebsiteBanners;
