import { Collapse, Space, Row, Col } from "antd";
import React from "react";
import { useSettings } from "../Networking/useSettings";
import styles from "./styles.module.css";
import PageTitle from "../PageTitle/PageTitle";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

const FAQ = () => {
  const settings = useSettings();
  const { t } = useTranslation();
  if (!settings) {
    throw new Error("Component cannot be used without settings!");
  }

  return (
    <Space direction={"vertical"} style={{ width: "100%", paddingBottom: 94 }}>
      <PageTitle>F.A.Q.</PageTitle>
      <Row justify="center">
        <Col
          xs={24}
          md={12}
          xl={8}
          style={{ paddingLeft: 24, paddingRight: 24 }}
        >
          {settings.faq.map((faq, faq_index) => (
            <div key={faq_index}>
              <h2 className={styles.infoTitle}>{t(faq.title)}</h2>
              <Collapse bordered={false} className={styles.faqList}>
                {faq.items.map((item, index) => (
                  <Panel key={`${faq_index}${index}`} header={t(item.title)}>
                    <p>{t(item.description)}</p>
                  </Panel>
                ))}
              </Collapse>
            </div>
          ))}
        </Col>
      </Row>
    </Space>
  );
};

export default FAQ;
