import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Button, Tooltip } from "antd";
import useCart from "../../Hooks/useCart";
import { useOpenInNewTab } from "../../Hooks/useRedirect";
import { useMe } from "../../AppSetup/MeContext";

interface ArrivalDateBannerProps {
  date: string;
  editable: boolean;
  editTooltipTitle?: string;
  title: string;
  subEnabled: boolean;
}

const ArrivalDateBanner = ({
  date,
  editable,
  editTooltipTitle,
  title,
  subEnabled,
}: ArrivalDateBannerProps) => {
  const navigate = useNavigate();
  const { hubspotRecordId, email } = useMe();
  const me = useMe(5000);

  const openInNewTab = useOpenInNewTab();

  const reactivateTypeformUrl = `https://6jwrfvwe6gp.typeform.com/to/RS7Zfo8l#record_id=${hubspotRecordId}&email=${email}`;

  const { setEditingSubscription } = useCart();

  return (
    <div className={styles.banner}>
      <img
        style={{ float: "right", margin: -24 }}
        src={"/icons/dish.svg"}
        alt={"yummy dish"}
      />
      <h1 className={styles.title} style={{ width: "70%" }}>
        {title}
      </h1>
      {subEnabled ? (
        <p className={styles.textLeft}>
          Data di consegna prevista: <br />
          <Tooltip title={editTooltipTitle}>
            <b
              style={{
                display: "flex",
                gap: 6,
                lineHeight: "22px",
                marginTop: 4,
              }}
              onClick={() => {
                if (editable) {
                  setEditingSubscription(true);
                  navigate("/shop/shipping/select-date");
                }
              }}
            >
              {date}
              <img
                src="/icons/edit_small.svg"
                alt="edit"
                className={styles.editIcon}
                style={
                  editable
                    ? { opacity: 1 }
                    : !editable &&
                        me.subscriptionProfile.latestOutboundOrder
                          ?.expectedShippingDate
                      ? { opacity: 0.4 }
                      : { display: "none" }
                }
              />
            </b>
          </Tooltip>
        </p>
      ) : (
        <>
          <p>
            Avendo disattivato la tua subscription, non ti verranno addebitate
            nuove box e smetterai di ricevere i nostri piatti una volta ricevuta
            l’ultima box che hai acquistato. Se vuoi ordinare nuovamente, clicca
            qui 👇
          </p>
          <Button
            type={"primary"}
            onClick={() => openInNewTab(reactivateTypeformUrl)}
          >
            Chiedi riattivazione
          </Button>
        </>
      )}
    </div>
  );
};

export default ArrivalDateBanner;
