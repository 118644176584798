import { Button, Col, Modal, Row, Divider } from "antd";
import styles from "./styles.module.css";
import { BoxConfig, useSettings } from "../Networking/useSettings";
import { useBoxConfig } from "../Hooks/useBoxConfig";
import MembershipBoxOption from "./MembershipBoxOption";
import { Trans, useTranslation } from "react-i18next";
import useCart from "../Hooks/useCart";

interface MembershipDetailsModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  currentBox: BoxConfig;
  discount?: number;
}

const MembershipDetailsModal = ({
  open,
  onCancel,
  onConfirm,
  currentBox,
  discount,
}: MembershipDetailsModalProps) => {
  const boxConfig = useBoxConfig();
  const { membership, membershipPercentDiscount } = useSettings();
  const { membershipInCart } = useCart();
  const { t } = useTranslation();

  if (!membership) {
    return null;
  }

  const freeTrialCount = membership.freeTrialPeriod?.count;
  const freeTrialUnit = membership.freeTrialPeriod?.unit
    ? t(membership.freeTrialPeriod?.unit)
    : undefined;

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className={styles.fullscreenModal + " " + styles.membershipDetails}
      style={{ top: 0 }}
      closeIcon={
        <img
          className={styles.closeIcon}
          src="/icons/close-modal2.svg"
          alt="close"
        />
      }
      footer={
        <Row
          align="middle"
          justify="center"
          className={styles.ctaBar}
          style={{
            top: "calc(100dvh - 121px)",
            height: "unset",
            justifyContent: "flex-start",
          }}
        >
          {!membershipInCart ? (
            <Button
              onClick={onConfirm}
              className={
                styles.button +
                " " +
                styles.modalBtn +
                " " +
                styles.primary +
                " " +
                styles.buttonLeftAligned
              }
            >
              {t("Aggiungi Membership all'Ordine")}
              <img
                alt={"plus"}
                src={`/icons/plus_small.svg`}
                style={{ marginBottom: -3, marginLeft: 8 }}
              />
            </Button>
          ) : (
            <Button
              onClick={onConfirm}
              className={
                styles.button +
                " " +
                styles.modalBtn +
                " " +
                styles.buttonLeftAligned
              }
            >
              Rimuovi Membership dall’Ordine
              <img
                alt={"minus"}
                src={`/icons/minus_small.svg`}
                style={{ marginBottom: -3, marginLeft: 8 }}
              />
            </Button>
          )}

          <div style={{ color: "#444444", marginTop: -16 }}>
            {t(
              "Tranquillo, potrai ancora toglierla dal carrello prima di fare l'ordine.",
            )}
          </div>
        </Row>
      }
    >
      <div className={styles.blueSection}>
        <img
          className={styles.modalImage}
          src={"/images/membership-dish-pattern.webp"}
          alt={"delicious dish"}
        />
        <div className={styles.bigTitle} style={{ color: "white" }}>
          <Trans i18nKey={"activateMembership"}>
            Attiva la membership e compra Humamy a &nbsp;
            <span className={styles.highlightGreen}>costo ridotto</span>
          </Trans>
        </div>
      </div>

      <div
        className={styles.greySection}
        id="available-boxes"
        style={{ marginBottom: -32, backgroundSize: "30%" }}
      >
        <div
          className={styles.smallTitle}
          style={{ width: "75%", marginBottom: "5dvh", fontSize: 16 }}
        >
          {membership.price}€ {t("ogni")} {membership.period.count}{" "}
          {t(membership.period.unit)}{" "}
          {membership.freeTrialPeriod && (
            <b>
              <Trans i18nKey={"withAFreeTrialOf"}>
                con un periodo di prova di {{ freeTrialCount }}
                {{ freeTrialUnit }}, con circa il
                {{ membershipPercentDiscount }}
                <span className={styles.highlightYellow}>% di sconto</span>
                su tutte le Box.
              </Trans>
            </b>
          )}
        </div>

        <Row
          className={styles.listItem + " " + styles.listItemBox}
          justify="start"
        >
          <Col span={3}></Col>
          <Col span={6}></Col>
          <Col span={5}>{t("Prezzo Normale")}</Col>
          <Col span={1}>
            <Divider type={"vertical"} style={{ margin: 0 }} />
          </Col>
          <Col span={6}>
            <b>{t("Prezzo Membri")}</b>
          </Col>
        </Row>

        {boxConfig.map((box) => (
          <MembershipBoxOption
            key={box.name}
            name={box.name}
            items={box.items}
            price={box.price!}
            nonMembershipPrice={box.nonMembershipPrice!}
            layout={"doublePrice"}
          />
        ))}
      </div>
    </Modal>
  );
};

export default MembershipDetailsModal;
