import { Modal } from "antd";
import styles from "./styles.module.css";
import { Trans, useTranslation } from "react-i18next";

interface ShippingDetailsModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ShippingDetailsModal = ({
  open,
  onCancel,
  onConfirm,
}: ShippingDetailsModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className={styles.fullscreenModal}
      style={{ top: 0 }}
      closeIcon={
        <img
          className={styles.closeIcon}
          src="/icons/close-modal2.svg"
          alt="close"
        />
      }
      footer={null}
    >
      <div className={styles.blueSection + " " + styles.shippingSection}>
        <img
          className={styles.shippingModalImage}
          src={"/images/shipping.jpg"}
          alt={"delicious dish"}
        />
        <div className={styles.bigTitle}>
          <Trans i18nKey={"shippingDetails"}>
            Spediamo a casa tua mantenendo la temperatura perfetta per
            <span className={styles.highlightYellow}>tutta la spedizione</span>
          </Trans>
        </div>
      </div>

      <div
        className={styles.greySection}
        id="available-boxes"
        style={{ marginBottom: -32, backgroundSize: "30%" }}
      >
        <div
          className={styles.sectionTitle}
          style={{ width: "70%", marginBottom: 8 }}
        >
          {t("Ecco come funziona:")}
        </div>

        <ul className={styles.ul}>
          <li>
            {t(
              "I piatti vengono cucinati e abbattuti immediatamente, preservandone i valori nutritivi.",
            )}
          </li>
          <li>{t("In ogni ordine puoi avere box da 15, 25 o 40 piatti.")}</li>
          <li>
            {t("Il corriere consegnerà il pacco direttamente a casa tua.")}
          </li>
          <li>
            {t(
              "Durante la consegna i piatti vengono mantenuti a -18 rispettando la catena del freddo, utilizzando ghiaccio secco.",
            )}
          </li>
          <li>
            {t(
              "Tutti i materiali e gli imballi sono selezionati accuratamente per garantire la massima ecosostenibilità. Usiamo impacchi di paglia per isolare i piatti, scatole di cartone riciclabile e ghiaccio secco per mantenere la temperatura corretta.",
            )}
          </li>
          <li>
            {t(
              "Grazie al ghiaccio secco, se la box rimane chiusa, i piatti possono rimanere fuori dal freezer fino a 48h dopo la spedizione (di solito fino alla sera del giorno di consegna).",
            )}
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default ShippingDetailsModal;
