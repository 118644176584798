import styles from "./styles.module.css";
import { Card, Col, Row, Switch } from "antd";
import React, { useState } from "react";
import { useSettings } from "../Networking/useSettings";
import MembershipDetailsModalNew from "../Shop/MembershipDetailsModalNew";
import useCart from "../Hooks/useCart";
import { useMe } from "../AppSetup/MeContext";

interface MembershipProductBannerSimpleProps {
  active: boolean;
  onToggle: (value: boolean) => void;
}

const MemberProductBanner = () => {
  const { currentDisplayedBox } = useCart();
  const save = (
    parseFloat(currentDisplayedBox.nonMembershipPrice!) -
    parseFloat(currentDisplayedBox.price)
  ).toFixed(2);

  return (
    <Row
      gutter={16}
      style={{
        padding: 16,
        paddingBottom: 8,
        paddingTop: 8,
        alignItems: "center",
      }}
    >
      <div
        className={styles.imageBadge}
        style={{ backgroundImage: "url('/icons/premium.svg')" }}
      />
      <Col
        flex="auto"
        className={styles.microCopy}
        style={{ maxWidth: "calc(100% - 54px)" }}
      >
        Grazie alla membership stai risparmiando {save}€ su quest'ordine!
      </Col>
    </Row>
  );
};

const MembershipProductBannerSimple = ({
  onToggle,
  active,
}: MembershipProductBannerSimpleProps) => {
  const { membership, membershipPercentDiscount } = useSettings();
  const { isMember } = useMe();
  const { currentDisplayedBox, setMembership, membershipInCart } = useCart();
  const [open, setModalOpen] = useState(false);

  const onConfirm = () => {
    !membershipInCart
      ? setMembership(membership?.sku, false)
      : setMembership(undefined, false);
    setModalOpen(false);
  };

  const onCancel = () => {
    setModalOpen(false);
  };

  if (!membership) {
    return null;
  }

  if (isMember) {
    return <MemberProductBanner />;
  }

  return (
    <Card
      key={"membership"}
      className={
        styles.productCard +
        " " +
        styles.membershipCard +
        " " +
        styles.simplified +
        " " +
        (active ? styles.inCart : styles.notInCart)
      }
      bodyStyle={{ paddingTop: 16, paddingBottom: 16 }}
      style={{ minHeight: "unset" }}
    >
      <MembershipDetailsModalNew
        open={open}
        onCancel={onCancel}
        onConfirm={onConfirm}
        currentBox={currentDisplayedBox}
      />
      <Row gutter={14} className={styles.membershipFirstRow}>
        <Col
          span={20}
          className={styles.details}
          style={{ padding: 0, paddingRight: 0, minHeight: 0 }}
        >
          <div
            className={styles.title}
            style={{ paddingBottom: 0, paddingRight: 0 }}
          >
            Attiva la Membership. <br />
            Acquista Humamy con il {membershipPercentDiscount}% di sconto.
          </div>
        </Col>
        <Col span={4}>
          <Switch
            checked={active}
            onChange={onToggle}
            style={{ verticalAlign: "unset" }}
          />
        </Col>
      </Row>
      <Row gutter={14} align="bottom">
        <Col
          span={20}
          className={styles.details}
          style={{ padding: 0, minHeight: "unset" }}
        >
          <div
            className={styles.price}
            style={{ opacity: 0.8, lineHeight: 1.3 }}
          >
            {membership.freeTrialPeriod &&
              `${membership.freeTrialPeriod.count} ${membership.freeTrialPeriod.unit} gratis, poi `}
            {membership.price}€ / {membership.period.count}{" "}
            {membership.period.unit}
          </div>
        </Col>
        <Col span={4}>
          <div className={styles.detailsBtn} onClick={() => setModalOpen(true)}>
            Dettagli
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default MembershipProductBannerSimple;
