import styles from "./styles.module.css";
import { Col, Row } from "antd";
import React from "react";
import { Product } from "../common/product";

const Macros = ({
  product,
  modalOpened,
  setModalOpened,
}: {
  product: Product;
  modalOpened: boolean;
  setModalOpened: (open: boolean) => void;
}) => {
  const decimalDigits = 1;

  const multiplier = product.cooki?.portionWeight
    ? product.cooki?.portionWeight / 100
    : 1;

  const proteins = product.macros?.proteins
    ? product.macros?.proteins * multiplier
    : undefined;
  const fats = product.macros?.fats
    ? product.macros?.fats * multiplier
    : undefined;
  const carbohydrates = product.macros?.carbohydrates
    ? product.macros?.carbohydrates * multiplier
    : undefined;
  const calories = product.macros?.calories
    ? product.macros?.calories * multiplier
    : undefined;

  return (
    <Row
      onClick={() => setModalOpened(!modalOpened)}
      justify="center"
      className={styles.macrosRow}
    >
      <Col>
        <div className={styles.macroLabel}>Protein</div>
        <div className={styles.macroValue}>
          {proteins?.toFixed(decimalDigits)}
        </div>
      </Col>
      <Col>
        <div className={styles.macroLabel}>Fat</div>
        <div className={styles.macroValue}>{fats?.toFixed(decimalDigits)}</div>
      </Col>
      <Col>
        <div className={styles.macroLabel}>Carb</div>
        <div className={styles.macroValue}>
          {carbohydrates?.toFixed(decimalDigits)}
        </div>
      </Col>
      <Col>
        <div className={styles.macroLabel}>Kcal</div>
        <div className={styles.macroValue}>
          {calories?.toFixed(decimalDigits)}
        </div>
      </Col>
    </Row>
  );
};

export default Macros;
