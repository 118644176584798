import { useMe } from "../AppSetup/MeContext";
import dayjs from "dayjs";

export const useEditableSubscription = () => {
  const me = useMe();

  const expectedShippingDate = dayjs(
    me.subscriptionProfile?.latestOutboundOrder?.expectedShippingDate,
  );

  const twoDaysAgo = dayjs().subtract(2, "day");

  return {
    canEditSubscription:
      me.subscriptionProfile?.enabled &&
      expectedShippingDate &&
      expectedShippingDate.isBefore(twoDaysAgo),
  };
};
