import { useCallback, useContext, useEffect } from "react";
import Constants from "../../common/constants";
import { CompleteHumamyEvent, UserAction, UserInfo } from "./event";
import { DeviceContext } from "../../common/useDevice";
import MeContext from "../../AppSetup/MeContext";
import { camelToSnakeCase } from "../../Lib/caseUtils";
import useHeaders from "../useHeaders";
import useFbPixel from "../useFbPixel";

let sendEventsLock: boolean = false;

const getRecentEvents: () => CompleteHumamyEvent[] = () => {
  const serializedEvents = localStorage.getItem(
    Constants.STORAGE_RECENT_EVENTS,
  );
  if (serializedEvents) {
    return JSON.parse(serializedEvents);
  }
  return [];
};
const setRecentEvents = (events: CompleteHumamyEvent[]) => {
  localStorage.setItem(Constants.STORAGE_RECENT_EVENTS, JSON.stringify(events));
  return;
};

const useUATracker = () => {
  const meContext = useContext(MeContext);
  const { getHeaders } = useHeaders();
  const deviceContext = useContext(DeviceContext);
  const { sendEvent } = useFbPixel();

  const sendRecentEvents = async () => {
    const events = getRecentEvents();
    await submitEvents(events);
    setRecentEvents([]);
  };

  const post = useCallback(
    async (url: string, body?: any, onError?: (err: string) => void) => {
      const requestOptions: RequestInit = {
        method: "post",
        headers: getHeaders(),
        credentials: "include",
        body,
      };
      body = camelToSnakeCase(body);
      if (body) {
        // @ts-ignore
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions.body = JSON.stringify(body);
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/web${url}`,
        requestOptions,
      );
      if (!response.ok && onError) {
        await onError(await response.text());
      }
    },
    [getHeaders],
  );

  const submitEvents = useCallback(
    async (events: CompleteHumamyEvent[]) => {
      await post("/submit_events", { events }, (err) => {
        // Suppress notifications for events logging.
        console.error(err);
      });
    },
    [post],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const recentEvents = getRecentEvents();
      if (recentEvents && recentEvents.length > 0) {
        if (sendEventsLock) {
          return;
        } else {
          sendEventsLock = true;
          sendRecentEvents().finally(() => {
            sendEventsLock = false;
          });
        }
      }
    }, 5000);
    return () => clearInterval(interval);
  });

  const getUserInfo: () => UserInfo = useCallback(() => {
    return {
      pageUrl: window.location.pathname,
      deviceId: deviceContext?.deviceId,
      email: meContext?.me?.email,
      firstName: meContext?.me?.firstName,
      lastName: meContext?.me?.lastName,
      zipCode: meContext?.me?.shippingInfo?.address?.zip,
      userAgent: navigator.userAgent,
      shippingAllowed: meContext?.me?.shippingInfo?.shippingAllowed,
      zone: meContext?.me?.shippingInfo?.address?.zone,
      subscriptionBoxSize: meContext?.me?.subscriptionProfile?.boxSize,
      subscriptionBoxFrequency:
        meContext?.me?.subscriptionProfile?.frequencyInWeeks,
    };
  }, [meContext, deviceContext]);

  const buildEvent = useCallback(
    (userAction: UserAction) => {
      return {
        timestamp: new Date(),
        userInfo: getUserInfo(),
        userAction,
      };
    },
    [getUserInfo],
  );

  const trackUserAction = useCallback(
    (userAction: UserAction) => {
      if (userAction.metaCustomEventName) {
        sendEvent(userAction.metaCustomEventName);
      }
      setRecentEvents([...getRecentEvents(), buildEvent(userAction)]);
    },
    [buildEvent, sendEvent],
  );

  return { trackUserAction, sendRecentEvents };
};

export default useUATracker;
