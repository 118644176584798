import { Progress, Space, Spin } from "antd";
import React, { useEffect } from "react";
import useApiClient from "../Networking/useApiClient";

const startTime = new Date().getTime();
const OneClickPurchase = () => {
  const email = new URLSearchParams(window.location.search).get("email");
  const token = new URLSearchParams(window.location.search).get("token");

  const { getCheckoutUrlFromHubspot } = useApiClient();

  const [endTime, setEndTime] = React.useState<number | null>(null);
  const [currentTime, setCurrentTime] = React.useState<number | null>(null);
  const [checkoutUrl, setCheckoutUrl] = React.useState<string | null>(null);
  const [status, setStatus] = React.useState<"loading" | "error" | "success">(
    "loading",
  );

  useEffect(() => {
    setEndTime(new Date().getTime() + 1000 * 20);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setCurrentTime(new Date().getTime());
    }, 200);
  }, [currentTime]);

  useEffect(() => {
    if (!email || !token) {
      return;
    }
    getCheckoutUrlFromHubspot(email, token)
      .then((url) => {
        if (url) {
          setStatus("success");
          setCheckoutUrl(url);
          window.location.href = url;
        }
      })
      .catch((e) => {
        setStatus("error");
      });
  }, [email, token, getCheckoutUrlFromHubspot]);

  let perc =
    currentTime && endTime
      ? ((startTime - currentTime) / (startTime - endTime)) * 100
      : 0;

  if (perc > 90) {
    perc = 90 + (perc - 90) * 0.1;
  }

  if (!email || !token) {
    window.location.href = "https://new.humamy.com";
    return <Spin />;
  }

  let el: React.ReactElement;

  if (status === "error") {
    el = (
      <p>Ti invieremo un'email al più presto per completare il pagamento.</p>
    );
  } else if (status === "success") {
    el = (
      <p>
        Stiamo completando il pagamento... Se non vieni reindirizzato
        automaticamente clicca <a href={checkoutUrl!}>qui</a>
      </p>
    );
  } else {
    el = (
      <Space direction={"vertical"}>
        <Progress
          percent={perc}
          size={[300, 20]}
          showInfo={false}
          status={"active"}
        />
        <h2> Stiamo preparando la tua box... </h2>
      </Space>
    );
  }

  return (
    <div style={{ height: 200, position: "relative" }}>
      <div
        style={{
          margin: 0,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {el}
      </div>
    </div>
  );
};

export default OneClickPurchase;
