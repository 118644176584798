import styles from "./styles.module.css";
import { Card, Col, Row, Image, Switch } from "antd";
import React, { useState } from "react";
import { useSettings } from "../Networking/useSettings";
import MembershipDetailsModal from "../Shop/MembershipDetailsModal";
import useCart from "../Hooks/useCart";
import { useMe } from "../AppSetup/MeContext";
import { Trans, useTranslation } from "react-i18next";

interface MembershipProductBannerProps {
  active: boolean;
  onToggle: (value: boolean) => void;
}

const MemberProductBanner = () => {
  const { currentDisplayedBox } = useCart();
  const save = (
    parseFloat(currentDisplayedBox.nonMembershipPrice!) -
    parseFloat(currentDisplayedBox.price)
  ).toFixed(2);

  return (
    <Row
      gutter={16}
      style={{
        padding: 16,
        paddingBottom: 8,
        paddingTop: 8,
        alignItems: "center",
      }}
    >
      <div
        className={styles.imageBadge}
        style={{ backgroundImage: "url('/icons/premium.svg')" }}
      />
      <Col
        flex="auto"
        className={styles.microCopy}
        style={{ maxWidth: "calc(100% - 54px)" }}
      >
        <Trans i18nKey={"membershipSaving"}>
          Grazie alla membership stai risparmiando {{ save }}€ su quest'ordine!
        </Trans>
      </Col>
    </Row>
  );
};

const MembershipProductBanner = ({
  onToggle,
  active,
}: MembershipProductBannerProps) => {
  const { membership, membershipPercentDiscount } = useSettings();
  const { isMember } = useMe();
  const { currentDisplayedBox, setMembership } = useCart();
  const [open, setModalOpen] = useState(false);
  const { t } = useTranslation();

  if (!membership) {
    return null;
  }

  const onConfirm = () => {
    setMembership(membership.sku, false);
    setModalOpen(false);
  };
  const onCancel = () => {
    setModalOpen(false);
  };

  const membershipFreeTrialPeriodCount = membership.freeTrialPeriod?.count;
  const membershipFreeTrialPeriodUnit = membership.freeTrialPeriod?.unit
    ? t(membership.freeTrialPeriod?.unit)
    : undefined;

  if (isMember) {
    return <MemberProductBanner />;
  }
  return (
    <Card
      key={"membership"}
      className={
        styles.productCard +
        " " +
        styles.membershipCard +
        " " +
        (active ? styles.inCart : "")
      }
      bodyStyle={{ padding: 0 }}
    >
      <MembershipDetailsModal
        open={open}
        onCancel={onCancel}
        onConfirm={onConfirm}
        currentBox={currentDisplayedBox}
      />
      <Row gutter={14}>
        <Col span={11}>
          <Image
            className={styles.image}
            alt={"membership"}
            src={"/images/membership_card.webp"}
            preview={false}
          />
        </Col>

        <Col span={13} className={styles.details}>
          <Row justify="start">
            <div className={styles.title} style={{ paddingBottom: 0 }}>
              <span>{t("Membership Humamy")}</span>
            </div>
          </Row>
          <Row>
            <div className={styles.price} style={{ color: "white" }}>
              {membership.freeTrialPeriod && (
                <Trans i18nKey={"membershipFreeTrialDescription"}>
                  {{ membershipFreeTrialPeriodCount }}{" "}
                  {{ membershipFreeTrialPeriodUnit }}
                  di prova gratuita, poi
                </Trans>
              )}
              <span>
                {membership.price} € / {membership.period.count} &nbsp;
                {t(membership.period.unit)}
              </span>
            </div>
          </Row>
          <Row>
            <Trans i18nKey={"membershipDiscountDescription"}>
              Acquista Humamy con il
              {{ membershipPercentDiscount }}
              <strong>% di sconto</strong>
            </Trans>
          </Row>
          <Row style={{ width: "100%" }} justify={"space-between"}>
            <Col>
              <div
                className={styles.detailsBtn}
                onClick={() => setModalOpen(true)}
              >
                {t("Dettagli")}
              </div>
            </Col>
            <Col style={{ marginRight: 12 }}>
              <Switch
                checked={active}
                onChange={onToggle}
                style={{ verticalAlign: "unset" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default MembershipProductBanner;
