import styles from "./styles.module.css";
import React from "react";
import { Button, Col, Row } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { BackButtonClicked } from "../Networking/Metrics/UserActions/common";
import useUATracker from "../Networking/Metrics/useUATracker";

interface PageTitleProps {
  backButton?: React.ReactElement;
}

const SecondaryPageHeader = ({
  backButton,
  children,
}: React.PropsWithChildren<PageTitleProps>) => {
  const navigate = useNavigate();

  const { trackUserAction } = useUATracker();

  const backButtonClicked = () => {
    trackUserAction(new BackButtonClicked());
    navigate(-1);
  };

  const back = backButton || (
    <Button onClick={backButtonClicked} className={styles.backButton}>
      <LeftOutlined />
    </Button>
  );

  return (
    <Row style={{ marginTop: 8 }}>
      <Col span={8} className={styles.backBtnRow}>
        {back}
      </Col>
      <Col span={12}>
        <h2>{children}</h2>
      </Col>
    </Row>
  );
};

export default SecondaryPageHeader;
