import { Col, Row, Space, Button, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import Logo from "../../Home/HomeComponents/Logo";
import styles from "./styles.module.css";
import homeStyles from "../../Home/styles.module.css";
import useCart from "../../Hooks/useCart";
import ArrivalDateBanner from "./ArrivalDateBanner";
import ProductComponent from "../../Products/Product";
import { Product } from "../../common/product";
import { useMe } from "../../AppSetup/MeContext";
import dayjs from "dayjs";
import { useEditableSubscription } from "../../Hooks/useEditableSubscription";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const SubscriptionRecap = () => {
  const { lineItems, setEditingSubscription } = useCart();
  const navigate = useNavigate();
  const me = useMe(5000);

  const { canEditSubscription } = useEditableSubscription();
  const { t } = useTranslation();

  const tooltipMessage =
    "Il tuo ordine è in preparazione, e non è possibile modificare data di consegna o piatti. Potrai modificare le impostazioni dopo la ricezione dell’ordine." +
    " Se hai bisogno di aiuto, contattaci su WhatsApp o via email.";

  const getQuantityForProduct = (product: Product): number => {
    return lineItems.find((li) => li.product.id === product.id)?.quantity || 0;
  };

  const productsList = lineItems.map((product) => (
    <ProductComponent
      editable={false}
      membershipInCart={false}
      key={product.product.id}
      product={product.product}
      inCart={getQuantityForProduct(product.product)}
    />
  ));

  const arrivalDateLabel = useMemo(() => {
    if (canEditSubscription) {
      return dayjs(me.subscriptionProfile.nextDeliveryDate).format(
        "DD / MM / YYYY",
      );
    }
    if (me.subscriptionProfile.latestOutboundOrder?.expectedShippingDate) {
      return dayjs(
        me.subscriptionProfile.latestOutboundOrder.expectedShippingDate,
      ).format("DD / MM / YYYY");
    }
    return "Riprova fra poco, stiamo lavorando alla tua box!";
  }, [
    canEditSubscription,
    me.subscriptionProfile.latestOutboundOrder?.expectedShippingDate,
    me.subscriptionProfile.nextDeliveryDate,
  ]);

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <Row style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
        <Logo small />
        <div className={homeStyles.headerTitle}>
          <h1>
            {me.subscriptionProfile.enabled
              ? "Grazie!"
              : "La tua subscription è disattivata!"}
          </h1>
        </div>
      </Row>
      <Row justify="center">
        <Col
          span={8}
          xs={24}
          md={12}
          xl={8}
          style={{ padding: 24, paddingTop: 0 }}
        >
          {
            <ArrivalDateBanner
              title={
                canEditSubscription
                  ? "Tieniti pronto per la prossima spedizione!"
                  : "La tua prossima Box é in arrivo!"
              }
              subEnabled={me.subscriptionProfile.enabled}
              editable={canEditSubscription}
              editTooltipTitle={
                canEditSubscription ? undefined : tooltipMessage
              }
              date={arrivalDateLabel}
            />
          }
          {canEditSubscription ? (
            <Button
              className={styles.button + " " + styles.buttonLeftAligned}
              style={{ marginBottom: 16 }}
              onClick={() => {
                setEditingSubscription(true);
                navigate("/shop/choose-subscription");
              }}
            >
              {t("Dimensione e frequenza")}
              <img
                alt={"next"}
                src={`/icons/next_small_black.svg`}
                style={{ marginBottom: -3 }}
              />
            </Button>
          ) : (
            <>
              <div className={styles.smallTitleRow}>
                <h1 className={styles.titleSmall}>{t("Frequenza")}</h1>
                <Tooltip title={canEditSubscription ? null : tooltipMessage}>
                  <b
                    className={styles.subEditBtn}
                    style={
                      canEditSubscription ? { opacity: 1 } : { opacity: 0.4 }
                    }
                    onClick={() =>
                      canEditSubscription && navigate("/shop/edit-subscription")
                    }
                  >
                    {t("Dimensione e frequenza")}
                    <img
                      src="/icons/edit_small.svg"
                      alt="edit"
                      className={styles.editIcon}
                    />
                  </b>
                </Tooltip>
              </div>
              <div
                className={styles.banner}
                style={{ padding: "12px 16px 12px 16px" }}
              >
                <div className={styles.textLeft}>
                  <b>
                    {t(
                      "Ogni " +
                        me.subscriptionProfile.frequencyInWeeks +
                        " settimane",
                    )}
                  </b>
                  <div
                    style={{
                      fontSize: 14,
                      color: "var(--dark-grey)",
                      opacity: 0.7,
                    }}
                  >
                    {t(
                      "Data di consegna box successiva: " +
                        dayjs(me.subscriptionProfile.nextDeliveryDate).format(
                          "DD/MM/YYYY",
                        ),
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          <div className={styles.smallTitleRow}>
            <h1 className={styles.titleSmall}>{t("Cosa contiene")}</h1>

            <Tooltip title={canEditSubscription ? null : tooltipMessage}>
              <b
                className={styles.subEditBtn}
                style={canEditSubscription ? { opacity: 1 } : { opacity: 0.4 }}
                onClick={() => {
                  if (canEditSubscription) {
                    setEditingSubscription(true);
                    navigate("/shop/edit");
                  }
                }}
              >
                {t("Modifica piatti")}
                <img
                  src="/icons/edit_small.svg"
                  alt="edit"
                  className={styles.editIcon}
                />
              </b>
            </Tooltip>
          </div>
          <div style={{ margin: -16, marginTop: 0 }}>{productsList}</div>
        </Col>
      </Row>
    </Space>
  );
};

export default SubscriptionRecap;
